import { DotLegalBreadCrumbModel, DotLegalPageHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, CircularProgress, IconButton, Link, MenuItem, Popover } from "@mui/material";
import React from "react";
import { editIcon } from "../../../common/icons";
import { useAuditGroupHeader } from "./AuditGroupHeader.hooks";
import { useTranslation } from "../../../localization/useTranslation";
import CreateLegalEntityAuditGroupDialog from "../../createLegalEntityAuditGroupDialog/CreateLegalEntityAuditGroupDialog";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../../useUrlProvider";
import { AuditGroupEditModel } from "./AuditGroupHeader.types";
import DeleteLegalEntityAuditGroupDialog from "../../deleteLegalEntityAuditGroupDialog/DeleteLegalEntityAuditGroupDialog";
import PageHeaderEditEntityButton, {
    PageHeaderEditEntityButtonMenuItem,
} from "../../../common/components/pageHeaderEditEntityButton/PageHeaderEditEntityButton.tsx";

export interface AuditGroupHeaderProps {
    breadCrumbs: Array<DotLegalBreadCrumbModel>;
    customerName: string;
    auditGroupModel: AuditGroupEditModel | undefined;
    onAuditGroupUpdated: () => void;
    showAddLegalEntityBtn: boolean;
    showDeleteMenuItem: boolean;
    canDeleteAuditgroup: boolean;
}

function AuditGroupHeader(props: AuditGroupHeaderProps) {
    const navigate = useNavigate();
    const { translateString } = useTranslation();
    const { showEditDialog, setShowEditDialog, id, showDeleteDialog, setShowDeleteDialog, onDownloadManagementReportClick, isDownloadingManagementReport } =
        useAuditGroupHeader(props);
    const { getAuditGroupDraftUrl, getAuditGroupsUrl, getAuditGroupManagementReportUrl } = useUrlProvider();

    const getEditMenuItems = () => {
        let menuItems: Array<PageHeaderEditEntityButtonMenuItem> = [];

        menuItems.push({
            title: translateString("edit"),
            onClick: () => {
                setShowEditDialog(true);
            },
        });

        if (props.showAddLegalEntityBtn) {
            menuItems.push({
                title: translateString("addLegalEntity"),
                onClick: () => {
                    navigate(getAuditGroupDraftUrl(id!));
                },
            });
        }

        if (props.showDeleteMenuItem) {
            menuItems.push({
                title: translateString("delete"),
                onClick: () => {
                    setShowDeleteDialog(true);
                },
            });
        }

        if (props.auditGroupModel && props.auditGroupModel.sent !== null) {
            menuItems.push({
                title: translateString("viewManagementReportInBrowser"),
                onClick: () => {},
                link: getAuditGroupManagementReportUrl(props.auditGroupModel.id),
            });

            menuItems.push({
                title: translateString("downloadManagementReport"),
                onClick: async () => {
                    await onDownloadManagementReportClick();
                },
            });
        }

        return menuItems;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader
                breadCrumbs={props.breadCrumbs}
                userContext={{
                    customerName: props.customerName,
                }}
                rightComponent={<PageHeaderEditEntityButton menuItems={getEditMenuItems()} showSpinner={isDownloadingManagementReport} />}
            />

            {props.auditGroupModel && showEditDialog && (
                <CreateLegalEntityAuditGroupDialog
                    onDialogClose={() => setShowEditDialog(false)}
                    auditGroup={{
                        description: props.auditGroupModel.description,
                        name: props.auditGroupModel.name,
                        deadline: props.auditGroupModel.deadline,
                        groupEntityId: props.auditGroupModel.senderId,
                        questionnaireId: props.auditGroupModel.auditTemplateId,
                        questionnaireName: props.auditGroupModel.auditTemplateName,
                        questionnaireVersion: props.auditGroupModel.auditTemplateVersion,
                        responsibleId: props.auditGroupModel.responsibleId,
                        questionnaireIntroductionTitle: props.auditGroupModel.questionnaireIntroductionTitle,
                    }}
                    onAuditGroupUpdated={() => {
                        setShowEditDialog(false);
                        props.onAuditGroupUpdated();
                    }}
                    auditGroupId={props.auditGroupModel.id}
                    auditGroupInProgress={props.auditGroupModel.sent !== undefined && props.auditGroupModel.sent !== null}
                />
            )}

            {props.auditGroupModel && showDeleteDialog && (
                <DeleteLegalEntityAuditGroupDialog
                    auditGroupId={id!}
                    auditGroupName={props.auditGroupModel.name}
                    deadline={props.auditGroupModel.deadline}
                    onCloseDialog={() => setShowDeleteDialog(false)}
                    onDeletedAuditGroup={() => navigate(getAuditGroupsUrl())}
                    canDeleteAuditGroup={props.canDeleteAuditgroup}
                />
            )}
        </React.Fragment>
    );
}

export default AuditGroupHeader;
