import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";

export interface UserPermissions {
    canManageCustomers: boolean;
    canManageUsers: boolean;
    canManageGroupEntities: boolean;
    canManageMasterData: boolean;
    canManageRiskAssessment: boolean;
    canChangeUserLoginProvider: boolean;
    annualWheelPermission: Permissions;
    processingActivityPermissions: Permissions;
    canManageRoles: boolean;
    canPurchasePrivacy: boolean;
    canAccessAllData: boolean;
    canManageSystemStandardTemplates: boolean;
    canManageProcessingActivityStandardTemplates: boolean;
    canValidateProcessingActivities: boolean;
    processingActivityDataSubjectPermissions: ProcessingActivityDataSubjectPermissions;
    processingActivityMasterDataPermissions: ProcessingActivityMasterDataPermissions;
    processingActivityAssociationPermissions: ProcessingActivityAssociationPermissions;
    processingActivityDataCategoryPermissions: ProcessingActivityDataCategoryPermissions;
    processingActivityLegalBasesPermissions: ProcessingActivityLegalBasesPermissions;
    processingActivitySystemPermissions: ProcessingActivitySystemPermissions;
    processingActivityPolicyPermissions: ProcessingActivityPolicyPermissions;
    processingActivityDataProcessorsPermissions: ProcessingActivityDataProcessorsPermissions;
    processingActivityDataControllersPermissions: ProcessingActivityDataControllersPermissions;
    processingActivityDisclosuresPermissions: ProcessingActivityDisclosuresPermissions;
    processingActivitySourcesPermissions: ProcessingActivitySourcesPermissions;
    processingActivityJointDataControllerPermissions: ProcessingActivityJointDataControllerPermissions;
    systemDescriptionPermissions: SystemDescriptionPermissions;
    systemAssociationPermissions: SystemAssociationPermissions;
    systemDataProcessorPermissions: SystemDataProcessorPermissions;
    systemInformationPermissions: SystemInformationPermissions;
    systemPersonalDataPermissions: SystemPersonalDataPermissions;
    systemAssociatedAssetsPermissions: SystemAssociatedAssetsPermissions;
    systemAIPermissions: SystemAIPermissions;
    canAccessRecord301: boolean;
    canAccessRecord302: boolean;
    riskPermissions: Permissions;
    riskTemplatePermissions: Permissions;
    taskPermissions: Permissions;
    customListPermissions: Permissions;
    systemPermissions: Permissions;
    companyPermissions: Permissions;
    companyDocumentationPermissions: Permissions;
    policyPermissions: Permissions;
    canManageProcessingActivtyComments: false;
    processingActivityStatusManagement?: ProcessingActivityStatusManagement;
    canManageProcessingActivityNote: false;
    canAccessAuditLog: false;
    canAccessProcessingActivityStatistic: boolean;
    canDeleteDoocumentVersion: boolean;
    canSetDocumentStatus: boolean;
    systemPolicyPermissions: SystemPolicyPermissions;
    systemDataAndStoragePermissions: SystemDataAndStoragePermissions;
    riskVersionPermissions: Permissions;
    gdprIncidentLogPermissions: Permissions;
    reportTypes: Array<CustomerReportType>;
    canManageTaskMasterData: boolean;
    canCopyProcessingActivity: boolean;
    canManageInternalAssessment: boolean;
    declarationPermissions: Permissions;
    hasDeclarationAuditorApproval: boolean;
    canManageAuditGroups: boolean;
}

export interface Permissions {
    read: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean;
}

export interface UserContext {
    language: string;
    customerName: string;
    userProfileId: string;
    emailAddress: string;
    fullName: string;
    permissions: UserPermissions;
    customerId: string;
    isFirstLogin: boolean;
    helpLink: string;
    gdpo: boolean;
    germanEnabled: boolean;
    created: Date;
}

export interface ProcessingActivityDataSubjectPermissions {
    hasProcessorsPermission: boolean;
    hasDataSubjectsPermission: boolean;
    hasSecurityMeasurePermission: boolean;
    hasDataSourcePermission: boolean;
    hasDataSubjectCustomOnlyPermission: boolean;
    hasDataProcessorCustomOnlyPermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivityMasterDataPermissions {
    hasPurposePermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivityAssociationPermissions {
    hasGroupEntitiesPermission: boolean;
    hasOwnershipPermission: boolean;
    hasBusinessAreaPermission: boolean;
    hasAnySubPermissionAccess: boolean;
    permissions: Permissions;
}

export interface ProcessingActivityDataCategoryPermissions {
    permissions: Permissions;
}

export interface ProcessingActivityLegalBasesPermissions {
    hasLegalBasisPermission: boolean;
    hasDeletionPeriodPermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivitySystemPermissions {
    permissions: Permissions;
}

export interface ProcessingActivityPolicyPermissions {
    permissions: Permissions;
}

export interface ProcessingActivitySharingsPermissions {
    processingActivityDataProcessorsPermissions: ProcessingActivityDataProcessorsPermissions;
    processingActivityDataControllersPermissions: ProcessingActivityDataControllersPermissions;
    processingActivityDisclosuresPermissions: ProcessingActivityDisclosuresPermissions;
    processingActivitySourcesPermissions: ProcessingActivitySourcesPermissions;
}

export interface ProcessingActivityDataProcessorsPermissions {
    hasAgreementPermission: boolean;
    hasTiaAndTransferBasisPermission: boolean;
    hasResponsiblePermission: boolean;
    hasSubProcessorPermission: boolean;
    hasDataCategoriesPermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivityDataControllersPermissions {
    hasAgreementPermission: boolean;
    hasResponsiblePermission: boolean;
    hasSubProcessorPermission: boolean;
    hasTiaAndTransferBasisPermission: boolean;
    hasDataCategoriesPermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivityDisclosuresPermissions {
    hasAgreementPermission: boolean;
    hasTransferBasisPermission: boolean;
    hasBasisOfDisclosurePermission: boolean;
    hasResponsiblePermission: boolean;
    hasDataCategoriesPermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivitySourcesPermissions {
    hasAgreementPermission: boolean;
    hasResponsiblePermission: boolean;
    hasDataCategoriesPermission: boolean;
    permissions: Permissions;
}

export interface ProcessingActivityJointDataControllerPermissions {
    hasAgreementPermission: boolean;
    hasResponsiblePermission: boolean;
    hasDataCategoriesPermission: boolean;
    permissions: Permissions;
}

export interface SystemDescriptionPermissions {
    permissions: Permissions;
}

export interface SystemAssociationPermissions {
    hasSystemTypePermission: boolean;
    permissions: Permissions;
}

export interface SystemDataProcessorPermissions {
    hasDataProcessorAgreementPermission: boolean;
    hasStorageLocationPermission: boolean;
    hasSupportAccessPermission: boolean;
    hasSubProcessorPermission: boolean;
    hasTiaAndTransferBasis: boolean;
    permissions: Permissions;
}

export interface SystemInformationPermissions {
    permissions: Permissions;
    hasSecurityMeasurePermission: boolean;
}

export interface SystemPolicyPermissions {
    permissions: Permissions;
}

export interface SystemDataAndStoragePermissions {
    permissions: Permissions;
    hasStorageLocationPermission: boolean;
    hasSupportAccessPermission: boolean;
}

export interface SystemPersonalDataPermissions {
    permissions: Permissions;
}

export interface SystemAssociatedAssetsPermissions {
    permissions: Permissions;
}

export interface SystemAIPermissions {
    permissions: Permissions;
}

export enum ProcessingActivityStatusManagement {
    Regular = 1,
    Draft = 2,
    Full = 3,
}
