import { DotLegalCheckbox, DotLegalDatePicker, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { useAnnualWheelDurationForm } from "./AnnualWheelDurationForm.hooks";
import { useAnnualWheelDialogStyles } from "../AnnualWheelDialog.styles";
import { AnnualWheelDurationFormModel } from "./AnnualWheelDurationForm.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { AnnualWheelActivityType } from "../AnnualWheelDialog.types";

export interface AnnualWheelDurationFormModelProps {
    saveModel: AnnualWheelDurationFormModel | undefined;
    onChange: (saveModel: AnnualWheelDurationFormModel) => void;
    showError: boolean;
    isCustomActivity: boolean;
    isEdit: boolean;
}

function AnnualWheelDurationForm(props: AnnualWheelDurationFormModelProps) {
    const { translateString, getDateTimePickerLanguage } = useTranslation();
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const {
        getMinDate,
        getMaxDate,
        onDateChange,
        onIntervalChange,
        onOnlyAppearOnceChange,
        onNotificationPeriodInDaysChange,
        notificationPeriodInDaysErrorText,
    } = useAnnualWheelDurationForm(props);
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);

    const getErrorMessage = (error: string) => {
        if (error) return <Box sx={styles.error}>{error}</Box>;

        return undefined;
    };

    let startDateError: string | null;
    if (props.saveModel?.isStartDateEmpty()) {
        startDateError = "fieldMandatory";
    } else if (props.saveModel?.isStartDateInvalid()) {
        startDateError = "cannotCreateActivitiesBackInTime";
    } else {
        startDateError = "";
    }

    return (
        <Box sx={styles.formSpacing}>
            <DotLegalHeader headerStyle={"small"} marginTop={2} marginBottom={0.5}>
                {translateString("annualWheelStartDate")}
            </DotLegalHeader>

            <DotLegalDatePicker
                label=""
                onChange={(date) => onDateChange(date)}
                value={props.saveModel?.startDate!}
                error={props.showError && (props.saveModel?.isStartDateInvalid() || props.saveModel?.isStartDateEmpty())}
                helperMessage={translateString(startDateError)}
                view={"month"}
                minDate={getMinDate()}
                maxDate={getMaxDate()}
                language={getDateTimePickerLanguage()}
                noMargin
            />

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                {translateString("annualWheelTaskCompleted")}
            </DotLegalHeader>
            <Box sx={styles.content}>
                <Box sx={styles.interval}>
                    <Box component={"span"} sx={styles.each}>
                        {translateString("every")}
                    </Box>
                    <Box sx={styles.numberField}>
                        <DotLegalTextField
                            onlyNumber={"integer"}
                            label={""}
                            value={props.saveModel?.interval?.toString() ?? null}
                            disabled={props.saveModel?.interval === null}
                            debounce={false}
                            noMargin
                            onChange={(interval) => onIntervalChange(interval)}
                        />
                    </Box>
                    <span>{translateString("month").toLowerCase()}</span>
                </Box>
            </Box>
            {getErrorMessage(
                props.showError && props.saveModel?.isIntervalInvalid()
                    ? isNullOrWhitespace(props.saveModel?.interval?.toString())
                        ? translateString("fieldMandatory")
                        : translateString("invalidValue")
                    : ""
            )}

            {canSetIntervalToSingleOccurence(props.saveModel!.type!) && (
                <Box sx={styles.singularActivity}>
                    <DotLegalCheckbox
                        checked={props.saveModel?.interval === null}
                        onChange={(value) => onOnlyAppearOnceChange(value)}
                        margin={{ marginLeft: -1.5 }}
                    />
                    <span>{translateString("annualWheelActivitySingular")}</span>
                </Box>
            )}

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                {translateString("annualWheelNotifications")}
            </DotLegalHeader>
            <Box sx={styles.content}>
                <Box sx={styles.notificationPeriod}>
                    <Box sx={styles.numberField}>
                        <DotLegalTextField
                            onlyNumber={"integer"}
                            label={""}
                            value={props.saveModel?.notificationPeriodInDays?.toString() ?? null}
                            debounce={false}
                            onChange={(period) => onNotificationPeriodInDaysChange(period)}
                            noMargin
                        />
                    </Box>
                    <span>{translateString("days").toLowerCase()}</span>
                </Box>
            </Box>
            {getErrorMessage(props.showError && props.saveModel?.isNotificationPeriodInvalid() ? notificationPeriodInDaysErrorText : "")}
        </Box>
    );

    function canSetIntervalToSingleOccurence(annualWheelActivityType: AnnualWheelActivityType) {
        if (annualWheelActivityType === AnnualWheelActivityType.ProcessingActivityValidation) {
            return false;
        }

        return true;
    }
}

export default AnnualWheelDurationForm;
