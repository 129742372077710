import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { ApplicationFeatures } from "./PlanProvider.types";
import { usePlanContext } from "./PlanProvider";

const ProcessingActivityCountURL = "/plan/processingActivityCount";
const SystemCountUrl = "/plan/systemCount";
const PolicyCountUrl = "/plan/policyCount";
const GdprIncidentLogCountUrl = "/plan/gdprIncidentLogCount";
const classificationsCountUrl = "/plan/classificationCount";
const auditCountUrl = "/plan/auditCount";
const contactCountUrl = "/plan/contact";

export function PlanProviderDataMapping() {
    const url = "/plan/";
    let oneHour = 3600000;
    const featureQuery = useQuery(url + "applicationFeatures", () => get<ApplicationFeatures>(url + "applicationFeatures"), { staleTime: oneHour });

    return { data: featureQuery.data, isLoading: featureQuery.isLoading };
}

//Processing Activities
function DoFetchProcessingActivityCount() {
    const { dataProtectionFeatures } = usePlanContext();

    if (dataProtectionFeatures.maxNumberOfProcessingActivities) {
        return true;
    }

    return false;
}

export function GetNumberOfProcessingActivities() {
    const fetchData = DoFetchProcessingActivityCount();

    const { data } = useQuery(ProcessingActivityCountURL, () => get<number>(ProcessingActivityCountURL), { enabled: fetchData });

    return data;
}

export function IsProcessingActivityLimitReached() {
    const { dataProtectionFeatures } = usePlanContext();

    let fetchData = DoFetchProcessingActivityCount();

    const data = GetNumberOfProcessingActivities();

    if (fetchData) {
        if (data !== undefined) {
            return data >= dataProtectionFeatures.maxNumberOfProcessingActivities!;
        }

        return undefined;
    }

    return false;
}

export function RefetchProcessingActivityCountQuery() {
    const fetchData = DoFetchProcessingActivityCount();

    const { refetch } = useQuery(ProcessingActivityCountURL, () => get<number>(ProcessingActivityCountURL), { enabled: fetchData });

    return refetch;
}

//Systems
function DoFetchSystemCount() {
    const { informationSecurityFeatures } = usePlanContext();

    if (informationSecurityFeatures.maxNumberOfSystems) {
        return true;
    }

    return false;
}

export function GetNumberOfSystems() {
    const fetchData = DoFetchSystemCount();

    const { data } = useQuery(SystemCountUrl, () => get<number>(SystemCountUrl), { enabled: fetchData });

    return data;
}

export function IsSystemLimitReached() {
    const { informationSecurityFeatures } = usePlanContext();

    let fetchData = DoFetchSystemCount();

    const data = GetNumberOfSystems();

    if (fetchData) {
        if (data !== undefined) {
            return data >= informationSecurityFeatures.maxNumberOfSystems!;
        }

        return undefined;
    }

    return false;
}

export function RefetchSystemCountQuery() {
    const fetchData = DoFetchSystemCount();

    const { refetch } = useQuery(SystemCountUrl, () => get<number>(SystemCountUrl), { enabled: fetchData });

    return refetch;
}

//Policies
function DoFetchPolicyCount() {
    const { platformFeatures } = usePlanContext();

    if (platformFeatures.maxNumberOfPolicies) {
        return true;
    }

    return false;
}

export function GetNumberOfPolicies() {
    const fetchData = DoFetchPolicyCount();

    const { data } = useQuery(PolicyCountUrl, () => get<number>(PolicyCountUrl), { enabled: fetchData });

    return data;
}

export function IsPolicyLimitReached() {
    const { platformFeatures } = usePlanContext();

    let fetchData = DoFetchPolicyCount();

    const data = GetNumberOfPolicies();

    if (fetchData) {
        if (data !== undefined) {
            return data >= platformFeatures.maxNumberOfPolicies!;
        }

        return undefined;
    }

    return false;
}

export function RefetchPolicyCountQuery() {
    const fetchData = DoFetchPolicyCount();

    const { refetch } = useQuery(PolicyCountUrl, () => get<number>(PolicyCountUrl), { enabled: fetchData });

    return refetch;
}

//Incident logs

function DoFetchGdprIncidentLogCount() {
    const { dataProtectionFeatures } = usePlanContext();

    if (dataProtectionFeatures.maxNumberOfIncidentLogEntries) {
        return true;
    }

    return false;
}

export function GetNumberOfGdprIncidents(fetchData: boolean) {
    const { data } = GdprIncidentLogCountQuery(fetchData);
    return data;
}

export function IsGdprIncidentLogLimitReached() {
    const { dataProtectionFeatures } = usePlanContext();

    let fetchData = DoFetchGdprIncidentLogCount();

    if (fetchData) {
        const data = GetNumberOfGdprIncidents(fetchData);

        if (data !== undefined) {
            return data >= dataProtectionFeatures.maxNumberOfIncidentLogEntries!;
        }

        return undefined;
    }

    return false;
}

export function RefetchNumberOfGdprIncidents() {
    let fetchData = DoFetchGdprIncidentLogCount();
    const { refetch } = GdprIncidentLogCountQuery(fetchData);
    return refetch;
}

const GdprIncidentLogCountQuery = (fetchData: boolean) => {
    const { data, refetch } = useQuery(GdprIncidentLogCountUrl, () => get<number>(GdprIncidentLogCountUrl), { enabled: fetchData });
    return { data, refetch };
};

//Classifications
function DoFetchClassificationCount() {
    const { vendorManagementFeatures } = usePlanContext();

    if (vendorManagementFeatures.maxNumberOfVendorRiskClassificationsPerYear) {
        return true;
    }

    return false;
}

export const useLegalEntityClassificationCountQuery = () => {
    var fetchData = DoFetchClassificationCount();

    const { isLoading, data, refetch } = useQuery(classificationsCountUrl, () => get<boolean>(classificationsCountUrl), { enabled: fetchData });

    return { countIsLoading: isLoading, isLimitReached: data ?? false, refetchCount: refetch };
};

//Audits
function DoFetchLegalEntityAuditCount() {
    const { vendorManagementFeatures } = usePlanContext();

    if (vendorManagementFeatures.maxNumberOfExternalOrInternalAuditsPerYear) {
        return true;
    }

    return false;
}

export const useLegalEntityAuditCountQuery = () => {
    var fetchData = DoFetchLegalEntityAuditCount();

    const { isLoading, data, refetch } = useQuery(auditCountUrl, () => get<boolean>(auditCountUrl), { enabled: fetchData });

    return { countIsLoading: isLoading, isLimitReached: data ?? false, refetchCount: refetch };
};
