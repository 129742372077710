import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { LoginProvider } from "../../user/User.types";
import { CustomerType } from "../customers/Customers.types";

export interface CustomerSaveModel {
    id: string;
    name: string;
    language: string;
    active: boolean;
    loginProvider: LoginProvider;
    customerType: CustomerType;
    reportTypes: Array<number>;
    helpLink: string;
    planType: PlanType;
    germanEnabled: boolean;
}

export enum CustomerReportType {
    RegistrationOfResearchProject = 1,
    InternalAcquisitionOfSignature = 2,
    ResearchEthicsApproval = 3,
}
