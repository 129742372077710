import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalPageHeader, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { useMasterDataStyles } from "./MasterData.styles";
import { MasterDataViewModel } from "./MasterData.Types";
import { greenArrowRight } from "../common/icons";
import { useUrlProvider } from "../useUrlProvider";
import { useMasterDataMapping } from "./MasterData.hooks";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { usePlanContext } from "../auth/planProvider/PlanProvider";

export function MasterData() {
    const styles = useMasterDataStyles();
    const { translateString } = useTranslation();
    const urlProvider = useUrlProvider();
    const { permissions, customerName } = useUserContext();
    const { platformFeatures, informationSecurityFeatures, vendorManagementFeatures } = usePlanContext();
    const navigate = useNavigate();

    if (!permissions.canManageMasterData) {
        navigate(urlProvider.getForbiddenUrl());
    }

    const { redirectTo, getBreadCrumbs } = useMasterDataMapping();

    //Indsæt objekt med navn og URl sideløbende med at de forskellige stamdata sider bliver tilføjet. Tænker vi benytter urlProvider.
    var masterData = [
        { name: translateString("purpose"), url: urlProvider.getPurposesUrl() },
        { name: translateString("securityMeasures"), url: urlProvider.getSecurityMeasures() },
        { name: translateString("deletionPeriods"), url: urlProvider.getDeletionPeriods() },
        { name: translateString("processingActivityAreas"), url: urlProvider.getBusinessAreasUrl() },
        { name: translateString("dataCategories"), url: urlProvider.getDataCategories() },
        { name: translateString("dataSubjects"), url: urlProvider.getDataSubjectsUrl() },
        { name: translateString("nationalLaws"), url: urlProvider.getNationalLawsUrl() },
        { name: translateString("helpTexts"), url: urlProvider.getHelpTextsUrl() },
    ];

    if (platformFeatures.riskVersions && permissions.riskVersionPermissions.read) {
        masterData.push({ name: translateString("riskVersions"), url: urlProvider.getRiskAssessmentVersionsUrl() });
    }

    if (vendorManagementFeatures.certifications || informationSecurityFeatures.certifications) {
        masterData.push({ name: translateString("certifications"), url: urlProvider.getCertificationsUrl() });
    }

    if (platformFeatures.annualWheelComplianceArea) {
        masterData.push({ name: translateString("areas"), url: urlProvider.getAreasUrl() });
    }

    if (platformFeatures.tags) {
        masterData.push({ name: translateString("Tags"), url: urlProvider.getTagsUrl() });
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />

            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders(translateString)}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={false}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    emptyText={translateString("noData")}
                    data={masterData}
                    renderRow={(row, i) => (
                        <TableRow onClick={() => redirectTo(row.url)} key={i} sx={styles.tableRow}>
                            <TableCell sx={styles.bold}>{row.name}</TableCell>
                            <TableCell>
                                <Box sx={[styles.greenArrowContainer, styles.iconContainer]}>{greenArrowRight}</Box>
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>
        </React.Fragment>
    );
}

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<MasterDataViewModel>>();
    headers.push({ property: "name", text: translateString("masterData"), align: "left", showOnMobile: true });
    return headers;
}
