import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import { DotLegalButton, DotLegalCheckbox, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import DotLegalCollapse from "../../../common/components/dotLegalCollapse/DotLegalCollapse";
import DotLegalInformationBox from "../../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import DotLegalPersonalDataSelector from "../../../common/components/dotLegalPersonalDataSelector/DotLegalPersonalDataSelector";
import DotLegalStepHeader from "../../../common/components/dotLegalStepHeader/DotLegalStepHeader";
import LegalEntityTable from "../../../legalEntity/legalEntityTable/LegalEntityTable";
import { useTranslation } from "../../../localization/useTranslation";
import {
    DataCategoryDataTypes,
    DataProcessor,
    ProcessingActivityModel,
    SelectableLegalUnitsItem,
    StepTypeEnum,
    SubProcessor,
} from "../../ProcessingActivity.types";
import SegmentStep from "../../stepSegment/StepSegment";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ValidationError } from "../../../common/validationError";
import {
    canAddNewSubProcessor,
    filterDataProcessors,
    getStepPermissions,
    isDataProcessorReadOnly,
    useDataProcessorsStepMapping,
} from "./DataProcessorsStep.hooks";
import { useDataProcessorsStepStyles } from "./DataProcessorsStep.styles";
import DataProcessorSvg from "../../processingActivities/dataProcessors.svg?react";
import SharingsInfoBox from "../sharingsInfoBox/SharingsInfoBox";
import SharingsLegalEntity from "../sharingsLegalEntityBox/sharingsLegalEntity";
import { systemsIcon } from "../../../common/icons";
import { useUrlProvider } from "../../../useUrlProvider";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { getSelectableDocumentItems, getSelectableTiaDocumentItems, getSelectedDocument, getSelectedDocuments } from "../../hooks/useDataProcessingAgreement";
import { DocumentStatus, DotLegalDocumentType } from "../../../documents/Documents.types";
import { isNullOrWhitespace } from "../../../common/stringOperations";
import EditLegalEntityDocumentDialog from "../../../legalEntity/legalEntityDocumentTab/editLegalEntityDocumentDIalog/EditLegalEntityDocumentDialog";
import { EUToUSDataPrivacyFrameworkId, filterTransferBasis } from "../../../legalBasis/TransferBasis.Helper";
import SharingsAgreementBox from "../sharingsAgreementBox/sharingsAgreementBox";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import PurpleLockSvg from "../../../common/svgs/purpleLock.svg?react";
import PlanDialogButtonWrapper from "../../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { dataContainedInThirdCountry } from "../../../legalEntity/isInsecureThirdCountry";
import SubDataProcessor from "./subDataProcessor/SubDataProcessor";
import { mapToDataCategorySelectorModel } from "../sharingOfDataStepFunctions.ts";

export interface IDataProcessorsStep {
    processingActivity: ProcessingActivityModel;
    dataProcessors: Array<DataProcessor>;
    entitesFromAssociation: Array<string>;
    validationErrors: Array<ValidationError>;
    isAddingDataProcessor: boolean;
    onDataProcessorChange: (processorId: string | null, dataProcessorId: string, selectedDataProcessor: SelectableLegalUnitsItem | undefined) => void;
    onCreateNewDocument: (agreement: string, dataProcessorId: string) => void;
    onTransferImpactAssessmentChange: (transferImpactAssessmentId: string | null, dataProcessorId: string) => void;
    onResponsibleChange: (responsibleId: string | null, dataProcessorId: string) => void;
    onChosenDataCategories: (chosenCategories: Array<DataCategoryDataTypes>, dataProcessorId: string) => void;
    onLegalEntitiesChange: (entities: Array<string>, dataProcessorId: string) => void;
    onRemoveDataProcessor: (dataProcessorId: string) => void;
    onAddNewDataProcessorClick: (groupEntityIsDataProcessor: boolean) => void;
    onSubProcessorDataCategoryClick: (chosenCategories: Array<DataCategoryDataTypes> | undefined, dataProcessorId: string, subprocessorIndex: number) => void;
    onStorageLocationChange: (
        storageLocations: Array<string>,
        supportLocations: Array<string>,
        dataProcessorId: string,
        dataContainedInThirdCountry: boolean
    ) => void;
    onSubDataProcessorChange: (
        processorId: string | null,
        dataProcessorId: string,
        subprocessorInde: number,
        selectedDataProcessor: SelectableLegalUnitsItem | undefined
    ) => void;
    onSubProcessorStorageLocationChange: (
        storageLocations: Array<string>,
        supportLocations: Array<string>,
        dataProcessorId: string,
        subprocessorIndex: number,
        dataContainedInThirdCountry: boolean
    ) => void;
    onSubProcessorAgreementChange: (hasAgreement: boolean, dataProcessorId: string, subprocessorInde: number) => void;
    onAddNewSubprocessorClick: (dataProcessorId: string, isLink: boolean) => void;
    onRemoveSubDataProcessor: (dataProcessorId: string, subprocessorIndex: number) => void;
    onTransferBasisChange: (transferBasis: string | null, dataProcessorId: string) => void;
    onSubProcessorTransferBasisChange: (transferBasis: string | null, dataProcessorId: string, index: number) => void;
    onSharingNameChange: (name: string, sharingId: string) => void;
    onSharingDescriptionChange: (description: string, sharingId: string) => void;
    onSubDataProcessorLinkChange: (link: string, dataProcessor: string, index: number) => void;
    onSubProcessorTransferImpactAssessmentChange: (tia: string | null, dataProcessorId: string, index: number) => void;
    // onDataHvadFilanSkalDenne???TypesChanged?: (dataCategoryDataTypes: DataCategoryDataTypes) => void;
    disableDataProcessors: boolean;
    onDisableDataProcessorsClick: (disabled: boolean, groupEntityIsDataProcessor: boolean) => void;
    groupEntityIsDataProcessor: boolean;
    hasData: boolean;
    isReadOnly: boolean;
}

function DataProcessorsStep(props: IDataProcessorsStep) {
    const { translateString, translateDocumentType } = useTranslation();
    const { getEditProcessingActivityUrl, getUserUrl } = useUrlProvider();
    const styles = useDataProcessorsStepStyles();
    const [showSubProcessorDataSelected, setShowSubProcessorDataSelected] = useState(false);

    const { addonsFeatures, informationSecurityFeatures } = usePlanContext();
    const { permissions, gdpo } = useUserContext();
    const userPermissions = getStepPermissions(props.groupEntityIsDataProcessor, permissions);

    const {
        dataCategoryLoading,
        dataCategoriesData,
        legalEntityLoading,
        legalEntityData,
        responsibleLoading,
        sendersLoading,
        sendersData,
        transferBasisesLoading,
        transferBasisesData,
        dataProcessorAgreementDocs,
        transferImpactAssessmentDocs,
        documentsLoading,
        getResponsibles,
        dataProcessorAgreementSaveModel,
        setDataProcessorAgreementSaveModel,
        dataprocessorId,
        setDataprocessorId,
        saveAgreement,
        selectedDocument,
        setSelectedDocument,
        showEditDocumentDialog,
        setShowEditDocumentDialog,
        refetchDocuments,
        getExpandedId,
        setExpandedId,
        subProcessorsTIADocsLoaing,
        subProcessorsTIADocs,
        countriesIsLoading,
        getSelectableCountries,
        thirdCountries,
        onAgreementAdded,
        onAgreementDeleted,
        onHasAgreementChange,
    } = useDataProcessorsStepMapping(props.processingActivity, props.groupEntityIsDataProcessor, props.onCreateNewDocument, props.dataProcessors);

    const handleRemoveDataProcessor = (processId: string) => {
        if (getExpandedId() === processId) setExpandedId("");

        props.onRemoveDataProcessor(processId);
    };

    const handleCollapseClick = (processId: string) => {
        if (processId) {
            if (getExpandedId() === processId) setExpandedId("");
            else setExpandedId(processId);
        }
    };

    const getLegalEntitiesForTable = (entities: Array<string>, isGlobalType: boolean, fetchAllEntitites: boolean, systemId?: string) => {
        if (sendersData) {
            if (isGlobalType || props.groupEntityIsDataProcessor || fetchAllEntitites) {
                return sendersData;
            } else return sendersData.filter((entity) => (systemId ? entities.indexOf(entity.id) >= 0 : props.entitesFromAssociation.indexOf(entity.id) >= 0));
        }
        return undefined;
    };

    const handleChosenDataCategories = (datacategories: Array<DataCategoryDataTypes> | undefined, dataProcessorId: string) => {
        if (dataCategoriesData) {
            props.onChosenDataCategories(datacategories!, dataProcessorId);
        } else {
            props.onChosenDataCategories([], dataProcessorId);
        }
    };

    const getCollapseDetails = (companies: Array<string>, dataprocessor: string | null | undefined, subProcessors: Array<SubProcessor>) => {
        let sender = "";
        let processor = "";
        let subprocessorChosen = "";

        if (!props.groupEntityIsDataProcessor) {
            companies = companies.filter((x) => props.processingActivity.legalEntities.includes(x));
        }

        if (companies.length > 0 && !legalEntityLoading) {
            let entity = legalEntityData?.find((x) => x.id === companies[0]);
            if (entity) {
                sender = `${entity.name} ${companies.length > 1 ? " + " + (companies.length - 1).toString() : ""}`;
            }
        }

        if (dataprocessor && !legalEntityLoading) {
            let entity = legalEntityData?.find((x) => x.id === dataprocessor);
            if (entity) {
                processor = entity.name;
            }
        }

        if (subProcessors.length > 0 && !legalEntityLoading) {
            let entity = legalEntityData?.find((x) => x.id === subProcessors[0].processorId);
            if (entity) {
                subprocessorChosen = `${entity.name} ${subProcessors.length > 1 ? " + " + (subProcessors.length - 1) : ""}`;
            }
        }

        return (
            <React.Fragment>
                <div>{sender}</div>
                <KeyboardBackspaceIcon fontSize="large" sx={styles.collapseArrow} />
                <div>{processor}</div>
                {subprocessorChosen !== "" ? <KeyboardBackspaceIcon fontSize="large" sx={styles.collapseArrow} /> : ""} {subprocessorChosen}
            </React.Fragment>
        );
    };

    const showSubProcessors = (dataProcessor: DataProcessor) => {
        return dataProcessor.subProcessors.length > 0 || showSubProcessorDataSelected;
    };

    const getSelectableLegalEntityItems = () => {
        if (legalEntityData) {
            if (props.groupEntityIsDataProcessor) return legalEntityData.filter((entity) => props.processingActivity.legalEntities.includes(entity.id));
            else return legalEntityData;
        }

        return [];
    };

    const getCollapseIcon = (dataProcessor: DataProcessor) => {
        if (dataProcessor.isSystemGenerated && permissions.processingActivitySystemPermissions) {
            return {
                icon: systemsIcon,
                iconHoverText: translateString("systemSharingDescription") + dataProcessor.name,
                href: getEditProcessingActivityUrl(props.processingActivity.id, StepTypeEnum.systems, undefined, dataProcessor.systemId),
            };
        } else if (
            props.groupEntityIsDataProcessor &&
            !dataProcessor.createdAsGroupEntityIsDataProcessor &&
            permissions.processingActivityDataProcessorsPermissions
        ) {
            const subStep = dataProcessor.hasDataProcessorAccess ? StepTypeEnum.dataprocessor : StepTypeEnum.dataController;
            return {
                icon: <DataProcessorSvg />,
                iconHoverText: translateString("sharingDataProcessor"),
                href: getEditProcessingActivityUrl(props.processingActivity.id, StepTypeEnum.sharings, subStep, dataProcessor.id),
            };
        } else if (
            !props.groupEntityIsDataProcessor &&
            dataProcessor.createdAsGroupEntityIsDataProcessor &&
            permissions.processingActivityDataControllersPermissions
        ) {
            const subStep = dataProcessor.hasDataControllerAccess ? StepTypeEnum.dataController : StepTypeEnum.dataprocessor;
            return {
                icon: <DataProcessorSvg />,
                iconHoverText: translateString("sharingDataController"),
                href: getEditProcessingActivityUrl(props.processingActivity.id, StepTypeEnum.sharings, subStep, dataProcessor.id),
            };
        }

        return undefined;
    };

    let errorStringToSearch = props.groupEntityIsDataProcessor ? "noDataSharingsDataProcessorGroupEntityisDataProcessor" : "noDataSharingsDataProcessor";
    return (
        <SegmentStep size="large">
            <DotLegalStepHeader
                toolTipText={props.groupEntityIsDataProcessor ? undefined : translateString("dataTransferOnProcessActivityHelperText")}
                record={false}
                topMargin
            >
                {props.groupEntityIsDataProcessor ? translateString("dataProcessorProcessActivity") : translateString("dataTransferOnProcessActivity")}
            </DotLegalStepHeader>

            {GetDataProcessors()}

            <Box sx={styles.addNewProcess}>
                <DotLegalButton
                    buttonType="secondary"
                    isLoading={props.isAddingDataProcessor}
                    disabled={props.disableDataProcessors || props.isReadOnly}
                    onClick={() => props.onAddNewDataProcessorClick(props.groupEntityIsDataProcessor)}
                >
                    {props.dataProcessors.length > 0
                        ? translateString("addAnotherProcessesToProcessActivity")
                        : translateString("addProcessesToProcessActivity")}
                </DotLegalButton>
            </Box>
            {!props.hasData && (
                <Box sx={styles.disableDataprocessor}>
                    <DotLegalCheckbox
                        checked={props.disableDataProcessors}
                        disabled={props.isReadOnly}
                        onChange={(e) => props.onDisableDataProcessorsClick(e, props.groupEntityIsDataProcessor)}
                        label={props.groupEntityIsDataProcessor ? translateString("disabledDataControllers") : translateString("disabledDataProcessors")}
                    />
                    {props.validationErrors.some((e) => e.field === errorStringToSearch) && (
                        <Box sx={styles.error}>{props.validationErrors.find((e) => e.field === errorStringToSearch)?.error}</Box>
                    )}
                </Box>
            )}

            {!informationSecurityFeatures.automaticRegistrationOfDataProcessorTransfers && (
                <PlanDialogButtonWrapper enabled>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1, marginTop: 3 }}>
                        <Typography sx={(theme) => ({ textDecoration: "underline", fontSize: "14px", color: theme.palette.primary.main })}>
                            {translateString("unlockAutomaticGeneratedDataProcessorSharings")}
                        </Typography>
                        <PurpleLockSvg />
                    </Box>
                </PlanDialogButtonWrapper>
            )}
        </SegmentStep>
    );

    function GetDataProcessors() {
        return (
            <>
                {filterDataProcessors(props.groupEntityIsDataProcessor, props.dataProcessors).map((dataProcessor, index) => {
                    let selectedDataProcessor = legalEntityData?.find((x) => x.id === dataProcessor.dataProcessor);
                    let isThirdCountry = dataContainedInThirdCountry(thirdCountries, dataProcessor.dataStorageLocations, selectedDataProcessor);
                    let showtransferBasis =
                        permissions.processingActivityDataProcessorsPermissions?.hasTiaAndTransferBasisPermission &&
                        !props.groupEntityIsDataProcessor &&
                        isThirdCountry &&
                        selectedDataProcessor?.isTransferBasisUnnecessary === false;
                    let readOnly = isDataProcessorReadOnly(dataProcessor, props.groupEntityIsDataProcessor, props.isReadOnly);
                    let fetchAllEntitites = props.groupEntityIsDataProcessor && !dataProcessor.createdAsGroupEntityIsDataProcessor;
                    let sharingName = dataProcessor.name;
                    let showTia =
                        permissions.processingActivityDataProcessorsPermissions?.hasTiaAndTransferBasisPermission &&
                        !props.groupEntityIsDataProcessor &&
                        isThirdCountry &&
                        selectedDataProcessor?.isTIAUnnecessary === false &&
                        dataProcessor.transferBasisId !== EUToUSDataPrivacyFrameworkId;
                    const hasDocuments = dataProcessor.dataProcessAgreements.length !== 0;

                    return (
                        <DotLegalCollapse
                            collapseName={sharingName}
                            id={dataProcessor.id}
                            key={dataProcessor.id}
                            disabled={
                                dataProcessor.isSystemGenerated ||
                                isDataProcessorReadOnly(dataProcessor, props.groupEntityIsDataProcessor, props.isReadOnly ?? false)
                            }
                            objectId={dataProcessor.systemId}
                            objectName={sharingName}
                            isLoading={sendersLoading || legalEntityLoading}
                            sx={[styles.collapse, styles.systemImage]}
                            isExpanded={getExpandedId() === dataProcessor.id}
                            centerContent={getCollapseDetails(dataProcessor.legalEntities, dataProcessor.dataProcessor, dataProcessor.subProcessors)}
                            removeItemText={translateString("removeProcess")}
                            onExpandClick={() => handleCollapseClick(dataProcessor.id!)}
                            onRemoveClick={() => handleRemoveDataProcessor(dataProcessor.id!)}
                            error={props.validationErrors.some((e) => e.field.startsWith(dataProcessor.id))}
                            collapseIcon={getCollapseIcon(dataProcessor)}
                        >
                            <SegmentStep size="small">
                                <SharingsInfoBox
                                    name={sharingName}
                                    description={dataProcessor.description}
                                    onDescriptionChange={(e) => props.onSharingDescriptionChange(e, dataProcessor.id)}
                                    onNameChange={(e) => props.onSharingNameChange(e, dataProcessor.id)}
                                    hideDescription={dataProcessor.isSystemGenerated && !!permissions.processingActivitySystemPermissions}
                                    disabled={dataProcessor.isSystemGenerated || readOnly}
                                />
                                <DotLegalStepHeader
                                    toolTipText={props.groupEntityIsDataProcessor ? translateString("personalDataSharingHelperText") : undefined}
                                    record
                                    topMargin
                                >
                                    {props.groupEntityIsDataProcessor ? translateString("dataProcessorRecipient") : translateString("dataTransferRecipient")}
                                </DotLegalStepHeader>
                                <SharingsLegalEntity
                                    isLoading={legalEntityLoading}
                                    selectedItem={dataProcessor.dataProcessor}
                                    disabled={dataProcessor.isSystemGenerated || readOnly}
                                    placeholder={translateString(props.groupEntityIsDataProcessor ? "transferDataController" : "ProcessorW")}
                                    label={translateString(props.groupEntityIsDataProcessor ? "transferDataController" : "ProcessorW")}
                                    options={getSelectableLegalEntityItems()}
                                    toolTipText={translateString(
                                        props.groupEntityIsDataProcessor ? "transferDataControllerHelperText" : "DataProcessorHelperText"
                                    )}
                                    errorText={props.validationErrors.find((v) => v.field === `${dataProcessor.id}dataProcessorId`)?.error}
                                    onLegalEntityChange={(processorWId) => {
                                        const entity = legalEntityData?.find((x) => x.id === processorWId);
                                        props.onDataProcessorChange(processorWId, dataProcessor.id, entity);
                                    }}
                                    showAddNewEntityButton={!(dataProcessor.isSystemGenerated || readOnly || props.groupEntityIsDataProcessor)}
                                    isThirdCountry={showtransferBasis}
                                    showStorageLocationLink={!dataProcessor.isSystemGenerated}
                                    countriesLoading={countriesIsLoading}
                                    countryList={getSelectableCountries()}
                                    selectedStorageLocations={dataProcessor.dataStorageLocations}
                                    selectedSupportLocations={dataProcessor.supportLocations}
                                    onDataStorageLocationSave={(storageLocations: Array<string>, supportLocations: Array<string>) => {
                                        const entity = legalEntityData?.find((x) => x.id === dataProcessor.dataProcessor);

                                        props.onStorageLocationChange(
                                            storageLocations,
                                            supportLocations,
                                            dataProcessor.id,
                                            dataContainedInThirdCountry(thirdCountries, storageLocations, entity)
                                        );
                                    }}
                                    warningText={dataProcessor.dataStorageLocations.length > 0 ? translateString("dataStoredInThirdCountry") : undefined}
                                />

                                {userPermissions.hasTiaAndTransferBasisPermission && (
                                    <React.Fragment>
                                        {showtransferBasis && (
                                            <DotLegalSelect
                                                selectedItem={dataProcessor.transferBasisId}
                                                isLoading={transferBasisesLoading}
                                                disabled={dataProcessor.isSystemGenerated || dataProcessor.readOnly || props.isReadOnly}
                                                label={translateString("transferBasis")}
                                                placeholder={translateString("transferBasis")}
                                                options={filterTransferBasis(transferBasisesData, selectedDataProcessor?.countryCode)}
                                                toolTipText={translateString("specifyDisclosureBasis")}
                                                errorText={props.validationErrors.find((v) => v.field === `${dataProcessor.id}transferBasisId`)?.error}
                                                onChange={(transferBasis) => props.onTransferBasisChange(transferBasis, dataProcessor.id)}
                                                noOptionsLabel={translateString("noOptions")}
                                            />
                                        )}
                                        {showTia && (
                                            <DotLegalSelect
                                                disableClearable
                                                selectedItem={getSelectedDocument(
                                                    dataProcessor.transferImpactAssessmentId,
                                                    dataProcessor.hasTransferImpactAssessment,
                                                    dataProcessor.containsNotAccessibleTia
                                                )}
                                                isLoading={documentsLoading}
                                                disabled={dataProcessor.isSystemGenerated || dataProcessor.readOnly || props.isReadOnly}
                                                label={translateString("tia")}
                                                placeholder={translateString("tia")}
                                                options={getSelectableTiaDocumentItems(
                                                    transferImpactAssessmentDocs,
                                                    translateString,
                                                    translateDocumentType,
                                                    dataProcessor.hasTransferImpactAssessment,
                                                    dataProcessor.containsNotAccessibleTia
                                                )}
                                                toolTipText={translateString("tiaTooltip")}
                                                errorText={props.validationErrors.find((v) => v.field === `${dataProcessor.id}transferImpactAssessment`)?.error}
                                                onChange={(tia) => props.onTransferImpactAssessmentChange(tia, dataProcessor.id)}
                                                noOptionsLabel={translateString("noOptions")}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                                {userPermissions.hasAgreementPermission && (
                                    <SharingsAgreementBox
                                        showAddAgreementBox={!isNullOrWhitespace(dataProcessor.dataProcessor ?? "") && !dataProcessor.isSystemGenerated}
                                        getSelectedDocuments={getSelectedDocuments(
                                            dataProcessor.dataProcessAgreements,
                                            dataProcessor.hasDataProcessorAgreement,
                                            dataProcessor.containsNotAccessibleDocuments
                                        )}
                                        options={getSelectableDocumentItems(
                                            dataProcessorAgreementDocs,
                                            translateString,
                                            translateDocumentType,
                                            dataProcessor.hasDataProcessorAgreement,
                                            dataProcessor.dataProcessAgreements,
                                            dataProcessor.containsNotAccessibleDocuments
                                        )}
                                        saveAgreement={(agreementId) => saveAgreement(agreementId, dataprocessorId)}
                                        id={dataprocessorId}
                                        disabled={dataProcessor.isSystemGenerated || readOnly}
                                        validationError={props.validationErrors.find((v) => v.field === `${dataProcessor.id}dataProcessorAgreement`)?.error}
                                        onYesOrNoChange={(hasAgreement) => onHasAgreementChange(hasAgreement, dataProcessor.id)}
                                        dataProcessorAgreementSaveModel={dataProcessorAgreementSaveModel}
                                        onAddNewAgreementClick={() => {
                                            setDataprocessorId(dataProcessor!.id);
                                            setDataProcessorAgreementSaveModel({
                                                legalEntityId: dataProcessor.dataProcessor!,
                                                name: "",
                                                type: addonsFeatures.research ? undefined : DotLegalDocumentType.DataProcessorAgreement,
                                                base64Data: "",
                                                fileName: "",
                                                link: "",
                                                isLink: false,
                                                owner: props.processingActivity.owner,
                                                status: permissions.canSetDocumentStatus ? undefined : DocumentStatus.draft,
                                                groupEntityAccessIds: [],
                                                allGroupEntities: gdpo || selectedDataProcessor!.isCustomerOwned,
                                                isGroupEntity: selectedDataProcessor?.isCustomerOwned,
                                            });
                                        }}
                                        onChipClick={
                                            hasDocuments
                                                ? (id) => {
                                                      setSelectedDocument({
                                                          documentId: id,
                                                          legalEntityId: dataProcessor.dataProcessor!,
                                                          name: "",
                                                          isGroupEntity: selectedDataProcessor?.isCustomerOwned,
                                                      });
                                                      setShowEditDocumentDialog(true);
                                                  }
                                                : undefined
                                        }
                                        singleSelect={!hasDocuments}
                                        isLoading={documentsLoading}
                                        stepType={StepTypeEnum.dataprocessor}
                                        onAddedAgreement={(value) => onAgreementAdded(value, dataProcessor.id)}
                                        onDeletedAgreement={(value) => onAgreementDeleted(value, dataProcessor.id)}
                                    />
                                )}
                                {userPermissions.hasResponsiblePermission && (
                                    <DotLegalSelect
                                        selectedItem={dataProcessor.responsible}
                                        isLoading={responsibleLoading}
                                        disabled={dataProcessor.isSystemGenerated || readOnly}
                                        label={translateString("responsible")}
                                        placeholder={translateString("responsible")}
                                        onChange={(responsibleId) => props.onResponsibleChange(responsibleId, dataProcessor.id)}
                                        options={getResponsibles(dataProcessor.responsible)}
                                        toolTipText={translateString("shareingResponsibleHelperText")}
                                        errorText={props.validationErrors.find((x) => x.field === `${dataProcessor.id}inactiveUser`)?.error}
                                        link={dataProcessor.responsible && permissions.canManageUsers ? getUserUrl(dataProcessor.responsible!) : undefined}
                                        noOptionsLabel={translateString("noOptions")}
                                    />
                                )}
                            </SegmentStep>

                            {userPermissions.hasDataCategoriesPermissions && (
                                <React.Fragment>
                                    {!dataProcessor.isSystemGenerated ? (
                                        <DotLegalStepHeader
                                            toolTipText={translateString(
                                                props.groupEntityIsDataProcessor ? "sharedDataControllerHelperText" : "sharedDataHelperText"
                                            )}
                                            record
                                            subHeader={translateString("noDataCategoriesSelectedProcess")}
                                            topMargin
                                            bottomMargin
                                        >
                                            {props.groupEntityIsDataProcessor ? translateString("dataTypeRecieved") : translateString("dataTypeSent")}
                                        </DotLegalStepHeader>
                                    ) : (
                                        <DotLegalStepHeader toolTipText={""} record subHeader={""} topMargin bottomMargin>
                                            {translateString("dataTypeSent")}
                                        </DotLegalStepHeader>
                                    )}

                                    {dataCategoriesData && (
                                        <DotLegalPersonalDataSelector
                                            disabled={readOnly || dataProcessor.isSystemGenerated}
                                            activityDataCategories={mapToDataCategorySelectorModel(
                                                dataCategoriesData!,
                                                props.processingActivity.dataCategories,
                                                dataProcessor.dataCategories
                                            )}
                                            onChange={(data) => handleChosenDataCategories(data, dataProcessor.id)}
                                            errorText={props.validationErrors.find((v) => v.field === `${dataProcessor.id}dataCategories`)?.error}
                                        />
                                    )}
                                </React.Fragment>
                            )}

                            <SegmentStep size="large">
                                <DotLegalStepHeader record={false} subHeader={translateString("entityChosenOnAssociation")} topMargin bottomMargin>
                                    {props.groupEntityIsDataProcessor
                                        ? translateString("companiesUsingDataProcesser")
                                        : translateString("organisationsUsingDataProcesser")}
                                </DotLegalStepHeader>
                                <LegalEntityTable
                                    disabled={dataProcessor.isSystemGenerated || readOnly}
                                    isLoading={sendersLoading}
                                    legalEntities={getLegalEntitiesForTable(
                                        dataProcessor.legalEntities,
                                        dataProcessor.isGeneratedFromGlobalSystem,
                                        fetchAllEntitites,
                                        dataProcessor.systemId
                                    )}
                                    selectedEntities={dataProcessor.legalEntities}
                                    showAddAllEntityButton
                                    showAddNewEntityButton={!(dataProcessor.isSystemGenerated || readOnly || !props.groupEntityIsDataProcessor)}
                                    errorText={props.validationErrors.find((v) => v.field === `${dataProcessor.id}legalEntities`)?.error}
                                    onLegalEntitiesChange={(entities) => props.onLegalEntitiesChange(entities, dataProcessor.id)}
                                />
                            </SegmentStep>

                            {userPermissions.hasSubProcessorsPermission && (
                                <React.Fragment>
                                    {showSubProcessors(dataProcessor) || !dataProcessor.isSystemGenerated ? (
                                        <React.Fragment>
                                            <DotLegalStepHeader
                                                record
                                                toolTipText={translateString(
                                                    props.groupEntityIsDataProcessor ? "dataSentToSubProcessorHelperText" : "containsubProcessorHelperText"
                                                )}
                                                topMargin
                                                bottomMargin
                                            >
                                                {props.groupEntityIsDataProcessor
                                                    ? translateString("dataSentToSubProcessor")
                                                    : translateString("isSubProcesserUsed")}
                                            </DotLegalStepHeader>
                                            <SegmentStep size="small">
                                                <Box sx={styles.subProcessorRadio}>
                                                    <Box sx={styles.yesSubProcessor}>
                                                        <DotLegalInformationBox
                                                            header={translateString("yes")}
                                                            size="small"
                                                            onChange={() => setShowSubProcessorDataSelected(true)}
                                                            isSelected={showSubProcessors(dataProcessor) ? "selected" : "unselected"}
                                                            isDisabled={readOnly}
                                                        />
                                                    </Box>
                                                    <div>
                                                        <DotLegalInformationBox
                                                            header={translateString("no")}
                                                            size="small"
                                                            onChange={() => setShowSubProcessorDataSelected(false)}
                                                            isSelected={!showSubProcessors(dataProcessor) ? "selected" : "unselected"}
                                                            isDisabled={dataProcessor.subProcessors.length > 0 || readOnly}
                                                        />
                                                    </div>
                                                </Box>
                                            </SegmentStep>
                                        </React.Fragment>
                                    ) : null}
                                    <Collapse in={showSubProcessors(dataProcessor)} sx={styles.subProcessorWrapper}>
                                        <SubDataProcessor
                                            groupEntityIsDataProcessor={props.groupEntityIsDataProcessor}
                                            dataCategoriesData={dataCategoriesData}
                                            isDataCategoryLoading={dataCategoryLoading}
                                            dataProcessor={dataProcessor}
                                            isLegalEntityLoading={legalEntityLoading}
                                            isReadOnly={readOnly}
                                            isTransferBasisLoading={transferBasisesLoading}
                                            legalEntityData={legalEntityData}
                                            documentsData={subProcessorsTIADocs}
                                            documentsLoading={subProcessorsTIADocsLoaing}
                                            onRemoveSubDataProcessor={props.onRemoveSubDataProcessor}
                                            onSubDataProcessorChange={props.onSubDataProcessorChange}
                                            onSubProcessorAgreementChange={props.onSubProcessorAgreementChange}
                                            onSubProcessorDataCategoryChange={props.onSubProcessorDataCategoryClick}
                                            onSubProcessorTransferBasisChange={props.onSubProcessorTransferBasisChange}
                                            onSubProcessorTransferImpactAssessmentChange={props.onSubProcessorTransferImpactAssessmentChange}
                                            transferBasisData={transferBasisesData}
                                            validationErrors={props.validationErrors}
                                            countriesLoading={countriesIsLoading}
                                            countryList={getSelectableCountries()}
                                            onSubProcessorStorageLocationChange={props.onSubProcessorStorageLocationChange}
                                            thirdCountries={thirdCountries}
                                            readonly={readOnly}
                                            subProcessors={dataProcessor.subProcessors}
                                            onAddNewSubprocessorClick={(isLink) => props.onAddNewSubprocessorClick(dataProcessor.id, isLink)}
                                            canAddNewSubDataProcessor={canAddNewSubProcessor(
                                                dataProcessor,
                                                props.groupEntityIsDataProcessor,
                                                props.isReadOnly ?? false
                                            )}
                                            onLinkChange={props.onSubDataProcessorLinkChange}
                                        />
                                    </Collapse>
                                </React.Fragment>
                            )}
                        </DotLegalCollapse>
                    );
                })}

                {showEditDocumentDialog && (
                    <EditLegalEntityDocumentDialog
                        onCloseDialog={() => {
                            setShowEditDocumentDialog(false);
                        }}
                        selectedDocument={selectedDocument!}
                        isExtended={false}
                        onSave={() => refetchDocuments()}
                        stepType={StepTypeEnum.dataprocessor}
                    />
                )}
            </>
        );
    }
}

export default DataProcessorsStep;
