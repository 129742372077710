import { useState } from "react";
import { post } from "../../common/api/apiShared";
import { useMutation } from "react-query";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { IUpgradePlanDialog } from "./UpgradeOrTrialDialog";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";

export function useUpgradeOrTrialDialog(props: IUpgradePlanDialog) {
    const [tryTrialClicked, setTryTrialClicked] = useState<boolean>(false);
    const { customerId } = useUserContext();
    const { planType } = usePlanContext();

    const beginTrial = async () => {
        await beginTrialMutation.mutateAsync();
        window.location.reload();
    };

    function begin() {
        return post<{}>("/plan/beginTrial", customerId);
    }

    const beginTrialMutation = useMutation(begin);

    function getNextTrialPlan() {
        if (planType === PlanType.GdprStarter) {
            return PlanType[2];
        }

        return PlanType[planType + 1];
    }

    return { tryTrialClicked, setTryTrialClicked, beginTrial, isBeginningTrial: beginTrialMutation.isLoading, getNextTrialPlan };
}
