import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, styled } from "@mui/material";
import { useAnnualWheelResponsibleForm } from "./AnnualWheelResponsibleForm.hooks";
import { useAnnualWheelDialogStyles } from "../AnnualWheelDialog.styles";
import { AnnualWheelResponsibleFormModel } from "./AnnualWheelResponsibleForm.types";
import {
    DotLegalCheckbox,
    DotLegalHeader,
    DotLegalInformationTooltip,
    DotLegalMultiSelect,
    DotLegalSelect,
    DotLegalSwitch,
    DotLegalTooltip,
    useIsOnSmallScreen,
} from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { ApprovalOption } from "../../AnnualWheel.types";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { PurpleLockIcon } from "../../../common/icons";
import { PlanType } from "../../../auth/planProvider/PlanProvider.types";
import LegalEntitySelectorWithAssociatedUser from "../../../legalEntity/legalEntitySelector/LegalEntitySelectorWithAssociatedUser";
import PlanInputFieldWrapper from "../../../plan/planInputFieldWrapper/PlanInputFieldWrapper";

export interface AnnualWheelResponsibleFormProps {
    saveModel: AnnualWheelResponsibleFormModel | undefined;
    onChange: (saveModel: AnnualWheelResponsibleFormModel) => void;
    showError: boolean;
}

const CheckboxWrapper = styled("div")({
    "& .MuiButtonBase-root": {
        marginLeft: "-11px",
    },
    "& .MuiTableSortLabel-root": {
        marginLeft: "0px",
    },
});

function AnnualWheelResponsibleForm(props: AnnualWheelResponsibleFormProps) {
    const { translateString } = useTranslation();
    const { platformFeatures } = usePlanContext();

    const userCtx = useUserContext();
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const {
        selectableBusinessAreaLoading,
        selectableBusinessAreaData,
        usersIsLoading,
        selectableResponsibles,
        selectableAssignees,
        selectableGroupCompaniesData,
        selectableGroupCompaniesLoading,
        onBusinessAreaChange,
        onResponsibleChange,
        onGroupCompaniesChange,
        onApprovalChange,
        onGroupCompanyResponsibleChange,
        onAssigneeChange,
        appliesAllGroupEntities,
        setAppliesAllGroupEntities,
    } = useAnnualWheelResponsibleForm(props);
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);

    const getErrorMessage = (error: string) => {
        if (error) return <Box sx={styles.error}>{error}</Box>;

        return undefined;
    };

    const isSignOffEnabled =
        props.saveModel?.responsible !== undefined ||
        (props.saveModel?.allCompaniesHasUsers(props.saveModel.groupCompanies ?? []) && props.saveModel.groupCompanies.length > 0);

    let assigneesPlaceholder =
        props.saveModel && selectableResponsibles && props.saveModel.responsible && props.saveModel.assignees.length === 0
            ? selectableResponsibles.find((x) => x.id === props.saveModel!.responsible)?.name
            : "";

    return (
        <Box sx={styles.formSpacing}>
            <DotLegalHeader headerStyle={"small"} marginTop={2} marginBottom={0.5}>
                {translateString("businessAreasForTask")}
            </DotLegalHeader>

            <DotLegalMultiSelect
                label={""}
                options={selectableBusinessAreaData}
                isLoading={selectableBusinessAreaLoading}
                chosenOptions={props.saveModel?.businessAreas ?? []}
                placeholder={""}
                onChange={(businessAreas) => onBusinessAreaChange(businessAreas)}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />
            {getErrorMessage(props.showError && props.saveModel?.IsModelInvalid() ? translateString("annualWheelDataTaskResponsibleError") : "")}

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5} toolTip={translateString("responsibleForTaskTooltip")}>
                {translateString("responsibleForTask")}
            </DotLegalHeader>

            <DotLegalSelect
                options={selectableResponsibles}
                isLoading={usersIsLoading}
                selectedItem={props.saveModel?.responsible}
                placeholder={""}
                label={""}
                onChange={(responsible) => onResponsibleChange(responsible)}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />
            {getErrorMessage(props.showError && props.saveModel?.IsModelInvalid() ? translateString("annualWheelDataTaskResponsibleError") : "")}

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                {translateString("annualWheelActivityAssignee")}
            </DotLegalHeader>

            <DotLegalMultiSelect
                label={""}
                options={selectableAssignees}
                isLoading={usersIsLoading}
                chosenOptions={props.saveModel?.assignees ?? []}
                placeholder={assigneesPlaceholder}
                onChange={(assigness) => onAssigneeChange(assigness)}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />

            <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                {translateString("doesTasksNeedApproval")}
            </DotLegalHeader>

            <PlanInputFieldWrapper hasAccess={platformFeatures.signOff} hoverText={translateString("upgradePrivacyPlanToday")}>
                <DotLegalSelect
                    options={[
                        {
                            id: ApprovalOption.ApprovalNotRequired.toString(),
                            name: translateString("approvalNotRequired"),
                        },
                        {
                            id: ApprovalOption.ApprovalNotRequiredResponsibleGetNotified.toString(),
                            name: translateString("approvalNotRequiredResponsibleGetNotified"),
                            disabled: !isSignOffEnabled,
                            hoverText: !isSignOffEnabled ? translateString("responsibleRequiredForApprovalTooltip") : undefined,
                        },
                        {
                            id: ApprovalOption.ApprovalRequiredByResponsible.toString(),
                            name: translateString("approvalRequiredByResponsible"),
                            disabled: !isSignOffEnabled,
                            hoverText: !isSignOffEnabled ? translateString("responsibleRequiredForApprovalTooltip") : undefined,
                        },
                    ]}
                    isLoading={usersIsLoading}
                    selectedItem={props.saveModel?.approval.toString()}
                    placeholder={""}
                    label={""}
                    onChange={(option) => onApprovalChange(option!)}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                    disableClearable
                    disableSearch
                    disabled={!platformFeatures.signOff}
                    icon={!platformFeatures.signOff ? PurpleLockIcon : undefined}
                />
            </PlanInputFieldWrapper>

            {selectableGroupCompaniesData && selectableGroupCompaniesData?.length > 1 && userCtx.gdpo && (
                <>
                    <DotLegalHeader headerStyle={"small"} marginTop={4} marginBottom={0.5}>
                        {translateString("annualWheelActivityAppliesFor")}
                    </DotLegalHeader>

                    <DotLegalSwitch
                        onChange={() => {
                            setAppliesAllGroupEntities(!appliesAllGroupEntities);
                            onGroupCompaniesChange([]);
                        }}
                        checked={appliesAllGroupEntities}
                        rightLabel={
                            <Box component={"span"} sx={{ display: "inline-flex", gap: 1 }}>
                                {translateString("globalAnnualWheelActivity")}

                                <DotLegalInformationTooltip text={translateString("globalAnnualWheelActivityTooltip")} />
                            </Box>
                        }
                        greyUncheckedTrack
                    />

                    {!appliesAllGroupEntities && (
                        <Box
                            sx={(theme) => ({
                                marginTop: 3,
                                marginLeft: "-11px",
                                "& .MuiAutocomplete-root .MuiInputBase-input": {
                                    fontSize: theme.typography.pxToRem(12),
                                },
                                "& .MuiAutocomplete-root .MuiInputBase-input::placeholder": {
                                    fontSize: theme.typography.pxToRem(12),
                                },
                            })}
                        >
                            <LegalEntitySelectorWithAssociatedUser
                                marginTop={0}
                                allColumns={false}
                                isLoading={selectableGroupCompaniesLoading}
                                selectedEntities={props.saveModel?.groupCompanies.map((x) => x.groupCompanyId) ?? []}
                                legalEntityItems={selectableGroupCompaniesData.map((item) => {
                                    return {
                                        users: selectableResponsibles,
                                        ...item,
                                    };
                                })}
                                setSelectedEntities={(e) => onGroupCompaniesChange(e)}
                                hideGridSize
                                gridlength={10000}
                                onResponsibleChange={onGroupCompanyResponsibleChange}
                                userGroupCompanies={props.saveModel?.groupCompanies ?? []}
                                mainResponsible={selectableResponsibles?.find((x) => x.id === props.saveModel?.responsible) ?? undefined}
                            />
                            {getErrorMessage(
                                props.showError && props.saveModel?.IsModelInvalid() ? translateString("annualWheelDataTaskResponsibleError") : ""
                            )}
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}

export default AnnualWheelResponsibleForm;
