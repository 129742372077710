import {
    DotLegalEmptyState,
    DotLegalMultiSelect,
    DotLegalOverflowMenu,
    DotLegalOverflowMenuItem,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
    useDotLegalSnackbar,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { Box, Grid, GridSize, IconButton, TableCell, TableRow } from "@mui/material";
import { InternalAssessmentApiModel, InternalAssessmentApiRow, InternalAssessmentViewRow } from "../../../../internalAssessment/InternalAssessments.types";
import React, { ReactNode } from "react";
import { LegalEntityAuditStatus } from "../../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import QuestionnaireTemplateLink from "../../../../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink";
import { NoteSVG } from "../../../icons";
import { getSettings } from "../../../settingsProvider";
import ComplianceAreaWrapper from "../../complianceAreaWrapper/ComplianceAreaWrapper";
import QuestionnaireEvaluation from "../questionnaireEvaluation/QuestionnaireEvaluation";
import QuestionnaireReportButtons from "../questionnaireReportButtons/QuestionnaireReportButtons";
import QuestionnaireReportOverflowButtons from "../questionnaireReportButtons/QuestionnaireReportOverflowButtons";
import QuestionnaireRisk from "../questionnaireRisk/QuestionnaireRisk";
import QuestionnaireStatus from "../questionnaireStatus/QuestionnaireStatus";
import useInternalAssessmentTableHooks from "./InternalAssessmentTable.hooks";
import DeleteInternalAssessmentDialog from "../../../../internalAssessment/deleteInternalAssessmentDialog/DeleteInternalAssessmentDialog";
import CloseInternalAssessmentDialog from "../../../../internalAssessment/closeInternalAssessmentDialog/CloseInternalAssessmentDialog";
import ReopenInternalAssessmentDialog from "../../../../internalAssessment/reopenInternalAssessmentDialog/ReopenInternalAssessmentDialog";
import ChangeAssigneeDialog from "../../../../internalAssessment/changeAssigneeDialog/ChangeAssigneeDialog";
import ChangeInternalAssessmentResponsibleDialog from "../../../../internalAssessment/changeInternalAssessmentResponsibleDialog/ChangeInternalAssessmentResponsibleDialog";
import DotLegalNoteDialog from "../../dotLegalNoteDialog/DotLegalNoteDialog";
import SendInternalAssessmentReminderDialog from "../../../../internalAssessment/sendInternalAssessmentReminderDialog/SendInternalAssessmentReminderDialog";
import EvaluateInternalAssessmentDialog from "../../../../internalAssessment/evaluateInternalAssessmentDialog/EvaluateInternalAssessmentDialog";
import ToggleInternalAssessmentRemindersDialog from "../../../../internalAssessment/toggleInternalAssessmentRemindersDialog/ToggleInternalAssessmentRemindersDialog";
import PublicAssessment from "../../../svgs/Public assessment.svg?react";
import { useUrlProvider } from "../../../../useUrlProvider";
import { isNullOrWhitespace } from "../../../stringOperations";

export interface IInternalAssessmentTableProps {
    internalAssessmentModel: InternalAssessmentApiModel | undefined;
    isLoading: boolean;
    refetch?: () => void;
    readOnly?: boolean;
    emptyState: ReactNode;
    getMenuItems: (row: InternalAssessmentApiRow) => Array<DotLegalOverflowMenuItem>;
    onNoteClick: (row: InternalAssessmentApiRow) => void;
}

function InternalAssessmentTable(props: IInternalAssessmentTableProps) {
    const { translateString, translateDate } = useTranslation();

    const {
        audits,
        hasData,
        selectableTemplates,
        selectedTemplates,
        setSelectedTemplates,
        selectableAreas,
        selectedAreas,
        setSelectedAreas,
        selectableResponsibles,
        selectedResponsibles,
        setSelectedResponsibles,
        selectableStatus,
        selectedStatus,
        setSelectedStatus,
        setSelectedInternalAssessment,
        getReportPdfUrl,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        setShowNote,
        selectableEvaluationStatus,
        selectedEvaluations,
        setSelectedEvaluations,
    } = useInternalAssessmentTableHooks(props);

    function getHeaders() {
        let headers = Array<ITableHeader<InternalAssessmentViewRow>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "uniqueIdentifier", text: "Id", align: "left", showOnMobile: true });
        headers.push({ property: "sent", text: translateString("assessmentSent"), align: "left", showOnMobile: true });
        headers.push({ property: "deadline", text: translateString("deadline"), align: "left", showOnMobile: true });
        headers.push({ property: "responsibleName", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "assigneeName", text: translateString("assigned"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaireName", text: translateString("assessmentTemplate"), align: "left", showOnMobile: true });
        headers.push({ property: "complianceAreasString", text: translateString("complianceAreas"), align: "left", showOnMobile: true });
        headers.push({ property: "sender", text: translateString("sentFrom"), align: "left", showOnMobile: true });
        headers.push({ property: "statusString", text: translateString("status"), align: "left", showOnMobile: true });
        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "scoreName", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "questionnaireId", text: translateString("assessmentReport"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "reminderSent", text: translateString("headerReminderSent"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });

        return headers;
    }

    function formatNote(row: InternalAssessmentApiRow) {
        return (
            row.note && (
                <IconButton
                    onClick={() => {
                        props.onNoteClick(row);
                        setSelectedInternalAssessment(row);
                        setShowNote(true);
                    }}
                >
                    {NoteSVG}
                </IconButton>
            )
        );
    }

    function getName(row: InternalAssessmentApiRow) {
        const publicAssessmentTooltip =
            `${translateString("publicInternalAssessment")} ${row.publicTemplateIsDeleted ? `(${translateString("deleted")})` : ""}`.trimEnd();
        const publicAssessment = (
            <Box>
                {row.createdFromPublicTemplate && (
                    <DotLegalTooltip text={`${publicAssessmentTooltip}${isNullOrWhitespace(row.remark) ? "" : ": " + row.remark}`}>
                        <Box sx={{ marginLeft: 0.5 }}>
                            <PublicAssessment />
                        </Box>
                    </DotLegalTooltip>
                )}
            </Box>
        );
        if (row.description) {
            return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DotLegalTooltip text={row.description}>
                        <Box>{row.name}</Box>
                    </DotLegalTooltip>
                    {publicAssessment}
                </Box>
            );
        }

        return (
            <Box sx={{ display: "flex" }}>
                {row.name} {publicAssessment}
            </Box>
        );
    }

    function getFilters() {
        const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
        };

        return (
            <React.Fragment>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableTemplates}
                        noMargin
                        chosenOptions={selectedTemplates}
                        onChange={setSelectedTemplates}
                        label={translateString("assessmentTemplate")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableAreas}
                        noMargin
                        chosenOptions={selectedAreas}
                        onChange={setSelectedAreas}
                        label={translateString("complianceAreas")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableStatus}
                        noMargin
                        chosenOptions={selectedStatus}
                        onChange={setSelectedStatus}
                        label={translateString("status")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableResponsibles}
                        noMargin
                        chosenOptions={selectedResponsibles}
                        onChange={setSelectedResponsibles}
                        label={translateString("responsible")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        options={selectableEvaluationStatus}
                        noMargin
                        chosenOptions={selectedEvaluations}
                        onChange={setSelectedEvaluations}
                        label={translateString("evaluation")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
            </React.Fragment>
        );
    }

    function getComplianceAreas(row: InternalAssessmentApiRow) {
        return (
            <Box sx={{ display: "flex", gap: 1 }}>
                {row.complianceAreas.map((c) => (
                    <ComplianceAreaWrapper key={c.id} complianceArea={c.name} />
                ))}
            </Box>
        );
    }

    function getStatus(row: InternalAssessmentApiRow) {
        return (
            <QuestionnaireStatus
                showQuestionsStatistics={row.showQuestionsStatistics}
                numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                totalNumberOfQuestions={row.totalNumberOfQuestions}
                status={row.status}
                lastestRecipientUpdate={row.lastestRecipientUpdate}
            />
        );
    }

    function getRisk(row: InternalAssessmentApiRow) {
        return <QuestionnaireRisk riskLevel={row.riskLevel} riskName={row.riskName} scoreEnabled={row.scoreEnabled} status={row.status} />;
    }

    function getReportButtons(row: InternalAssessmentApiRow) {
        let showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.isClosed;
        let isDownloading = isDownloadingPdf === row.id;
        let reportUrl = getReportPdfUrl(row, false, row.hasRecommendations);

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row)}
                    showButtons={showButtons}
                    isDownloadingPdf={isDownloading}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={isDownloading}
                onDownloadReportPdf={() => onDownloadReportAsPdf(row, true, false)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getReportButtonMenuItems(row: InternalAssessmentApiRow) {
        if (row.scoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf(row, false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf(row, true, false),
                },
            ];
        } else {
            return [
                { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, false, true) },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf(row, false, false),
                },
            ];
        }
    }

    return (
        <>
            {hasData ? (
                <DotLegalTableWithControls
                    headers={getHeaders()}
                    extraControls={getFilters()}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    defaultOrderBy={"sent"}
                    defaultOrder={"desc"}
                    isLoading={props.isLoading}
                    clickableRows={false}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    emptyText={translateString("noData")}
                    data={audits ?? []}
                    renderRow={(row) => (
                        <TableRow key={row.id}>
                            <TableCell>{getName(row)}</TableCell>
                            <TableCell>{row.uniqueIdentifier}</TableCell>
                            <TableCell>{translateDate(row.sent)}</TableCell>
                            <TableCell>{translateDate(row.deadline)}</TableCell>
                            <TableCell>{row.responsibleName}</TableCell>
                            <TableCell>{row.assigneeName}</TableCell>
                            <TableCell>
                                <QuestionnaireTemplateLink type={"audit"} id={row.assessmentTemplate.id} name={row.assessmentTemplate.name} />
                            </TableCell>
                            <TableCell>{getComplianceAreas(row)}</TableCell>
                            <TableCell>{row.sender}</TableCell>
                            <TableCell>{getStatus(row)}</TableCell>
                            <TableCell>{getRisk(row)}</TableCell>
                            <TableCell>{row.scoreName}</TableCell>
                            <TableCell>{getReportButtons(row)}</TableCell>
                            <TableCell>
                                <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                            </TableCell>
                            <TableCell>
                                {row.remindersPaused ? translateString("remindersPaused") : row.reminderSent ? translateDate(row.reminderSent) : ""}
                            </TableCell>
                            <TableCell>{formatNote(row)}</TableCell>

                            <TableCell align={"right"}>{<DotLegalOverflowMenu items={props.getMenuItems(row)} />}</TableCell>
                        </TableRow>
                    )}
                />
            ) : (
                <Box
                    sx={{
                        img: {
                            width: "25%",
                        },
                    }}
                >
                    {props.emptyState}
                </Box>
            )}
        </>
    );
}

export default InternalAssessmentTable;
