export function isNullOrWhitespace(input?: string) {
    return !input || !input.trim();
}

export function setFirstLetterToLowerCase(value: string) {
    return value.charAt(0).toLowerCase() + value.slice(1);
}

export function isEmptyStringOrTruthy(value?: string) {
    return value || value === "";
}
