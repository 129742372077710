import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ProcessingActivities from "./processingActivity/processingActivities/ProcessingActivities";
import ProcessingActivityOverview from "./processingActivity/processingActivityOverview/ProcessingActivityOverview";
import ProcessingActivity from "./processingActivity/ProcessingActivity";
import ErrorPage from "./errorPage/ErrorPage";
import { useTranslation } from "./localization/useTranslation";
import SignInCallback from "./auth/SignInCallback";
import LegalEntities from "./legalEntity/legalEntities/LegalEntities";
import LegalEntity from "./legalEntity/LegalEntity";
import Settings, { hasSettingsAccess } from "./privacySettings/Settings";
import Users from "./user/users/Users";
import User from "./user/User";
import Customers from "./customer/customers/Customers";
import Record from "./record/Record";
import ChangeOrganization from "./auth/ChangeOrganization";
import { MasterData } from "./masterData/MasterData";
import Purposes from "./masterData/purpose/purposes/Purposes";
import SecurityMeasures from "./masterData/securityMeasure/securityMeasures/SecurityMeasures";
import BusinessAreas from "./masterData/businessArea/businessAreas/BusinessAreas";
import { DeletionPeriods } from "./masterData/deletionPeriod/deletionPeriods/DeletionPeriods";
import { DataCategories } from "./masterData/dataCategory/dataCategories/DataCategories";
import DataSubjects from "./masterData/dataSubject/dataSubjects/DataSubjects";
import RiskAssessments from "./riskAssessment/riskAssessments/RiskAssessments";
import ProcessingActivityRiskScenariosDialog from "./riskAssessment/processingActivityRiskScenariosDialog/ProcessingActivityRiskScenariosDialog";
import ProcessingActivityRiskQuestionDialog from "./riskAssessment/riskAssessmentQuestionDialog/RiskAssessmentQuestionDialog";
import RiskAssessmentTemplate from "./riskAssessment/riskAssessmentTemplate/RiskAssessmentTemplate";
import Systems from "./system/systems/Systems";
import SystemOverview from "./system/systemOverview/SystemOverview";
import System from "./system/systemEdit/SystemEdit";
import NationalLaws from "./masterData/nationalLaw/nationalLaws/NationalLaws";
import Legislations from "./masterData/legislation/legislations/Legislations";
import AnnualWheel from "./annualWheel/AnnualWheel";
import TaskManagement from "./taskManagement/TaskManagement";
import AnnualWheelPDF from "./annualWheel/AnnualWheelPDF";
import AddEditCustomerDialog from "./customer/addEditCustomerDialog/AddEditCustomerDialog";
import Notification from "./notification/Notification";
import CustomLists from "./customLists/CustomLists";
import { CallPage, CustomListType } from "./customLists/CustomLists.types";
import CustomListView from "./customLists/customListView/CustomListView";
import Roles from "./roles/Roles";
import { useUserContext } from "./auth/userContextProvider/UserContextProvider";
import Policies from "./policy/policies/Policies";
import PolicyOverview from "./policy/policyOverview/PolicyOverview";
import HelpTexts from "./masterData/helpText/helpTexts/HelpTexts";
import ProcessingActivityStatistics from "./processingActivity/processingActivityStatistics/ProcessingActivityStatistics";
import RegistrationOfResearchProjectReport from "./processingActivityReport/registrationOfResearchProjectReport/RegistrationOfResearchProjectReport";
import InternalAcquisitionOfSignatureReport from "./processingActivityReport/internalAcquisitionOfSignatureReport/InternalAcquisitionOfSignatureReport";
import ProcessingActivityRiskPDF from "./riskAssessment/riskAssessmentDialog/PDF/RiskAssessmentPDF";
import ProcessingActivityStatisticsPdf from "./processingActivity/processingActivityStatistics/pdf/ProcessingActivityStatisticsPdf";
import DeepLinkUpgradeDialog from "./plan/deepLinkUpgradeDialog/DeepLinkUpgradeDialog";
import Certifications from "./masterData/certification/certifications/Certifications";
import ExportCustomList from "./customLists/exportCustomList/ExportCustomList";
import Areas from "./masterData/area/areas/Areas";
import RiskAssessmentVersions from "./masterData/riskAssessmentVersion/riskAssessmentVersions/RiskAssessmentVersions";
import IncidentLog from "./incidentLog/incidentLog/IncidentLog";
import IncidentLogOverview from "./incidentLog/incidentLogOverview/IncidentLogOverview";
import ResearchEthicsApprovalReport from "./processingActivityReport/researchEthicsApprovalReport/ResearchEthicsApprovalReport";
import { ProcessingActivityStatus } from "./processingActivity/processingActivities/ProcessingActivities.types";
import { LegalEntityPageEnum } from "./legalEntity/legalEntities/LegalEntities.types";
import LegalEntityManagement from "./legalEntity/legalEntityManagement/LegalEntityManagement";
import InternalAssessments from "./internalAssessment/InternalAssessments";
import LegalEntityClassification from "./legalEntity/LegalEntityClassification.tsx";
import Declarations from "./declaration/declarations/Declarations";
import Declaration from "./declaration/Declaration";
import DeclarationCategories from "./declarationCategory/DeclarationCategories.tsx";
import PublicInternalAssessmentTemplates from "./internalAssessment/publicInternalAssessmentTemplate/PublicInternalAssessmentTemplates.tsx";
import LegalEntityAuditGroups from "./legalEntityAuditGroup/legalEntityAuditGroups/LegalEntityAuditGroups.tsx";
import LegalEntityAuditGroupOverview from "./legalEntityAuditGroup/legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.tsx";
import LegalEntityAuditGroupDraftOverview from "./legalEntityAuditGroup/legalEntityAuditGroupDraftOverview/LegalEntityAuditGroupDraftOverview.tsx";
import QuestionnairesListOverview from "./questionnaireAdministration/QuestionnairesListOverview.tsx";
import Dashboard from "./dashboard/Dashboard.tsx";
import ComplianceReport from "./dashboard/complianceReport/ComplianceReport.tsx";
import { usePlanContext } from "./auth/planProvider/PlanProvider.tsx";
import { PlanType } from "./auth/planProvider/PlanProvider.types.ts";
import Tags from "./masterData/tag/Tags.tsx";
import DataTypesList from "./masterData/dataCategory/dataTypes/DataTypesList.tsx";

function RouterSwitch() {
    const { translateString } = useTranslation();
    const navigate = useNavigate();
    const { permissions } = useUserContext();
    const { addonsFeatures } = usePlanContext();

    const getPermissionDefaultPage = () => {
        if (!addonsFeatures.research) return <Navigate to="privacy/dashboard" />;
        else if (permissions.processingActivityPermissions.read) return <Navigate to="privacy/processingactivities" />;
        else if (permissions.canAccessRecord301 || permissions.canAccessRecord302) return <Navigate to="privacy/record" />;
        else if (permissions.systemPermissions.read) return <Navigate to="privacy/systems" />;
        else if (permissions.riskPermissions.read) return <Navigate to="privacy/risk-assessments" />;
        else if (permissions.annualWheelPermission.read) return <Navigate to="privacy/annual-wheel" />;
        else if (permissions.taskPermissions.read) return <Navigate to="privacy/task-management" />;
        else if (permissions.companyPermissions.read) return <Navigate to="privacy/legal-entities" />;
        else if (hasSettingsAccess(permissions)) return <Navigate to="privacy/settings" />;

        return <ErrorPage errorMessage={translateString("forbiddenAccess")} image="NoAccess" />;
    };

    return (
        <Routes>
            <Route path="privacy">
                <Route path="dashboard">
                    <Route path="" element={<Dashboard />} />
                </Route>

                <Route path="processingactivities" element={<ProcessingActivities />} />

                <Route path="processingactivity">
                    <Route path=":id">
                        <Route path="overview" element={<ProcessingActivityOverview />} />
                        <Route path="edit" element={<ProcessingActivity processsingActivityValidation={false} />} />
                        <Route path="validate" element={<ProcessingActivity processsingActivityValidation />} />
                    </Route>
                </Route>

                <Route path="systems" element={<Systems />} />
                <Route path="system">
                    <Route path=":id">
                        <Route path="overview" element={<SystemOverview />} />
                        <Route path="edit" element={<System />} />
                    </Route>
                </Route>

                <Route path="record" element={<Record />} />

                <Route path="legal-entities">
                    <Route path=":id" element={<LegalEntity legalEntityPage={LegalEntityPageEnum.Vendor} />} />
                    <Route path=":id/management" element={<LegalEntityManagement legalEntityPage={LegalEntityPageEnum.Vendor} />}></Route>
                    <Route path=":id/classification/*" element={<LegalEntityClassification legalEntityPage={LegalEntityPageEnum.Vendor} />}></Route>
                    <Route path="" element={<LegalEntities legalEntityPage={LegalEntityPageEnum.Vendor} />} />
                </Route>

                <Route path="group-entities">
                    <Route path=":id" element={<LegalEntity legalEntityPage={LegalEntityPageEnum.GroupEntity} />} />
                    <Route path=":id/management" element={<LegalEntityManagement legalEntityPage={LegalEntityPageEnum.GroupEntity} />}></Route>
                    <Route path=":id/classification/*" element={<LegalEntityClassification legalEntityPage={LegalEntityPageEnum.GroupEntity} />}></Route>
                    <Route path="" element={<LegalEntities legalEntityPage={LegalEntityPageEnum.GroupEntity} />} />
                </Route>

                <Route path="customer-legal-entities">
                    <Route path=":id" element={<LegalEntity legalEntityPage={LegalEntityPageEnum.Customer} />} />
                    <Route path=":id/management" element={<LegalEntityManagement legalEntityPage={LegalEntityPageEnum.Customer} />}></Route>
                    <Route path=":id/classification/*" element={<LegalEntityClassification legalEntityPage={LegalEntityPageEnum.Customer} />}></Route>
                    <Route path="" element={<LegalEntities legalEntityPage={LegalEntityPageEnum.Customer} />} />
                </Route>

                <Route path="other-legal-entities">
                    <Route path=":id" element={<LegalEntity legalEntityPage={LegalEntityPageEnum.Other} />} />
                    <Route path=":id/management" element={<LegalEntityManagement legalEntityPage={LegalEntityPageEnum.Other} />}></Route>
                    <Route path=":id/classification/*" element={<LegalEntityClassification legalEntityPage={LegalEntityPageEnum.Other} />}></Route>
                    <Route path="" element={<LegalEntities legalEntityPage={LegalEntityPageEnum.Other} />} />
                </Route>

                <Route path="risk-assessments" element={<RiskAssessments />} />
                <Route path="settings" element={<Settings />} />

                <Route path="users" element={<Users />} />
                <Route path="user">
                    <Route path=":id" element={<User />} />
                </Route>

                <Route path="customers" element={<Customers />} />
                <Route
                    path="customer/:id/edit"
                    element={
                        <AddEditCustomerDialog
                            selectedCustomer={undefined}
                            onCloseDialog={() => {
                                navigate(-1);
                            }}
                        />
                    }
                />

                <Route path="masterdata">
                    <Route path="" element={<MasterData />} />
                    <Route path="purposes" element={<Purposes />} />
                    <Route path="business-areas" element={<BusinessAreas />} />
                    <Route path="security-measures" element={<SecurityMeasures />} />
                    <Route path="deletion-periods" element={<DeletionPeriods />} />
                    <Route path="data-categories" element={<DataCategories />} />
                    <Route path="data-types" element={<DataTypesList />} />
                    <Route path="data-subjects" element={<DataSubjects />} />
                    <Route path="national-laws" element={<NationalLaws />} />
                    <Route path="legislations" element={<Legislations />} />
                    <Route path="help-texts" element={<HelpTexts />} />
                    <Route path="certifications" element={<Certifications />} />
                    <Route path="areas" element={<Areas />} />
                    <Route path="risk-assessment-versions" element={<RiskAssessmentVersions />} />
                    <Route path="tags" element={<Tags />} />
                </Route>

                <Route path="sign-in-callback" element={<SignInCallback />} />
                <Route path="change-organization" element={<ChangeOrganization />} />
                <Route path="annual-wheel" element={<AnnualWheel />} />
                <Route path="task-management" element={<TaskManagement />} />
                <Route path="annual-wheel-PDF" element={<AnnualWheelPDF />} />
                <Route path="notifications" element={<Notification />} />
                <Route path="custom-processing-activity-lists" element={<CustomLists listType={CustomListType.ProcessingActivity} />} />
                <Route path="custom-list-view/:customListId" element={<CustomListView />} />
                <Route path="custom-company-lists" element={<CustomLists listType={CustomListType.Company} />} />
                <Route path="custom-group-company-lists" element={<CustomLists listType={CustomListType.Company} callPage={CallPage.GroupCompanies} />} />
                <Route path="custom-sharing-lists" element={<CustomLists listType={CustomListType.Sharing} />} />
                <Route path="custom-system-lists" element={<CustomLists listType={CustomListType.System} />} />
                <Route path="custom-list/export" element={<ExportCustomList />} />
                <Route path="roles" element={<Roles />} />

                <Route path="role">
                    <Route path=":id/edit" element={<Roles />} />
                    <Route path=":roleid/edit-permission" element={<Roles />} />
                </Route>

                <Route path="policies" element={<Policies />} />
                <Route path="policy/:policyId" element={<PolicyOverview />} />

                <Route path="declarations">
                    <Route path=":id" element={<Declaration />} />
                    <Route path="" element={<Declarations />} />
                </Route>

                <Route path="declaration-categories/:id" element={<DeclarationCategories />} />

                <Route path="internal-assessments" element={<InternalAssessments />} />
                <Route path="internal-assessment-templates" element={<PublicInternalAssessmentTemplates />} />

                <Route path="audit-groups" element={<LegalEntityAuditGroups />} />

                <Route path="audit-group/:id" element={<LegalEntityAuditGroupOverview />} />
                <Route path="audit-group/:id/draft" element={<LegalEntityAuditGroupDraftOverview />} />

                <Route path="processing-activity">
                    <Route path=":id">
                        <Route
                            path="registration-of-research-report-pdf"
                            element={
                                <RegistrationOfResearchProjectReport
                                    isPDF
                                    onValidChange={() => {}}
                                    showError={false}
                                    processingActivityStatus={ProcessingActivityStatus.Draft}
                                />
                            }
                        />
                        <Route path="internal-acquisition-of-signature-report-pdf" element={<InternalAcquisitionOfSignatureReport isPDF />} />
                        <Route path="research-ethics-approval-report-pdf" element={<ResearchEthicsApprovalReport isPDF />} />
                    </Route>
                </Route>

                <Route path="processing-activity-statistics">
                    <Route path="" element={<ProcessingActivityStatistics />} />
                    <Route path="pdf" element={<ProcessingActivityStatisticsPdf />} />
                </Route>

                <Route path="risk-assessment">
                    <Route path=":id">
                        <Route path=":riskAssessmentAreaString">
                            <Route path=":riskComplianceAreaString">
                                <Route path=":riskAssessmentVersionId">
                                    <Route path="questions" element={<ProcessingActivityRiskQuestionDialog />} />
                                    <Route path="pdf" element={<ProcessingActivityRiskPDF />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path="risk-assessments">
                    <Route path="template" element={<RiskAssessmentTemplate />} />
                    <Route path=":processingActivityId/:riskAssessmentVersionId/scenarios" element={<ProcessingActivityRiskScenariosDialog />} />
                </Route>

                <Route path="show-upgrade-dialog" element={<DeepLinkUpgradeDialog />} />

                <Route path="incident-log">
                    <Route path="" element={<IncidentLog />} />
                    <Route path=":id/overview" element={<IncidentLogOverview />} />
                </Route>

                <Route path="questionnaires/*" element={<QuestionnairesListOverview />} />
            </Route>

            <Route path="/" element={getPermissionDefaultPage()} />
            <Route path="*" element={<ErrorPage errorMessage={translateString("pageNotFound")} image="NotFound" />} />
        </Routes>
    );
}

export default RouterSwitch;
