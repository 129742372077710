import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalInfoDialog,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalSwitch,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useDataCategoriesHooks } from "./DataCategories.hooks";
import { useDataCategoriesStyles } from "./DataCategories.styles";
import { DataCategoryTableModel, DataCategoryViewModel } from "./DataCategories.types";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { Box, TableCell, TableRow } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteDataCategoryDialog from "../deleteDataCategoryDialog/DeleteDataCategoryDialog";
import { DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import { ChangeDataCategoryStatusDialog } from "../changeDataCatagoryStatusDialog/ChangeDataCategoryStatusDialog";
import AddEditDataCategoryDialog from "../addEditDataCatoryDialog/AddEditDataCategoryDialog";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { getMasterIcon } from "../../MasterData.hooks";
import PurpleLockSvg from "../../../common/svgs/purpleLock.svg?react";

export function DataCategories() {
    const styles = useDataCategoriesStyles();
    const { permissions, customerName } = useUserContext();
    const { translateString } = useTranslation();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        tableModel,
        showAllDataCategories,
        setShowAllDataCategories,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDataCategoryDialog,
        setShowDeleteDataCategoryDialog,
        selectedDataCategory,
        setSelectedDataCategory,
        refetchDataCategories,
        showChangeStatusDialog,
        setShowChangeStausDialog,
        showActivateDataTypesDialog,
        onDataTypesClicked,
        onDataTypesActivated,
        setShowActivateDataTypesDialog,
        dataTypes,
        customerHasDataTypesAlready,
    } = useDataCategoriesHooks();

    function mapToSaveModel(dc: DataCategoryViewModel) {
        return {
            name: dc.name,
            description: dc.description,
            id: dc.id,
            classification: dc.classification,
            active: dc.active,
            isCustom: dc.isCustom,
            dataTypes: dc.dataTypes,
        };
    }

    const getMenuItems = (dc: DataCategoryViewModel) => {
        let menuItems = [];
        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDataCategory(mapToSaveModel(dc));
                    setShowAddEditDialog(true);
                },
            });

            menuItems.push({
                menuItem: dc.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedDataCategory(mapToSaveModel(dc));
                    setShowChangeStausDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && dc.isCustom) {
            menuItems.push({
                menuItem: translateString("removeDataCategory"),
                onClick: () => {
                    setSelectedDataCategory(mapToSaveModel(dc));
                    setShowDeleteDataCategoryDialog(true);
                },
            });
        }
        return menuItems;
    };

    function getHeaders(translateString: any) {
        let headers = Array<ITableHeader<DataCategoryTableModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
        if (dataTypes?.length ?? 0 > 0) {
            headers.push({ property: "dataTypesString", text: translateString("dataTypes"), align: "left", showOnMobile: true });
        }
        headers.push({ property: "classification", text: translateString("classification"), align: "left", showOnMobile: true });
        headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
        headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

        return headers;
    }

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("dataCategories"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllDataCategories}
                        checked={showAllDataCategories}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />

            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createDataCategory")}
                    additionalButtons={[
                        <DotLegalButton
                            key={"manage-data-types"}
                            buttonType={"secondary"}
                            onClick={onDataTypesClicked}
                            endIcon={customerHasDataTypesAlready ? undefined : <PurpleLockSvg />}
                        >
                            {translateString("manageDataTypes")}
                        </DotLegalButton>,
                    ]}
                    onOpenDialog={() => {
                        setShowAddEditDialog(true);
                        setSelectedDataCategory(undefined);
                    }}
                    showDialog={showAddEditDialog || showActivateDataTypesDialog}
                    icon={getMasterIcon()}
                >
                    <>
                        <AddEditDataCategoryDialog
                            dataCategory={selectedDataCategory}
                            isAdd={selectedDataCategory === undefined}
                            open={showAddEditDialog}
                            onDialogClose={() => setShowAddEditDialog(false)}
                            onOkClick={() => setShowAddEditDialog(false)}
                            refetch={refetchDataCategories}
                        />
                        {showActivateDataTypesDialog && (
                            <DotLegalInfoDialog
                                btnText={translateString("activate")}
                                onCloseDialog={() => setShowActivateDataTypesDialog(false)}
                                onBtnClick={onDataTypesActivated}
                                header={translateString("manageDataTypes")}
                                content={<Box sx={{ whiteSpace: "pre-line" }}>{translateString("manageDataTypesActivationDescription")}</Box>}
                            />
                        )}
                    </>
                </DotLegalActionBar>
            </div>
            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders(translateString)}
                    isLoading={isLoading}
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    emptyText={translateString("noData")}
                    data={tableModel}
                    renderRow={(row, i) => (
                        <TableRow
                            key={i}
                            onClick={() => {
                                setSelectedDataCategory(row);
                                setShowAddEditDialog(true);
                            }}
                        >
                            <TableCell sx={styles.bold}>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.dataTypesString}</TableCell>
                            <TableCell>{row.classificationString}</TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDeleteDataCategoryDialog && (
                <DeleteDataCategoryDialog
                    selectedDataCatagory={selectedDataCategory!}
                    onSaveCorrect={refetchDataCategories}
                    onCloseDialog={() => setShowDeleteDataCategoryDialog(false)}
                />
            )}

            {showChangeStatusDialog && (
                <ChangeDataCategoryStatusDialog
                    selectedDataCategory={selectedDataCategory!}
                    refetch={refetchDataCategories}
                    onCloseDialog={() => setShowChangeStausDialog(false)}
                />
            )}
        </Box>
    );
}
