import { DotLegalDialog, DotLegalSelect, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useRiskVersionDialogHooks } from "./RiskAssessmentVersionDialog.hooks";
import { RiskVersionSaveModel, SelectableRiskAssessmentVersionViewModel } from "../riskAssessmentVersions/RiskAssessmentVersions.types";
import { DotLegalSelectOption } from "../../../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { RiskAssessmentArea, RiskComplianceArea } from "../../../riskAssessment/riskAssessments/RiskAssessments.types";

export interface IRiskAssessmentVersionDialog {
    onDialogClose: () => void;
    selectedVersion?: RiskVersionSaveModel;
    onVersionSaved: (versionId: string) => void;
    versionOptions?: Array<SelectableRiskAssessmentVersionViewModel>;
    riskComplianceArea: RiskComplianceArea | undefined;
    riskAssessmentArea: RiskAssessmentArea | undefined;
}

export default function RiskAssessmentVersionDialog(props: IRiskAssessmentVersionDialog) {
    const { translateString } = useTranslation();
    const { inProgress, onSaveClick, version, onDescriptionChange, onNameChange, onVersionChange, validation } = useRiskVersionDialogHooks(props);

    const getVersionOptions = () => {
        let versions: Array<DotLegalSelectOption> = [];

        props.versionOptions?.forEach((x) => {
            versions.push({ id: x.id, name: x.name });
        });

        versions.push({ id: "null", name: translateString("blankVersion") });
        return versions;
    };

    validation.check(version);
    return (
        <DotLegalDialog
            buttonOkText={props.versionOptions ? translateString("create") : translateString("save")}
            header={props.versionOptions ? translateString("createRiskVersion") : translateString("editRiskVersion")}
            size="sm"
            open
            inProgress={inProgress}
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => onSaveClick()}
            disableBackdropOnCloseIfValueChange={version}
        >
            {props.versionOptions && (
                <DotLegalSelect
                    options={getVersionOptions()}
                    selectedItem={version.id ?? "null"}
                    onChange={(versionId) => onVersionChange(versionId ?? "null")}
                    placeholder={""}
                    label={translateString("version")}
                    noOptionsLabel={translateString("noOptions")}
                    disableClearable
                ></DotLegalSelect>
            )}

            <DotLegalTextField
                label={translateString("name")}
                onChange={(name) => onNameChange(name)}
                value={version.name}
                debounce={false}
                errorText={validation.getErrorsForDisplay("name")}
            ></DotLegalTextField>
            <DotLegalTextField
                label={translateString("description")}
                onChange={(description) => onDescriptionChange(description)}
                value={version.description}
                debounce={false}
                multiline
                rows={5}
            ></DotLegalTextField>
        </DotLegalDialog>
    );
}
