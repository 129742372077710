import React, { useState } from "react";
import PrivacyDrawer from "./privacyDrawer/PrivacyDrawer";
import Content from "./content/Content";
import { useIsOnSmallScreen, useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import { useTrialInformationHook } from "../trial/TrialInformation.hooks";

export interface IMainProps {
    children: JSX.Element;
}

function Main(props: IMainProps) {
    const [isExpandedBigScreen, setIsExpandedBigScreen] = useStateLocalStorage("menu-expanded", true);
    const [isExpandedSmallScreen, setIsExpandedSmallScreen] = useState(false);
    const isOnSmallScreen = useIsOnSmallScreen();
    const { data } = useTrialInformationHook();

    return (
        <>
            <Box
                sx={(theme) => ({
                    backgroundColor: "white",
                    display: "flex",
                    [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                    },
                    [theme.breakpoints.up("md")]: {
                        flexDirection: "row",
                    },
                    minHeight: "100vh",
                })}
            >
                <PrivacyDrawer
                    ribbonShown={data?.isTrial ?? false}
                    isExpandedBigScreen={isExpandedBigScreen}
                    isExpandedSmallScreen={isExpandedSmallScreen}
                    setIsExpandedBigScreen={setIsExpandedBigScreen}
                    setIsExpandedSmallScreen={setIsExpandedSmallScreen}
                />

                <Content isOnSmallScreen={isOnSmallScreen} isExpandedBigScreen={isExpandedBigScreen} isExpandedSmallScreen={isExpandedSmallScreen}>
                    {props.children}
                </Content>
            </Box>
        </>
    );
}

export default Main;
