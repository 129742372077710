import { Box, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DotLegalButton, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { TrackingEvent, useTrackAIEvent } from "../processingActivity/hooks/useTracking";
import { useUrlProvider } from "../useUrlProvider";
import CreateCustomListDialog from "./createCustomListDialog/CreateCustomListDialog";
import { useCustomLists } from "./CustomLists.hooks";
import { useCustomListsStyles } from "./CustomLists.styles";
import { CallPage, CustomListsViewModel as CustomListViewModel, CustomListType } from "./CustomLists.types";
import DeleteCustomListDialog from "./deleteCustomListDialog/DeleteCustomListDialog";
import EmptyStateIcon from "./noCustomLists.svg?react";
import { useDotLegalCreatePaper } from "../common/components/dotLegalCreatePaper/DotLegalCreatePaper.styles";
import CheckIcon from "@mui/icons-material/Check";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { WhiteLockIcon } from "../common/icons";
import PlanDialogButtonWrapper from "../plan/planDialogButtonWrapper/planDialogButtonWrapper";

export interface ICustomListsProps {
    listType: CustomListType;
    callPage?: CallPage;
}

function CustomLists(props: ICustomListsProps) {
    const { getCustomListViewUrl } = useUrlProvider();
    const styles = useCustomListsStyles();
    const createPaperStyles = useDotLegalCreatePaper();
    const navigate = useNavigate();
    const trackEvent = useTrackAIEvent();
    const { translateString } = useTranslation();
    const { hasLists, data, isLoading, selectedCustomList, setSelectedCustomList, refetchLists, showDeleteDialog, setShowDeleteDialog, getBreadCrumbs } =
        useCustomLists(props);

    const { permissions, customerName } = useUserContext();
    const { addonsFeatures } = usePlanContext();
    const customListPermissions = permissions.customListPermissions;

    const getMenuItems = (viewModel: CustomListViewModel) => {
        const menuItems = [];

        const editObject = {
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedCustomList({ id: viewModel.id, name: viewModel.name, description: viewModel.description, show: true, copy: false });
            },
        };
        const deleteObject = {
            menuItem: translateString("delete"),
            onClick: () => {
                setShowDeleteDialog({ show: true, name: viewModel.name, id: viewModel.id });
            },
        };

        if (!viewModel.isStandardList) {
            if (customListPermissions.edit) {
                menuItems.push(editObject);
            }

            if (customListPermissions.delete) {
                menuItems.push(deleteObject);
            }
        }

        if (customListPermissions.create) {
            const copiObject = {
                menuItem: translateString("copy"),
                onClick: () => {
                    setSelectedCustomList({
                        show: true,
                        description: viewModel.description,
                        name: `(${translateString("copySingular")}) ${viewModel.name}`,
                        id: viewModel.id,
                        copy: true,
                    });
                },
            };

            menuItems.push(copiObject);
        }

        return menuItems;
    };

    function onDeleteDialogClose() {
        setShowDeleteDialog({ show: false, id: "", name: "" });
    }

    function getHeaders(translateString: any) {
        const headers = new Array<ITableHeader<CustomListViewModel>>();
        headers.push({ property: "name", text: translateString("savedLists"), align: "left", showOnMobile: true });
        headers.push(
            { property: "description", text: translateString("description"), align: "left", showOnMobile: true },
            { property: "isStandardList", text: translateString("standardList"), align: "left", showOnMobile: true },
            { property: null, text: "", align: "left", showOnMobile: true }
        );
        return headers;
    }

    const gotoListView = (id: string) => {
        trackEvent(TrackingEvent.CustomListOpened, { customList: id });
        if (props.callPage === CallPage.GroupCompanies) {
            navigate(getCustomListViewUrl(id) + "?groupcompanies=true");
        } else {
            navigate(getCustomListViewUrl(id));
        }
    };

    function resetSelectedCustomList() {
        setSelectedCustomList({ show: false, description: "", name: "", id: "", copy: false });
    }

    function onOpenDialog() {
        setSelectedCustomList({ show: true, description: "", name: "", id: "", copy: false });
    }

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />
            <DotLegalPaper background={"blueWave"} sx={createPaperStyles.paperHeader} backgroundCover>
                {customListPermissions.create && (
                    <PlanDialogButtonWrapper enabled={!addonsFeatures.customLists}>
                        <DotLegalButton
                            onClick={onOpenDialog}
                            buttonType={"primary"}
                            disabled={!addonsFeatures.customLists}
                            toolTip={!addonsFeatures.customLists ? translateString("upgradeToActivateAddOn") : undefined}
                            endIcon={!addonsFeatures.customLists ? WhiteLockIcon : undefined}
                        >
                            {translateString("createNewCustomList")}
                        </DotLegalButton>
                    </PlanDialogButtonWrapper>
                )}
            </DotLegalPaper>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    {hasLists ? (
                        <DotLegalTableWithControls
                            headers={getHeaders(translateString)}
                            getUserSpecificPageLength={() => 30}
                            hideRowsPerPage
                            defaultOrderBy={"name"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            emptyText={translateString("noCompaniesMatchesQuery")}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            data={data ?? []}
                            renderRow={(row, i) => (
                                <TableRow key={i} onClick={() => gotoListView(row.id)}>
                                    <TableCell sx={styles.bold}>{row.name}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.isStandardList ? <CheckIcon sx={{ height: "20px" }} /> : ""}</TableCell>
                                    <TableCell align={"right"}>
                                        {addonsFeatures.customLists &&
                                            ((row.isStandardList && customListPermissions.create) || (!row.isStandardList && customListPermissions.edit)) && (
                                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                                            )}
                                    </TableCell>
                                </TableRow>
                            )}
                        />
                    ) : (
                        <DotLegalEmptyState icon={<EmptyStateIcon />} text={translateString("noCustomLists")} />
                    )}
                </DotLegalPaper>
            </Box>

            {selectedCustomList.show && (
                <CreateCustomListDialog
                    open={selectedCustomList.show}
                    onHideDialog={resetSelectedCustomList}
                    customListType={props.listType}
                    onSave={() => {
                        refetchLists();
                        resetSelectedCustomList();
                    }}
                    customList={selectedCustomList}
                    copy={selectedCustomList.copy}
                    callPage={props.callPage}
                />
            )}

            {showDeleteDialog.show && (
                <DeleteCustomListDialog
                    id={showDeleteDialog.id}
                    name={showDeleteDialog.name}
                    onCloseDialog={onDeleteDialogClose}
                    onDeleteClick={() => {
                        onDeleteDialogClose();
                        refetchLists();
                    }}
                />
            )}
        </Box>
    );
}

export default CustomLists;
