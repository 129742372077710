import { createElement, useState } from "react";
import { Trans } from "react-i18next";
import { useMutation } from "react-query";
import { post, put } from "../../common/api/apiShared";
import { Result } from "../../common/api/result";
import { getEnumValues } from "../../common/enumOperations";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { useValidator } from "../../common/hooks/validation";
import { useTranslation } from "../../localization/useTranslation";
import { PolicySaveModel, PolicyType } from "../policies/Policies.types";
import { IPolicyDialog } from "./PolicyDialog";

export function usePolicyDialogDataMapping(props: IPolicyDialog) {
    const snackbar = useDotLegalSnackbar();
    const { translatePolicyTypes, translateString } = useTranslation();
    const [saveModel, setSaveModel] = useState<PolicySaveModel>(props.selectedPolicy);

    const isEdit = props.selectedPolicy.id !== "";

    const validation = useValidator<PolicySaveModel>(
        (validators) => ({
            name: validators.validateNotEmpty((item) => item.name, translateString("name")),
            type: validators.validateNotEmpty((item) => item.type, translateString("type")),
        }),

        []
    );

    function save() {
        return post<string>("/policies/", saveModel);
    }

    const saveMutation = useMutation(save, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                props.onDialogClose();
                props.onSave();

                snackbar.show(createElement(Trans, { i18nKey: "policyCreated", values: { name: saveModel?.name } }));
            } else {
                snackbar.show(translateString("policyExists"), "warning");
            }
        },
    });

    function update() {
        return put<string>("/policies/" + saveModel.id, saveModel);
    }

    const editMutation = useMutation(update, {
        onSuccess: (response: Result<string>) => {
            if (response.success()) {
                snackbar.show(translateString("policyUpdated"));
                props.onDialogClose();
                props.onSave();
            } else {
                snackbar.show(translateString("policyExists"), "warning");
            }
        },
    });

    const savePolicy = async () => {
        if (validation.anyHasErrors) {
            validation.setShowErrors(true);
            return;
        }

        if (isEdit) {
            await editMutation.mutateAsync();
        } else {
            await saveMutation.mutateAsync();
        }
    };

    const typeOptions = getEnumValues(PolicyType)
        .map((x) => {
            return { id: x.toString(), name: translatePolicyTypes(x.toString()) };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

    return { typeOptions, saveModel, setSaveModel, savePolicy, validation, isSaving: saveMutation.isLoading || editMutation.isLoading, isEdit };
}
