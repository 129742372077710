import React from "react";
import { DotLegalButton, DotLegalHeader, DotLegalTextField, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import DotLegalTextFieldWithSnippets from "../../../common/components/dotLegalTextFieldWithSnippets/DotLegalTextFieldWithSnippets";
import { useTranslation } from "../../../localization/useTranslation";
import RiskRadioGroup from "../../riskRadioGroup/RiskRadioGroup";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";
import { Box } from "@mui/system";
import { WhiteLockIcon } from "../../../common/icons";
import PlanDialogButtonWrapper from "../../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { useTheme } from "@mui/material";

export interface IRiskAnswerGroup {
    headerText: string;
    notePlaceholder?: string;
    showHeader: boolean;
    showResetButton: boolean;
    disableResetButton: boolean;
    score: number | null;
    note: string | null;
    isConsequence: boolean;
    isPdf?: boolean;
    answerTooltip?: string;
    noteMandatory?: boolean;
    onScoreChange: (newScore: number) => void;
    onNoteChange: (text: string) => void;
    onResetOverrideClick: () => void;
    snippets?: Array<string>;
    noMargin?: boolean;
    isDisabled: boolean;
}

function RiskAnswerGroup(props: IRiskAnswerGroup) {
    const theme = useTheme();
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { dataProtectionFeatures } = usePlanContext();

    function getUnlockButton() {
        if (props.snippets && props.snippets.length >= 3 && !dataProtectionFeatures.gdprRiskAssessmentConsequenceCatalogue) {
            return (
                <PlanDialogButtonWrapper enabled>
                    <DotLegalTooltip text={translateString("upgradeDataProtectionPlan")}>
                        <Box sx={{ display: "inline-block", marginTop: 1 }}>
                            <DotLegalButton endIcon={WhiteLockIcon} size="small" buttonType={"primary"} disabled={props.isDisabled}>
                                {translateString("unlockForMoreSuggestions")}
                            </DotLegalButton>
                        </Box>
                    </DotLegalTooltip>
                </PlanDialogButtonWrapper>
            );
        }

        return undefined;
    }

    return (
        <React.Fragment>
            {props.showHeader && (
                <DotLegalHeader marginBottom={-1} headerStyle={"small"} toolTip={props.answerTooltip}>
                    {props.headerText}
                </DotLegalHeader>
            )}

            <RiskRadioGroup
                showResetButton={props.showResetButton}
                disableResetButton={props.disableResetButton}
                isConsequenceQuestion={props.isConsequence ?? false}
                score={props.score}
                onResetClick={() => props.onResetOverrideClick()}
                onScoreChange={(score) => props.onScoreChange(score)}
                disabled={!permissions.riskPermissions.edit || props.isDisabled}
            />

            {props.snippets && !props.isPdf ? (
                <React.Fragment>
                    <DotLegalTextFieldWithSnippets
                        pdfClassName="pdfNoteField"
                        placeholder={props.notePlaceholder ?? translateString("notelabel")}
                        value={props.note}
                        debounce
                        label={""}
                        snippets={dataProtectionFeatures.gdprRiskAssessmentConsequenceCatalogue ? props.snippets : props.snippets.slice(0, 3)}
                        onChange={(text) => props.onNoteChange(text)}
                        rows={props.isPdf ? undefined : 5}
                        error={props.isPdf || props.isDisabled ? "" : props.noteMandatory && !props.note ? translateString("fieldMandatory") : ""}
                        select={props.isConsequence ? translateString("selectConsequences") : translateString("selectSecurityMeasures")}
                        disabled={!permissions.riskPermissions.edit || props.isDisabled}
                        noMargin={props.noMargin}
                    >
                        {getUnlockButton()}
                    </DotLegalTextFieldWithSnippets>
                </React.Fragment>
            ) : (
                <Box
                    sx={{
                        "& .MuiInputBase-root": {
                            padding: theme.spacing(1),
                            backgroundColor: theme.customPalette.white,
                            borderRadius: `${theme.shape.borderRadius}px`,
                        },
                    }}
                >
                    <DotLegalTextField
                        pdfClassName="pdfNoteField"
                        debounce
                        label={""}
                        placeholder={props.notePlaceholder ?? translateString("notelabel")}
                        multiline
                        rows={props.isPdf ? undefined : 5}
                        value={props.note}
                        onChange={(text) => props.onNoteChange(text)}
                        errorText={props.isPdf ? "" : props.noteMandatory && !props.note ? translateString("fieldMandatory") : ""}
                        disabled={!permissions.riskPermissions.edit || props.isDisabled}
                    />
                </Box>
            )}
        </React.Fragment>
    );
}

export default RiskAnswerGroup;
