import React from "react";
import { Trans } from "react-i18next";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useTrialRibbonStyles } from "./TrialRibbon.styles";
import { CustomerPlanViewModel } from "../Trial.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { Box } from "@mui/material";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface ITrialRibbonProps {
    data: CustomerPlanViewModel | undefined;
}

function TrialRibbon(props: ITrialRibbonProps) {
    const styles = useTrialRibbonStyles();
    const { translateString } = useTranslation();
    const { planType } = usePlanContext();

    return props.data?.isTrial ? (
        //pdfRibbon skal være en del af className, da den bruges til export.
        <Box sx={styles.ribbon} className={`pdfRibbon`}>
            <DotLegalHeader fontWeight={300} color="white" marginBottom={0} headerStyle={"extraSmall"}>
                <Trans
                    i18nKey={"trialRibbon"}
                    values={{
                        days: props.data.trialDaysRemaining,
                        plan: translateString(setFirstLetterToLowerCase(PlanType[planType])),
                        daysText: props.data.trialDaysRemaining === 1 ? translateString("day").toLowerCase() : translateString("days").toLowerCase(),
                    }}
                />
                &nbsp;
            </DotLegalHeader>
            <PlanDialogButtonWrapper enabled>
                <Box
                    component={"p"}
                    sx={(theme) => ({
                        color: theme.customPalette.white,
                        fontSize: "0.75rem",
                        textDecoration: "underline",
                        margin: "unset",
                        "&:hover": {
                            cursor: "pointer",
                        },
                    })}
                >
                    {translateString("clickToUpgrade")}
                </Box>
            </PlanDialogButtonWrapper>
        </Box>
    ) : null;
}

export default TrialRibbon;
