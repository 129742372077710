import { useTheme } from "@mui/material";
import { createSxStyles } from "../common/createSxStyles";

export const useDataProcessorRecordStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        table: {
            marginTop: theme.spacing(3),
        },
        tableHeader: {
            color: theme.palette.primary.dark,
            fontWeight: theme.typography.fontWeightBold,
        },
        searchField: {
            display: "flex",
            justifyContent: "flex-end",
        },
        emptyState: {
            marginTop: theme.spacing(1),
            color: theme.palette.grey["500"],
        },
        paper: {
            marginTop: theme.spacing(2),
        },
        skeleton: {
            marginTop: theme.spacing(1),
        },
    });
};
