import { useStepSegmentStyles } from "./StepSegment.styles";
import { Box } from "@mui/material";

export interface ISegmentStepProps {
    size: "small" | "medium" | "large";
    children: any;
}

function SegmentStep(props: ISegmentStepProps) {
    const styles = useStepSegmentStyles(props);

    return <Box sx={styles.container}>{props.children}</Box>;
}

export default SegmentStep;
