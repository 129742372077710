import { DotLegalTextField, DotLegalDialog } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useCopyProcessingActivityDataMapping } from "./CopyProcessingActivityDialog.hooks";
import { CloneProcessingActivityModel } from "./CopyProcessingActivityDialog.types";

export interface ICloneProcessingActivityDialog {
    onHideDialog: () => void;
    copyModel: CloneProcessingActivityModel;
    onSave: () => void;
}

function CopyProcessingActivityDialog(props: ICloneProcessingActivityDialog) {
    const { copyProcessingActivity, inProgress, isNameValid, copyModel, setCopyModel } = useCopyProcessingActivityDataMapping(props);
    const { translateString } = useTranslation();

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={translateString("copy")}
            okButtonDisabled={!isNameValid.current}
            header={translateString("copyProcessingActivity")}
            onOkClick={async () => {
                copyProcessingActivity();
            }}
            onDialogClose={props.onHideDialog}
            open
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("processingActivity")}
                value={copyModel.name}
                onChange={(x) => {
                    let tempModel = { ...copyModel };
                    tempModel.name = x;
                    setCopyModel(tempModel);
                }}
                errorText={isNameValid.current ? "" : translateString("nameRequired")}
            />
        </DotLegalDialog>
    );
}

export default CopyProcessingActivityDialog;
