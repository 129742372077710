import { createContext, useContext } from "react";
import { PlanProviderDataMapping } from "./PlanProvider.hooks";
import {
    ApplicationFeatures,
    PlanType,
    ProcessingActivityTemplates,
    StandardGDPRActivities,
    StandardNIS2Activities,
    SystemTemplates,
} from "./PlanProvider.types";
import { DotLegalFullScreenSpinner } from "@dotlegal/dotlegal-ui-components";
import React from "react";

export interface IPlanProvider {
    children: JSX.Element;
}

function PlanProvider(props: IPlanProvider) {
    const { data, isLoading } = PlanProviderDataMapping();

    return (
        <PlanContextContext.Provider value={data}>
            {isLoading ? <DotLegalFullScreenSpinner /> : <React.Fragment>{props.children}</React.Fragment>}
        </PlanContextContext.Provider>
    );
}

export function usePlanContext(): ApplicationFeatures {
    return useContext(PlanContextContext)!;
}

export const PlanContextContext = createContext<ApplicationFeatures | undefined>({
    platformFeatures: {
        maxNumberOfGroupCompanies: undefined,
        dashboard: false,
        maxNumberOfPolicies: undefined,
        commentModule: false,
        addTasksToOutlook: false,
        annualWheelOverview: false,
        annualWheelReport: false,
        annualWheelCopyActivity: false,
        taskHistory: false,
        assignTask: false,
        signOff: false,
        annualWheelComplianceArea: false,
        riskVersions: false,
        tags: false,
        relatedActivitiesAndTasks: false,
        auditLog: false,
    },
    dataProtectionFeatures: {
        hasDataProtection: false,
        maxNumberOfProcessingActivities: undefined,
        processingActivityTemplates: ProcessingActivityTemplates.MostPopular,
        processingActivityValidationFlow: false,
        dataProcessingAgreements: false,
        processingActivitiesDocuments: false,
        standardGDPRActivities: StandardGDPRActivities.NoAccess,
        maxNumberOfProcessingActivityRiskAssessments: undefined,
        maxNumberOfGDPRRiskScenarios: undefined,
        gdprRiskAssessmentConsequenceCatalogue: false,
        gdprRiskReports: false,
        maxNumberOfIncidentLogEntries: undefined,
        processingActivityStatus: false,
        registrationOfDataController: false,
        sharedResponsibility: false,
        linkPoliciesAndProcessingActivities: false,
        annualWheelProcessingActivitiesValidation: false,
        recordDataProcessor: false,
        recordsExport: false,
        maxNumberOfContactsOnUnit: undefined,
        standardListExport: false,
    },
    informationSecurityFeatures: {
        maxNumberOfSystems: undefined,
        systemTemplates: SystemTemplates.All,
        systemDocuments: false,
        niS2ActivitiesCatalogue: StandardNIS2Activities.NoAccess,
        isoActivitiesCatalogue: false,
        maxNumberOfAssetRiskAssessments: undefined,
        maxNumberOfNIS2RiskScenarios: undefined,
        nis2RiskAssessmentConsequenceCatalogue: false,
        niS2RiskReports: false,
        systemRiskClassification: false,
        standardListExport: false,
        systemStatus: false,
        storageType: false,
        entityType: false,
        dataType: false,
        operationCriticalSystem: false,
        certifications: false,
        entityTypeFilter: false,
        associatedAssets: false,
        internalControlProcedures: false,
        personalData: false,
        automaticRegistrationOfDataProcessorTransfers: false,
        ai: false,
    },
    vendorManagementFeatures: {
        hasVendorManagement: false,
        maxNumberOfVendorRiskClassificationsPerYear: undefined,
        vendorScreeningAndOnboarding: false,
        auditGroups: false,
        vendorDocuments: false,
        maxNumberOfContactsOnUnit: undefined,
        standardListsExport: false,
        certifications: false,
        processingActivityAssociateInternalAssessments: false,
        systemAssociateInternalAssessments: false,
        publicAssessments: false,
        systemClassifications: false,
    },
    addonsFeatures: {
        sso: false,
        customLists: false,
        organizationalManagement: false,
        research: false,
        declarations: false,
        userManagementPremium: false,
    },
    hasPremium: false,
    hasVendorManagementAccess: false,
    hasTaskManagementAccess: false,
    planType: PlanType.GdprStarter,
    oldIsmsPlanType: PlanType.IsmsStarter,
});

export default PlanProvider;
