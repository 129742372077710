import { DotLegalHeader, DotLegalPaper, DotLegalPdfBackPage, DotLegalPdfFrontPage, DotLegalSwitch } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { Box, Grid } from "@mui/material";
import ProcessingActivityCountWiget from "../processingActivityCountWidget/ProcessingActivityCountWiget";
import SystemsCountWidget from "../systemsCountWidget/SystemsCountWidget";
import VendorsCountWidget from "../vendorsCountWidget/VendorsCountWidget";
import TasksCountWidget from "../tasksCountWidget/TasksCountWidget";
import IncidentLogWidget from "../incidentLogWidget/IncidentLogWidget";
import { useSetPdfPageSize } from "../../common/pdfPageSizeHelper";
import TasksWidgetReport from "./tasksWidgetReport/TasksWidgetReport";
import RiskWidgetReport from "./riskWidgetReport/RiskWidgetReport";
import LatestAuditsSubmittedWidgetReport from "./latestAuditsSubmittedWidgetReport/LatestAuditsSubmittedWidgetReport";
import TasksOverviewWidgetReport from "./tasksOverviewWidgetReport/TasksOverviewWidgetReport";
import ComplianceReportFrontPage from "./complianceReportFrontPage/ComplianceReportFrontPage";
import useComplianceReportHooks from "./ComplianceReport.hooks";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";

function ComplianceReport() {
    const { translateString } = useTranslation();
    const { data } = useComplianceReportHooks();
    const { oldIsmsPlanType } = usePlanContext();
    useSetPdfPageSize("landscape");

    return (
        <>
            {data && (
                <Box>
                    <ComplianceReportFrontPage />
                    <Box mt={2} ml={2} mr={2}>
                        <DotLegalHeader headerStyle={"xxxl"}>
                            <Box sx={{ textTransform: "capitalize" }} component={"span"}>
                                {translateString("complianceReport")}
                            </Box>
                        </DotLegalHeader>
                        {data.note && (
                            <Box sx={{ whiteSpace: "pre-wrap" }}>
                                <DotLegalHeader fontWeight={400} fontStyle="italic" headerStyle={"large"}>
                                    {translateString("note")}: {data.note}
                                </DotLegalHeader>
                            </Box>
                        )}

                        <DotLegalSwitch checked={data.onlyUserSpecific} leftLabel={translateString("all")} rightLabel={translateString("my")} />

                        <Box
                            sx={{
                                paddingBottom: 2,
                                paddingTop: 2,
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 2,
                                backgroundColor: "#EEEFFA",
                                borderRadius: "20px",
                                mb: 2,
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ProcessingActivityCountWiget
                                        isPdf
                                        onlyUserSpecific={data.onlyUserSpecific}
                                        selectedGroupCompany={data.selectedGroupEntity}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SystemsCountWidget isPdf onlyUserSpecific={data.onlyUserSpecific} />
                                </Grid>
                                <Grid item xs={6}>
                                    <VendorsCountWidget isPdf onlyUserSpecific={data.onlyUserSpecific} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TasksCountWidget isPdf onlyUserSpecific={data.onlyUserSpecific} selectedGroupCompany={data.selectedGroupEntity} />
                                </Grid>
                            </Grid>
                        </Box>

                        <div className="page-break-before">
                            <Box mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TasksWidgetReport
                                            onlyUserSpecific={data.onlyUserSpecific}
                                            selectedGroupCompany={data.selectedGroupEntity}
                                            showCurrentMonth={false}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TasksWidgetReport
                                            onlyUserSpecific={data.onlyUserSpecific}
                                            selectedGroupCompany={data.selectedGroupEntity}
                                            showCurrentMonth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div className="page-break-before">
                            <Box mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RiskWidgetReport
                                            onlyUserSpecific={data.onlyUserSpecific}
                                            selectedGroupCompany={data.selectedGroupEntity}
                                            isSystemChosen={false}
                                        />
                                    </Grid>
                                    {oldIsmsPlanType && (
                                        <Grid item xs={6}>
                                            <RiskWidgetReport
                                                onlyUserSpecific={data.onlyUserSpecific}
                                                selectedGroupCompany={data.selectedGroupEntity}
                                                isSystemChosen
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </div>
                        <div className="page-break-before">
                            <Box mt={2}>
                                <Grid container columnSpacing={2} rowSpacing={1}>
                                    <Grid item xs={6}>
                                        <LatestAuditsSubmittedWidgetReport
                                            onlyUserSpecific={data.onlyUserSpecific}
                                            selectedGroupCompany={data.selectedGroupEntity}
                                            isAudits
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LatestAuditsSubmittedWidgetReport
                                            onlyUserSpecific={data.onlyUserSpecific}
                                            selectedGroupCompany={undefined}
                                            isAudits={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: 390 }}>
                                        <TasksOverviewWidgetReport
                                            onlyUserSpecific={data.onlyUserSpecific}
                                            selectedGroupCompany={data.selectedGroupEntity}
                                            year={2024}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div className="page-break-before">
                            <Box mt={2}>
                                <Grid container>
                                    <Grid item xs={6} height={350}>
                                        <IncidentLogWidget isPdf />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Box>
                    <div className="page-break-before">
                        <DotLegalPdfBackPage />
                    </div>
                </Box>
            )}
        </>
    );
}

export default ComplianceReport;
