import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../../common/api/apiShared";
import { useCertificationsQuery, useSelectableDeletionPeriodsQuery } from "../../../common/hooks/useSelectableItemQueries";
import { SelectableSecurityMeasure } from "../../../processingActivity/ProcessingActivity.types";
import { ISystemInformationStep } from "./SystemInformationStep";
import { usePlanContext } from "../../../auth/planProvider/PlanProvider";

export function useSystemInfomationStepHooks(props: ISystemInformationStep) {
    const { informationSecurityFeatures } = usePlanContext();
    const { id } = useParams<{ id: string }>();

    const [showAddCertificationDialog, setShowAddCertificationDialog] = useState(false);
    const [typedCertification, setTypedCertification] = useState("");

    const [showAddNewSecurityMeasureDialog, setShowAddNewSecurityMeasureDialog] = useState(false);
    const [typedSecurityMeasure, setTypedSecurityMeasure] = useState("");

    const [showAddNewDeletionPeriod, setShowAddNewDeletionPeriod] = useState(false);
    const [typedDeletionPeriod, setTypedDeletionPeriod] = useState("");

    let securityMeasuresQuery = useQuery("systemSecurityMeasures" + id, () => get<Array<SelectableSecurityMeasure>>(`/securitymeasures/${id}/system`));
    let deletionPeriodsQuery = useSelectableDeletionPeriodsQuery();
    let certificationsQuery = useCertificationsQuery(informationSecurityFeatures.certifications);

    let securityMeasureLoading = securityMeasuresQuery.isLoading;
    let securityMeasureData = securityMeasuresQuery.data;
    let deletionPeriodsData = deletionPeriodsQuery.data;
    let deletionPeriodsLoading = deletionPeriodsQuery.isLoading;
    let certificationData = certificationsQuery.data;
    let certificationLoading = certificationsQuery.isLoading;

    const onSaveSecurityMeasure = (id: any, name: string) => {
        var temp = [...props.securityMeasures!];
        temp.push({ securityMeasureId: id.id, isMet: true });
        props.onSecurityMeasureChange(temp);

        var tempData = [...securityMeasuresQuery.data!];
        tempData.push({ id: id.id, isGlobal: false, name: name, description: "" });
        securityMeasuresQuery.data = tempData;

        securityMeasuresQuery.refetch();
        setTypedSecurityMeasure("");
    };

    const onSaveDeletionPeriod = (reponse: any, name: string) => {
        var temp = [...deletionPeriodsData!];
        temp.push({ id: reponse.id, name: name });
        props.onDeletionPeriodChange(reponse.id);
        deletionPeriodsData = temp;

        deletionPeriodsQuery.refetch();
    };

    const onSaveCertification = (response: any) => {
        var temp = [...props.certifications!];
        temp.push(response.id);
        props.onCertificationChange(temp);

        certificationsQuery.refetch();
        setShowAddCertificationDialog(false);
    };

    return {
        securityMeasureLoading,
        securityMeasureData,
        deletionPeriodsLoading,
        deletionPeriodsData,
        showAddNewSecurityMeasureDialog,
        setShowAddNewSecurityMeasureDialog,
        typedSecurityMeasure,
        setTypedSecurityMeasure,
        onSaveSecurityMeasure,
        onSaveDeletionPeriod,
        showAddNewDeletionPeriod,
        setShowAddNewDeletionPeriod,
        typedDeletionPeriod,
        setTypedDeletionPeriod,
        certificationData,
        certificationLoading,
        setShowAddCertificationDialog,
        showAddCertificationDialog,
        typedCertification,
        setTypedCertification,
        onSaveCertification,
    };
}
