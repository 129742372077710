import { Box, GridSize, Skeleton, TableCell } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React from "react";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalMultiSelect,
    DotLegalPageHeader,
    DotLegalChip,
    ITableHeader,
    DotLegalTooltip,
    DotLegalTable,
    DotLegalFilterControls,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useLegalEntitiesHook } from "./LegalEntities.hooks";
import { useLegalEntitiesStyles } from "./LegalEntities.styles";
import { LegalEntityPageEnum, LegalEntityRowModel } from "./LegalEntities.types";
import { greenArrowRight, WhiteLockIcon } from "../../common/icons";
import VendorsIcon from "../vendors.svg?react";
import NoData from "../legalEntityTable/noData.svg?react";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import CustomListSelector from "../../customLists/customListSelector/CustomListSelector";
import { CustomListSelectorType } from "../../customLists/customListSelector/CustomListSelector.types";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { CallPage } from "../../customLists/CustomLists.types";
import AddLegalEntityDialog from "../legalEntityManagement/addLegalEntityDialog/AddLegalEntityDialog";
import ComplianceAreaWrapper from "../../common/components/complianceAreaWrapper/ComplianceAreaWrapper";
import QuestionnaireStatus from "../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import ClassificationRisk from "../../common/components/questionnaireComponents/classiificationRisk/ClassificationRisk";
import SystemLinks from "../../system/SystemLinks.tsx";

export interface ILegalEntitiesProps {
    legalEntityPage: LegalEntityPageEnum;
}

function LegalEntities(props: ILegalEntitiesProps) {
    const isGroupEntities = props.legalEntityPage === LegalEntityPageEnum.GroupEntity;
    const styles = useLegalEntitiesStyles();
    const pageLength = 30;

    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { hasVendorManagementAccess, addonsFeatures, vendorManagementFeatures, informationSecurityFeatures, oldIsmsPlanType } = usePlanContext();

    const {
        isLoading,
        auditIsLoading,
        searchedCountry,
        setSearchedCountry,
        countrySelectOptions,
        setShowAddLegalEntityDialog,
        showAddLegalEntityDialog,
        onGoToLegalEntityClick,
        getBreadCrumbs,
        hasCompanies,
        searchedRiskLevel,
        setSearchedRisklevel,
        riskLevelSelectOptions,
        searchedClassifications,
        setSearchedClassifications,
        searchedComplianceAreas,
        setSearchedComplianceAreas,
        searchedResponsibles,
        setSearchedResponsibles,
        searchedLatestAudits,
        setSearchedLatestAudits,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedLatestAuditStatus,
        setSearchedLatestAuditStatus,
        businessAreaSelectOptions,
        responsibleSelectOptions,
        complianceAreaSelectOptions,
        latestAuditFilterOptions,
        classificationFilterOptions,
        latestAuditStatusFilterOptions,
        rows,
        searchedLegalEntityStatus,
        setSearchedLegalEntityStatus,
        searchString,
        setSearchString,
    } = useLegalEntitiesHook(props);

    const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: GridSize | boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: hasVendorManagementAccess ? true : 2,
    };

    function getHeaders(isExternalEntities: boolean) {
        let headers = Array<ITableHeader<LegalEntityRowModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "countryName", text: translateString("country"), align: "left", showOnMobile: true });
        headers.push({ property: "businessAreaNames", text: translateString("businessAreas"), align: "left", showOnMobile: true });

        if (hasVendorManagementAccess || oldIsmsPlanType) {
            headers.push({ property: "complianceAreas", text: translateString("complianceAreas"), align: "left", showOnMobile: true });

            if (vendorManagementFeatures.certifications || informationSecurityFeatures.certifications) {
                headers.push({ property: "certifications", text: translateString("certifications"), align: "left", showOnMobile: true });
            }
        }

        headers.push({ property: "responsibleName", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "isApproved", text: translateString("approved"), align: "left", showOnMobile: true });

        if (hasVendorManagementAccess) {
            headers.push({ property: "classifications", text: translateString("classifications"), align: "left", showOnMobile: true });

            if (isExternalEntities) {
                headers.push({ property: "latestAudit", text: translateString("legalEntitiesLatestAuditHeader"), align: "left", showOnMobile: true });
                headers.push({ property: "latestAudit", text: translateString("latestAuditStatus"), align: "left", showOnMobile: true });
            }
        }

        return headers;
    }

    const getSearchFields = () => {
        let filterFields = [];

        filterFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedLegalEntityStatus}
                chosenOptions={searchedLegalEntityStatus}
                label={translateString("legalEntityStatus")}
                placeholder={translateString("legalEntityStatus")}
                isLoading={isLoading}
                options={[
                    { id: "active", name: translateString("active") },
                    { id: "deactivated", name: translateString("deactivated") },
                ]}
                noMargin
                limit={1}
                noOptionsLabel={translateString("noOptions")}
            />
        );
        filterFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedCountry}
                chosenOptions={searchedCountry}
                label={translateString("countriesPlural")}
                placeholder={translateString("countriesPlural")}
                isLoading={isLoading}
                options={countrySelectOptions}
                noMargin
                limit={1}
                noOptionsLabel={translateString("noOptions")}
            />
        );
        filterFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedBusinessAreas}
                chosenOptions={searchedBusinessAreas}
                label={translateString("businessAreas")}
                placeholder={translateString("businessAreas")}
                isLoading={isLoading}
                options={businessAreaSelectOptions}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />
        );

        if (hasVendorManagementAccess) {
            filterFields.push(
                <DotLegalMultiSelect
                    onChange={setSearchedClassifications}
                    chosenOptions={searchedClassifications}
                    label={translateString("classifications")}
                    placeholder={translateString("classifications")}
                    isLoading={isLoading}
                    options={classificationFilterOptions}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                />
            );
            filterFields.push(
                <DotLegalMultiSelect
                    onChange={setSearchedRisklevel}
                    chosenOptions={searchedRiskLevel}
                    label={translateString("classificationRiskLevel")}
                    placeholder={translateString("classificationRiskLevel")}
                    isLoading={isLoading}
                    options={riskLevelSelectOptions}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                />
            );
        }

        if (hasVendorManagementAccess || oldIsmsPlanType) {
            filterFields.push(
                <DotLegalMultiSelect
                    onChange={setSearchedComplianceAreas}
                    chosenOptions={searchedComplianceAreas}
                    label={translateString("complianceAreas")}
                    placeholder={translateString("complianceAreas")}
                    isLoading={isLoading}
                    options={complianceAreaSelectOptions}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                />
            );
        }

        filterFields.push(
            <DotLegalMultiSelect
                onChange={setSearchedResponsibles}
                chosenOptions={searchedResponsibles}
                label={translateString("responsibles")}
                placeholder={translateString("responsibles")}
                isLoading={isLoading}
                options={responsibleSelectOptions}
                noMargin
                noOptionsLabel={translateString("noOptions")}
            />
        );

        if (hasVendorManagementAccess && !isGroupEntities) {
            filterFields.push(
                <DotLegalMultiSelect
                    onChange={setSearchedLatestAudits}
                    chosenOptions={searchedLatestAudits}
                    label={translateString("legalEntitiesLatestAuditHeader")}
                    placeholder={translateString("legalEntitiesLatestAuditHeader")}
                    isLoading={isLoading}
                    options={latestAuditFilterOptions}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                />
            );
            filterFields.push(
                <DotLegalMultiSelect
                    onChange={setSearchedLatestAuditStatus}
                    chosenOptions={searchedLatestAuditStatus}
                    label={translateString("latestAuditStatus")}
                    placeholder={translateString("latestAuditStatus")}
                    isLoading={isLoading}
                    options={latestAuditStatusFilterOptions}
                    noMargin
                    noOptionsLabel={translateString("noOptions")}
                />
            );
        }

        return filterFields;
    };

    const getCustomListBtn = () => {
        if (permissions.customListPermissions.read) {
            return [<CustomListSelector key={0} type={CustomListSelectorType.Company} callPage={isGroupEntities ? CallPage.GroupCompanies : undefined} />];
        }

        return undefined;
    };

    function getLoadingState() {
        return <Skeleton width={"100%"} height={18} />;
    }

    const disableCreateButton = !addonsFeatures.organizationalManagement && isGroupEntities;

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs()} />
            <Box
                sx={{
                    "& img": {
                        height: 100,
                    },
                }}
            >
                <DotLegalActionBar
                    canCreate={(isGroupEntities && permissions.canManageGroupEntities) || (!isGroupEntities && permissions.companyPermissions.create)}
                    showDialog={showAddLegalEntityDialog}
                    icon={<VendorsIcon />}
                    additionalButtons={getCustomListBtn()}
                    primaryButton={
                        <PlanDialogButtonWrapper enabled={disableCreateButton}>
                            <DotLegalButton
                                buttonType="primary"
                                size="medium"
                                onClick={() => setShowAddLegalEntityDialog(true)}
                                disabled={disableCreateButton}
                                endIcon={disableCreateButton ? WhiteLockIcon : undefined}
                                toolTip={disableCreateButton ? translateString("upgradeToActivateAddOn") : undefined}
                            >
                                {translateString("createLegalEntity")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    }
                >
                    <AddLegalEntityDialog legalEntityPage={props.legalEntityPage} onDialogClose={() => setShowAddLegalEntityDialog(false)} />
                </DotLegalActionBar>
            </Box>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    {hasCompanies ? (
                        <Box>
                            <DotLegalFilterControls
                                extraControls={getSearchFields()}
                                searchFieldPlaceholder={translateString("search")}
                                onSearchStringChange={(e) => setSearchString(e)}
                                searchString={searchString}
                            />
                            <DotLegalTable
                                headers={getHeaders(isGroupEntities === false)}
                                defaultOrderBy={"name"}
                                defaultOrder={"asc"}
                                isLoading={isLoading}
                                emptyText={translateString("noCompaniesMatchesQuery")}
                                paginationLabelOf={translateString("labelOf")}
                                searchString={searchString}
                                data={rows}
                                rowsPerPage={pageLength}
                                renderRow={(row, i) => {
                                    const lastElement = rows![rows!.length - 1];
                                    return (
                                        <DotLegalTooltip
                                            key={i}
                                            text={!row.isActive ? translateString("legalEntityDeactivated") : undefined}
                                            followCursor
                                            position={"right"}
                                        >
                                            <TableRow
                                                onClick={() => onGoToLegalEntityClick(row.legalEntityId)}
                                                sx={(theme) => ({ backgroundColor: !row.isActive ? theme.palette.grey[100] : undefined, ...styles.tableRow })}
                                            >
                                                <TableCell sx={styles.bold}>{row.name}</TableCell>
                                                <TableCell>{row.countryName}</TableCell>
                                                <TableCell>
                                                    {row.businessAreas.map((x, index) => {
                                                        return (
                                                            <Box sx={styles.chip} key={x.name + index}>
                                                                <DotLegalChip
                                                                    value={x.name}
                                                                    color={x.color}
                                                                    size="small"
                                                                    isSelected
                                                                    collapse={row.businessAreas.length > 2 && index > 0}
                                                                />
                                                            </Box>
                                                        );
                                                    })}
                                                </TableCell>
                                                {(hasVendorManagementAccess || oldIsmsPlanType) && (
                                                    <>
                                                        <TableCell>
                                                            <Box
                                                                sx={(theme) => ({
                                                                    display: "flex",
                                                                    gap: 1,
                                                                    "& .MuiTypography-root": { fontSize: theme.typography.pxToRem(12) },
                                                                })}
                                                            >
                                                                {row.complianceAreas.map((area) => {
                                                                    return <ComplianceAreaWrapper key={area.id} complianceArea={area.name} />;
                                                                })}
                                                            </Box>
                                                        </TableCell>
                                                        {(vendorManagementFeatures.certifications || informationSecurityFeatures.certifications) && (
                                                            <TableCell>
                                                                {row.certifications
                                                                    .map((certification) => {
                                                                        return certification.name;
                                                                    })
                                                                    .join(", ")}
                                                            </TableCell>
                                                        )}
                                                    </>
                                                )}

                                                <TableCell>{row.responsibleName}</TableCell>
                                                <TableCell>
                                                    <SystemLinks systems={row.assets} />
                                                </TableCell>
                                                <TableCell>{row.isApproved && <CheckIcon sx={{ height: "20px !important" }} />}</TableCell>

                                                {hasVendorManagementAccess && (
                                                    <>
                                                        <TableCell sx={{ minWidth: 350 }}>
                                                            {auditIsLoading
                                                                ? getLoadingState()
                                                                : row.classifications &&
                                                                  row.classifications.length > 0 && (
                                                                      <ClassificationRisk
                                                                          legalEntityId={row.legalEntityId}
                                                                          legalEntityPage={props.legalEntityPage}
                                                                          classifications={row.classifications}
                                                                      />
                                                                  )}
                                                        </TableCell>

                                                        {!isGroupEntities && (
                                                            <>
                                                                <TableCell sx={{ padding: 0, minWidth: 150, position: "relative" }}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            bottom: 0,
                                                                            left: 0,
                                                                            right: 0,
                                                                            alignItems: "center",
                                                                            paddingLeft: "16px !important",
                                                                            borderTopLeftRadius: i === 0 ? "20px" : "unset",
                                                                            borderBottomLeftRadius:
                                                                                i === rows!.length - 1 ||
                                                                                i === pageLength - 1 ||
                                                                                row.legalEntityId === lastElement.legalEntityId
                                                                                    ? "20px"
                                                                                    : "unset",
                                                                            backgroundColor: "#F5F5FC",
                                                                        }}
                                                                    >
                                                                        {auditIsLoading ? getLoadingState() : row.latestAudit?.name}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell sx={{ padding: 0, minWidth: 100, position: "relative" }}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            bottom: 0,
                                                                            left: 0,
                                                                            right: 0,
                                                                            alignItems: "center",
                                                                            paddingRight: "16px !important",
                                                                            borderTopRightRadius: i === 0 ? "20px" : "unset",
                                                                            borderBottomRightRadius:
                                                                                i === rows!.length - 1 ||
                                                                                i === pageLength - 1 ||
                                                                                row.legalEntityId === lastElement.legalEntityId
                                                                                    ? "20px"
                                                                                    : "unset",
                                                                            backgroundColor: "#F5F5FC",
                                                                        }}
                                                                    >
                                                                        {auditIsLoading
                                                                            ? getLoadingState()
                                                                            : row.latestAudit && (
                                                                                  <QuestionnaireStatus
                                                                                      showQuestionsStatistics={row.latestAudit.showQuestionsStatistics}
                                                                                      numberOfQuestionsAnswered={row.latestAudit.numberOfQuestionsAnswered}
                                                                                      totalNumberOfQuestions={row.latestAudit.totalNumberOfQuestions}
                                                                                      status={row.latestAudit.status}
                                                                                      lastestRecipientUpdate={
                                                                                          row.latestAudit.lastestRecipientUpdate || undefined
                                                                                      }
                                                                                  />
                                                                              )}
                                                                    </Box>
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        <TableCell>
                                                            <Box sx={styles.greenArrowContainer}>{greenArrowRight}</Box>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        </DotLegalTooltip>
                                    );
                                }}
                            />
                        </Box>
                    ) : (
                        <DotLegalEmptyState
                            icon={<NoData />}
                            text={isGroupEntities ? translateString("noGroupEntities") : translateString("noLegalEntities")}
                        />
                    )}
                </DotLegalPaper>
            </Box>
        </Box>
    );
}

export default LegalEntities;
