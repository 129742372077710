import { StepTypeEnum } from "./processingActivity/ProcessingActivity.types";
import { useLocation } from "react-router-dom";
import { RiskAssessmentArea, RiskComplianceArea } from "./riskAssessment/riskAssessments/RiskAssessments.types";
import { UrlToPathObject } from "@dotlegal/dotlegal-ui-components";
import { LegalEntityPageEnum } from "./legalEntity/legalEntities/LegalEntities.types";
import { isNullOrWhitespace } from "./common/stringOperations";
import { LegalEntityAuditTabs } from "./legalEntity/LegalEntity.types";

export function useUrlProvider() {
    const location = useLocation();
    function getStepParams(stepNumber?: number, subStepNumber?: number, create?: boolean) {
        const searchParams = new URLSearchParams(location.search);
        if (stepNumber) searchParams.set("step", stepNumber.toString());
        if (subStepNumber) {
            searchParams.set("substep", subStepNumber.toString());
        }
        if (create) searchParams.set("create", "true");
        return `?${searchParams.toString()}`;
    }

    function getEditProcessingActivityUrl(processingActivityId: string, step?: StepTypeEnum, subStep?: StepTypeEnum, entityId?: string, create?: boolean) {
        let stepParams = "";
        stepParams = getStepParams(step, subStep, create);
        const firstPart = `/privacy/processingactivity/${processingActivityId}/edit${stepParams}`;
        return UrlToPathObject(entityId ? firstPart + `#${entityId}` : firstPart);
    }

    function getEditProcessingActivityUrlCreateView(processingActivityId: string) {
        return getEditProcessingActivityUrl(processingActivityId, undefined, undefined, undefined, true);
    }

    function getProcessingActivityOverviewUrl(processingActivityId: string | undefined) {
        return `/privacy/processingactivity/${processingActivityId}/overview`;
    }

    function getProcessingActivityOverviewWithOpenCommenDialogUrl(processingActivityId: string) {
        return `/privacy/processingactivity/${processingActivityId}/overview?commentDialog=true`;
    }

    function getProcessingActivityValidationUrl(processingAcitivtyId: string) {
        return `/privacy/processingactivity/${processingAcitivtyId}/validate`;
    }

    function getSystemCreateViewUrl(systemId: string) {
        return getSystemEditUrl(systemId) + "?create=true";
    }

    function getLegalEntitiesUrl() {
        return "/privacy/legal-entities";
    }

    function getGroupEntitiesUrl() {
        return "/privacy/group-entities";
    }

    function getLegalEntityUrl(legalEntityId: string, tab?: LegalEntityAuditTabs) {
        let route = `/privacy/legal-entities/${legalEntityId}`;

        if (tab !== undefined) {
            return route + `?tab=${tab}`;
        }

        return route;
    }

    function getGroupEntityUrl(legalEntityId: string | undefined, tab?: LegalEntityAuditTabs) {
        let route = `/privacy/group-entities/${legalEntityId}`;

        if (tab !== undefined) {
            return route + `?tab=${tab}`;
        }

        return route;
    }

    function getOtherLegalEntities() {
        return `/privacy/other-legal-entities`;
    }

    function getOtherLegalEntityUrl(legalEntityId: string, tab?: LegalEntityAuditTabs) {
        let route = getOtherLegalEntities() + `/${legalEntityId}`;

        if (tab !== undefined) {
            return route + `?tab=${tab}`;
        }

        return route;
    }

    function getCustomerLegalEntities() {
        return `/privacy/customer-legal-entities`;
    }

    function getCustomerLegalEntityUrl(legalEntityId: string, tab?: LegalEntityAuditTabs) {
        let route = getCustomerLegalEntities() + `/${legalEntityId}`;
        if (tab !== undefined) {
            return route + `?tab=${tab}`;
        }

        return route;
    }

    function getLegalEntityBaseUrl(page: LegalEntityPageEnum, legalEntityId: string) {
        let rootUrl = "";

        switch (page) {
            case LegalEntityPageEnum.Customer:
                rootUrl = getCustomerLegalEntityUrl(legalEntityId);
                break;
            case LegalEntityPageEnum.GroupEntity:
                rootUrl = getGroupEntityUrl(legalEntityId);
                break;
            case LegalEntityPageEnum.Other:
                rootUrl = getOtherLegalEntityUrl(legalEntityId);
                break;
            case LegalEntityPageEnum.Vendor:
                rootUrl = getLegalEntityUrl(legalEntityId);
                break;
        }
        return rootUrl;
    }

    function getLegalEntityManagementUrl(page: LegalEntityPageEnum, legalEntityId: string) {
        let rootUrl = getLegalEntityBaseUrl(page, legalEntityId);

        return `${rootUrl}/management`;
    }

    function getProcessingActivitiesOverviewUrl() {
        return "/privacy/processingactivities";
    }

    function getSystemsUrl() {
        return "/privacy/systems";
    }

    function getRecordUrl() {
        return "/privacy/record";
    }

    function getSignOutUrl() {
        return "/privacy/sign-out";
    }

    function getHelpUrl(link: string) {
        if (link) {
            return link;
        }

        return "https://help.dotlegal.com/knowledge/privacy";
    }

    function getSettingsUrl() {
        return "/privacy/settings";
    }

    function getUsersUrl() {
        return "/privacy/users";
    }

    function getUserUrl(userId: string) {
        return `/privacy/user/${userId}`;
    }

    function getCustomersUrl() {
        return "/privacy/customers";
    }

    function getChangeOrganizationUrl() {
        return "/privacy/change-organization";
    }

    function getMasterDataUrl() {
        return "/privacy/masterdata";
    }

    function getForbiddenUrl() {
        return "/privacy/forbidden";
    }

    function getPurposesUrl() {
        return "/privacy/masterdata/purposes";
    }

    function getBusinessAreasUrl() {
        return "/privacy/masterdata/business-areas";
    }

    function getSecurityMeasures() {
        return "/privacy/masterdata/security-measures";
    }

    function getCertificationsUrl() {
        return "/privacy/masterdata/certifications";
    }

    function getAreasUrl() {
        return "/privacy/masterdata/areas";
    }

    function getDeletionPeriods() {
        return "/privacy/masterdata/deletion-periods";
    }

    function getDataCategories() {
        return "/privacy/masterdata/data-categories";
    }

    function getDataTypesUrl() {
        return "/privacy/masterdata/data-types";
    }
    function getDataSubjectsUrl() {
        return "/privacy/masterdata/data-subjects";
    }

    function getNationalLawsUrl() {
        return "/privacy/masterdata/national-laws";
    }

    function getLegislationsUrl() {
        return "/privacy/masterdata/legislations";
    }

    function getHelpTextsUrl() {
        return "/privacy/masterdata/help-texts";
    }

    function getRiskAssessmentVersionsUrl() {
        return "/privacy/masterdata/risk-assessment-versions";
    }

    function getRiskAssessmentsUrl() {
        return "/privacy/risk-assessments";
    }

    function getRiskAssessmentTemplateUrl() {
        return `/privacy/risk-assessments/template`;
    }

    function getRiskAssessmentsPDF(id: string, riskAssessmentArea: RiskAssessmentArea, riskComplianceArea: RiskComplianceArea, versionId: string) {
        return `/privacy/risk-assessment/${id}/${riskAssessmentArea}/${riskComplianceArea}/${versionId}/pdf`;
    }

    function getProcessingActivityRiskScenariosDialog(id: string | undefined, versionId?: string) {
        return `/privacy/risk-assessments/${id}/${versionId}/scenarios`;
    }

    function getRiskAssessmentQuestionsDialog(
        id: string | undefined,
        riskAssessmentArea: RiskAssessmentArea,
        riskComplianceArea: RiskComplianceArea,
        versionId: string
    ) {
        if (!id) return `/privacy/risk-assessment`;
        return `/privacy/risk-assessment/${id}/${riskAssessmentArea}/${riskComplianceArea}/${versionId}/questions`;
    }

    function getSystemOverviewUrl(systemId: string | undefined) {
        return `/privacy/system/${systemId}/overview`;
    }

    function getSystemEditUrl(systemId?: string) {
        return systemId ? `/privacy/system/${systemId}/edit` : `/privacy/system`;
    }

    function getAnnualWheelUrl() {
        return "/privacy/annual-wheel";
    }

    function getAnnualWheelPDFUrl() {
        return "/privacy/annual-wheel-pdf";
    }

    function getTaskManagementUrl(queryString?: string) {
        let url = "/privacy/task-management";
        if (isNullOrWhitespace(queryString)) {
            return url;
        }
        return url + queryString;
    }

    function getTaskManagementTaskDialogUrl(taskId?: string, months?: number, type?: string) {
        let queryString = `?taskId=${taskId}`;
        if (months !== undefined) {
            queryString = queryString + `&months=${months}`;
        }

        if (type !== undefined) {
            queryString = queryString + `&type=${months}`;
        }

        return getTaskManagementUrl(queryString);
    }

    function getAddEditCustomerDialog(customerId: string) {
        return `/privacy/customer/${customerId}/edit`;
    }

    function getNotificationUrl() {
        return "/privacy/notifications";
    }

    function getCustomListViewWithOpenDialogUrl(id: string) {
        return "/privacy/custom-list-view/" + id + "?showDialog=true";
    }

    function getCustomListViewUrl(id: string) {
        return "/privacy/custom-list-view/" + id;
    }

    function getCustomProcessingActivityListsUrl() {
        return "/privacy/custom-processing-activity-lists";
    }

    function getCustomCompanyListsUrl() {
        return "/privacy/custom-company-lists";
    }

    function getCustomGroupCompanyListsUrl() {
        return "/privacy/custom-group-company-lists";
    }

    function getCustomSharingListsUrl() {
        return "/privacy/custom-sharing-lists";
    }

    function getCustomSystemListsUrl() {
        return "/privacy/custom-system-lists";
    }

    function gerRolesEditUrl(userRolesId: string) {
        return `/privacy/role/${userRolesId}/edit`;
    }

    function getUserRolesUrl() {
        return "/privacy/roles";
    }

    function getEditRolesUrl(roleId: string) {
        return `/privacy/role/${roleId}/edit-permission`;
    }

    function getPoliciesUrl() {
        return "/privacy/policies";
    }

    function getPolicyUrl(id: string) {
        return "/privacy/policy/" + id;
    }

    function getDeclarationsUrl() {
        return "/privacy/declarations";
    }

    function getDeclarationUrl(id: string) {
        return `/privacy/declarations/${id}`;
    }

    function getProcessingActivityRegistrationOfResearchProjectReportPDF(id: string) {
        return `/privacy/processing-activity/${id}/registration-of-research-report-pdf`;
    }

    function getProcessingActivityInternalAcquisitionOfSignatureReportPDF(id: string | undefined) {
        return `/privacy/processing-activity/${id}/internal-acquisition-of-signature-report-pdf`;
    }

    function getProcessingActivityResearchEthicsApprovalReportReportPDF(id: string | undefined) {
        return `/privacy/processing-activity/${id}/research-ethics-approval-report-pdf`;
    }

    function getProcessingActivityStatisticUrl() {
        return `/privacy/processing-activity-statistics`;
    }

    function getProcessingActivityStatisticPdfUrl() {
        return `/privacy/processing-activity-statistics/pdf`;
    }

    function getIncidentLogUrl() {
        return "/privacy/incident-log";
    }

    function getIncidentLogOverviewUrl(id: string) {
        return `/privacy/incident-log/${id}/overview`;
    }

    function getAuditsUrl() {
        return "/privacy/questionnaires/audits";
    }

    function getVendorClassifications() {
        return "/privacy/questionnaires/vendor-classifications";
    }

    function getLegalEntityClassificationUrl(legalEntityPage: LegalEntityPageEnum, legalEntityId: string, responseId: string) {
        const legalEntityBaseUrl = getLegalEntityBaseUrl(legalEntityPage, legalEntityId);
        return `${legalEntityBaseUrl}/classification/response-internal/${responseId}/introduction?redirectAfterSubmitUrl=${legalEntityBaseUrl}`;
    }

    function getQuestionnaireVendorClassificationOverview(questionnaireId: string) {
        return `/privacy/questionnaires/vendor-classifications/${questionnaireId}/overview`;
    }

    function getQuestionnaireAuditverview(questionnaireId: string) {
        return `/privacy/questionnaires/audits/${questionnaireId}/overview`;
    }

    function getQuestionnaireResponseReport(
        questionnaireResponseId: string,
        deadline: Date,
        senderName: string,
        respondent: string,
        pdf: boolean,
        showRecommendations: boolean
    ) {
        let url = `/privacy/questionnaires/no-menu/response-internal/${questionnaireResponseId}/report?deadline=${deadline}&sender=${encodeURIComponent(senderName)}&respondent=${encodeURIComponent(respondent)}&showRecommendations=${showRecommendations}`;
        if (pdf) {
            url = url + "&isPdf=true";
        }

        return url;
    }

    function getQuestionnaireResponseExternalreport(
        questionnaireexternalId: string,
        deadline: Date,
        senderName: string,
        respondent: string,
        assessmentTitle: string | undefined,
        pdf: boolean,
        showRecommendations: boolean,
        includeScoreForInternalAssessments: boolean
    ) {
        const nameParameter = assessmentTitle ? `&name=${encodeURIComponent(assessmentTitle)}` : ``;

        let url = `/privacy/questionnaires/external/response-external/${questionnaireexternalId}/report?deadline=${deadline}&sender=${encodeURIComponent(senderName)}&respondent=${encodeURIComponent(respondent)}${nameParameter}&showRecommendations=${showRecommendations}&includeScoreForInternalAssessments=${includeScoreForInternalAssessments}`;
        if (pdf) {
            url = url + "&isPdf=true";
        }
        return url;
    }

    function getClassificationQuestionnaireResponseReport(questionnaireResponseId: string, respondent: string, pdf: boolean, showRecommendations: boolean) {
        let url = `/privacy/questionnaires/no-menu/response-internal/${questionnaireResponseId}/report?respondent=${encodeURIComponent(respondent)}&showRecommendations=${showRecommendations}`;
        if (pdf) {
            url = url + "&isPdf=true";
        }
        return url;
    }

    function getInternalAssessmentQuestionnaireResponseReport(
        questionnaireResponseId: string,
        deadline: Date,
        senderName: string,
        assesmentTille: string,
        pdf: boolean,
        showRecommendations: boolean,
        respondent: string
    ) {
        let url = `/privacy/questionnaires/no-menu/response-internal/${questionnaireResponseId}/report?deadline=${deadline}&sender=${encodeURIComponent(senderName)}&name=${encodeURIComponent(assesmentTille)}&showRecommendations=${showRecommendations}&respondent=${encodeURIComponent(respondent)}`;
        if (pdf) {
            url = url + "&isPdf=true";
        }
        return url;
    }

    function getInternalAssessmentUrl() {
        return "/privacy/internal-assessments";
    }

    function getPublicInternalAssessmentTemplateUrl() {
        return "/privacy/internal-assessment-templates";
    }

    function getPublicStartInternalAssessmentUrl(externalId: string) {
        return `/privacy/start-internal-assessment/${externalId}`;
    }

    function getDeclarationCategoriesUrl(declarationId: string) {
        return `/privacy/declaration-categories/${declarationId}`;
    }

    function getExternalQuestionnaireResponseUrl(externalId: string) {
        return `/privacy/questionnaires/external/response-external/${externalId}/introduction`;
    }

    function getAuditGroupsUrl() {
        return `/privacy/audit-groups/`;
    }

    function getAuditGroupUrl(auditGroupId: string) {
        return `/privacy/audit-group/${auditGroupId}`;
    }

    function getAuditGroupDraftUrl(auditGroupId: string) {
        return `/privacy/audit-group/${auditGroupId}/draft`;
    }

    function getAuditGroupManagementReportUrl(auditGroupId: string) {
        return `/privacy/audit-group/${auditGroupId}/management-report`;
    }

    function getDotLegalVendorMangementInfoUrl() {
        return "https://www.dotlegal.com/en/product/vendor-management-software";
    }

    function getDashboardUrl() {
        return `/privacy/dashboard`;
    }

    function getComplianceReportUrl(id: string) {
        return `/privacy/dashboard/compliance-report/${id}`;
    }

    function getTagsUrl() {
        return "/privacy/masterdata/tags";
    }

    function getDotLegalContactForm() {
        return "https://www.dotlegal.com/en/about/contact-us";
    }

    return {
        getMasterDataUrl,
        getEditProcessingActivityUrl,
        getProcessingActivityOverviewUrl,
        getProcessingActivityOverviewWithOpenCommenDialogUrl,
        getEditProcessingActivityUrlCreateView,
        getLegalEntitiesUrl,
        getLegalEntityUrl,
        getProcessingActivitiesOverviewUrl,
        getRecordUrl,
        getSignOutUrl,
        getHelpUrl,
        getGroupEntitiesUrl,
        getSettingsUrl,
        getGroupEntityUrl,
        getUsersUrl,
        getUserUrl,
        getCustomersUrl,
        getChangeOrganizationUrl,
        getForbiddenUrl,
        getPurposesUrl,
        getSecurityMeasures,
        getDeletionPeriods,
        getBusinessAreasUrl,
        getDataCategories,
        getDataSubjectsUrl,
        getNationalLawsUrl,
        getRiskAssessmentsUrl,
        getRiskAssessmentsPDF,
        getProcessingActivityRiskScenariosDialog,
        getRiskAssessmentQuestionsDialog,
        getRiskAssessmentTemplateUrl,
        getSystemsUrl,
        getSystemOverviewUrl,
        getSystemCreateViewUrl,
        getSystemEditUrl,
        getLegislationsUrl,
        getAnnualWheelUrl,
        getAnnualWheelPDFUrl,
        getTaskManagementUrl,
        getAddEditCustomerDialog,
        getNotificationUrl,
        getCustomListViewUrl,
        getCustomProcessingActivityListsUrl,
        getCustomCompanyListsUrl,
        getCustomSharingListsUrl,
        getCustomSystemListsUrl,
        getCustomListViewWithOpenDialogUrl,
        gerRolesEditUrl,
        getUserRolesUrl,
        getEditRolesUrl,
        getPoliciesUrl,
        getPolicyUrl,
        getHelpTextsUrl,
        getProcessingActivityRegistrationOfResearchProjectReportPDF,
        getProcessingActivityInternalAcquisitionOfSignatureReportPDF,
        getProcessingActivityResearchEthicsApprovalReportReportPDF,
        getProcessingActivityStatisticUrl,
        getProcessingActivityStatisticPdfUrl,
        getCustomGroupCompanyListsUrl,
        getCertificationsUrl,
        getAreasUrl,
        getRiskAssessmentVersionsUrl,
        getIncidentLogUrl,
        getIncidentLogOverviewUrl,
        getAuditsUrl,
        getQuestionnaireResponseReport,
        getOtherLegalEntities,
        getOtherLegalEntityUrl,
        getCustomerLegalEntities,
        getCustomerLegalEntityUrl,
        getLegalEntityManagementUrl,
        getVendorClassifications,
        getLegalEntityClassificationUrl,
        getQuestionnaireVendorClassificationOverview,
        getQuestionnaireAuditOverview: getQuestionnaireAuditverview,
        getClassificationQuestionnaireResponseReport,
        getInternalAssessmentUrl,
        getInternalAssessmentQuestionnaireResponseReport,
        getProcessingActivityValidationUrl,
        getDeclarationsUrl,
        getDeclarationUrl,
        getPublicInternalAssessmentTemplateUrl,
        getPublicStartInternalAssessmentUrl,
        getDeclarationCategoriesUrl,
        getQuestionnaireResponseExternalreport,
        getExternalQuestionnaireResponseUrl,
        getAuditGroupsUrl,
        getAuditGroupUrl,
        getAuditGroupDraftUrl,
        getAuditGroupManagementReportUrl,
        getTaskManagementTaskDialogUrl,
        getDotLegalVendorMangementInfoUrl,
        getDashboardUrl,
        getComplianceReportUrl,
        getTagsUrl,
        getDotLegalContactForm,
        getDataTypesUrl,
    };
}
