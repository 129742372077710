import { DotLegalInfoDialog } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import useSendInternalAssessmentReminderDialogHooks from "./SendInternalAssessmentReminderDialog.hooks";
import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";

export interface ISendInternalAssessmentReminderDialog {
    onCloseDialog: () => void;
    onReminderSent: () => void;
    selectedInternalAssessment: InternalAssessmentApiRow;
}

function SendInternalAssessmentReminderDialog(props: ISendInternalAssessmentReminderDialog) {
    const { translateString } = useTranslation();
    const { sendReminder, isSending } = useSendInternalAssessmentReminderDialogHooks(props);

    return (
        <DotLegalInfoDialog
            header={translateString("sendReminder")}
            btnText={translateString("send")}
            content={
                <Trans
                    i18nKey={"sendInternalAssessmentReminder"}
                    values={{
                        audit: props.selectedInternalAssessment.name,
                        respondent: props.selectedInternalAssessment.assignee.name,
                    }}
                    components={{
                        strong: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.primary.dark })}></Box>,
                        box: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.secondary.main })}></Box>,
                    }}
                />
            }
            onCloseDialog={props.onCloseDialog}
            onBtnClick={() => sendReminder()}
            inProgress={isSending}
        />
    );
}

export default SendInternalAssessmentReminderDialog;
