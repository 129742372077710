import { useTheme } from "@mui/material";
import blueWave from "./blueWave.svg?url";
import purpleWave from "./purpleWave.svg?url";
import greenWave from "./greenWave.svg?url";
import purpleWave1 from "./purpleWave1.svg?url";
import smallPurpleWave from "./smallPurpleWave.svg?url";
import { IDotLegalPaperProps } from "./DotLegalPaper";
import { isNullOrWhitespace } from "../../stringOperations";
import { createSxStyles } from "../../createSxStyles";

export const useDotLegalPaperStyles = (props: IDotLegalPaperProps) => {
    const theme = useTheme();
    return createSxStyles({
        root: () => {
            if (props.onClick && props.clickableRows) {
                return {
                    padding: props.removePadding ? 0 : theme.spacing(2),
                    boxShadow: "0px 3px 10px #E7E7E7",
                    borderRadius: 20 + "px",
                    cursor: "pointer",
                    height: props.fullHeight ? "100%" : undefined,
                    ...getBackgroundColor(props),
                };
            }
            return {
                padding: props.removePadding ? 0 : theme.spacing(2),
                boxShadow: "0px 3px 10px #E7E7E7",
                borderRadius: 20 + "px",
                height: props.fullHeight ? "100%" : undefined,
                ...getBackgroundColor(props),
            };
        },
        paperBackground: getBackgroundImage(props),
    });
};

function getBackgroundColor(props: IDotLegalPaperProps) {
    let backgroundColor = "";
    let textColor = "";
    if (props.backgroundColor) {
        switch (props.backgroundColor) {
            case "lightGreen":
                backgroundColor = "#E6FAF8";
                textColor = "#258E9F";
                break;
            case "lightGrey":
                backgroundColor = "#F0F0F0";
                textColor = "#707070";
                break;
            case "darkLightBlue":
                backgroundColor = "#DCE3F1";
                textColor = "#3C63AD";
                break;
            case "lightBlue":
                backgroundColor = "#E9F2FA";
                textColor = "#032478";
                break;
            case "lightPurple":
                backgroundColor = "#F3F3FB";
                textColor = "#7284FA";
                break;
            case "orange":
                backgroundColor = "#FDC6A2";
                textColor = "#FF7317";
                break;
            case "darkGreen":
                backgroundColor = "#AADEC6";
                textColor = "#479370";
                break;
            case "limeGreen":
                backgroundColor = "#E2F3C2";
                textColor = "#7DA532";
                break;
            case "pink":
                backgroundColor = "#F5D3E8";
                textColor = "#E880C0";
                break;
        }
        return {
            backgroundColor: isNullOrWhitespace(backgroundColor) ? "inherit" : backgroundColor + " !important",
            "& > *": {
                color: textColor + " !important",
            },
        };
    }
    return {};
}

function getBackgroundImage(props: IDotLegalPaperProps) {
    if (props.background) {
        let backgroundImage = "";

        switch (props.background) {
            case "blueWave":
                backgroundImage = blueWave;
                break;
            case "purpleWave":
                backgroundImage = purpleWave;
                break;
            case "greenWave":
                backgroundImage = greenWave;
                break;
            case "purpleWave1":
                backgroundImage = purpleWave1;
                break;
            case "smallPurpleWave":
                backgroundImage = smallPurpleWave;
                break;
        }

        return {
            backgroundImage: `url("${backgroundImage}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: props.backgroundCover ? "cover" : "contain",
        };
    }
    return {};
}
