import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { DotLegalCheckbox, DotLegalInformationTooltip, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import DotLegalInformationBox from "../../common/components/dotLegalInformationBox/DotLegalInformationBox";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import DotLegalTemplateDialog from "../../common/components/dotLegalTemplateDialog/DotLegalTemplateDialog";
import { star } from "../../common/icons";
import { useTranslation } from "../../localization/useTranslation";
import { useProcessingActivityTemplatesMapping } from "./ProcessingActivityTemplatesDialog.hooks";
import { useProcessingActivityTemplatesDialogStyles } from "./ProcessingActivityTemplatesDialog.styles";
import { SelectableProcessingActivityTemplate } from "./ProcessingActivityTemplatesDialog.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { ProcessingActivityTemplates } from "../../auth/planProvider/PlanProvider.types";
import { Trans } from "react-i18next";

export interface IProcessingActivityTemplatesDialogProps {
    closeDialog: () => void;
}

function ProcessingActivityTemplatesDialog(props: IProcessingActivityTemplatesDialogProps) {
    const { translateString } = useTranslation();
    const styles = useProcessingActivityTemplatesDialogStyles();
    const { dataProtectionFeatures } = usePlanContext();

    const {
        isLoading,
        businessAreaData,
        businessAreaTemplates,
        handleTemplateChange,
        selectedTemplates,
        postTemplates,
        isSaving,
        mostPopularTemplates,
        industryAreaData,
        industryAreaTemplates,
        setSelectedIndustryAreaId,
        setSelectedBusinessAreaId,
        addAllTemplatesClick,
        data,
        numberOfProcessingActivities,
    } = useProcessingActivityTemplatesMapping(props);

    const processingActivityLimitReached =
        dataProtectionFeatures.maxNumberOfProcessingActivities && numberOfProcessingActivities
            ? numberOfProcessingActivities >= dataProtectionFeatures.maxNumberOfProcessingActivities
            : false;

    const getContent = () => {
        const tabContent = Array<TabContent>();
        let industryAreaTab = {
            label: translateString("industryArea"),
            content: getIndustryAreaContent(),
            index: 1,
        };

        if (data?.showOnlyIndustryAreas) {
            industryAreaTab.index = 0;
            tabContent.push(industryAreaTab);
        } else {
            tabContent.push({ label: translateString("mostPopular"), content: getMostPopularContent(), index: 0, icon: star });
            tabContent.push(industryAreaTab);
            tabContent.push({
                label: translateString("processingActivityAreas"),
                content: getBusinessAreaContent(),
                index: 2,
            });
        }

        return tabContent;
    };

    const getTemplateCheckBox = (template: SelectableProcessingActivityTemplate | undefined, isDisabled: boolean) => {
        if (template) {
            var templateIndex = selectedTemplates.indexOf(template.id);
            var isChecked = templateIndex >= 0;

            const checkBox = (
                <DotLegalCheckbox
                    label={template.name}
                    checked={isChecked}
                    fontSize={"s"}
                    onChange={() => handleTemplateChange(!isChecked, template.id, templateIndex)}
                    disabled={isDisabled}
                />
            );

            if (isDisabled) {
                return (
                    <DotLegalTooltip text={translateString("upgradeToAccessAllProcessingActivityTemplates")}>
                        <span>{checkBox}</span>
                    </DotLegalTooltip>
                );
            }

            return checkBox;
        }

        return undefined;
    };

    const getTemplateToolTip = (templateDescription?: string) => {
        if (templateDescription)
            return (
                <Box sx={{ marginLeft: 1 }}>
                    <DotLegalInformationTooltip text={templateDescription} />
                </Box>
            );

        return undefined;
    };

    const getIndustryAreaContent = () => {
        var allTempaltes: Array<SelectableProcessingActivityTemplate> = [];
        industryAreaTemplates?.forEach((x) =>
            x.templates.forEach((template) => {
                if (template) {
                    allTempaltes.push(template);
                }
            })
        );
        return (
            <Box sx={styles.container}>
                <Box sx={styles.gridContainer}>
                    <Typography sx={styles.gridContainerHeader}>{translateString("filterProcessingTemplatesByIndustryArea")}</Typography>
                    <Grid container spacing={2}>
                        {industryAreaData
                            ?.filter((x) => x.hasData)
                            .map((industryArea, index) => {
                                let hasTemplates = industryArea.templateCount && industryArea.templateCount > 0;
                                return (
                                    <Grid key={industryArea.id} item xs={12} md={4} lg={4}>
                                        <DotLegalInformationBox
                                            header={`${industryArea.name} ${hasTemplates ? `(${industryArea.templateCount})` : ""}`}
                                            isSelected={industryArea.isSelected ? "selected" : hasTemplates ? "secondarySelected" : "unselected"}
                                            size="small"
                                            isLoading={isLoading}
                                            onChange={() => setSelectedIndustryAreaId(industryArea.id)}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Box>

                {industryAreaTemplates !== undefined && industryAreaTemplates.length > 0 && (
                    <Box sx={styles.gridContainer}>
                        <Box sx={styles.templateHeaderContainer}>
                            <Typography sx={styles.gridContainerHeader}>
                                {translateString("chooseProcessingTemplates", { name: industryAreaData?.find((x) => x.isSelected)?.name })}
                            </Typography>
                            {!dataProtectionFeatures.maxNumberOfProcessingActivities &&
                                allTempaltes &&
                                allTempaltes.length !== allTempaltes.filter((x) => selectedTemplates.indexOf(x.id) >= 0).length && (
                                    <Typography sx={styles.chooseAll} onClick={() => addAllTemplatesClick(allTempaltes)}>
                                        {translateString("chooseAll")}
                                    </Typography>
                                )}
                        </Box>

                        {industryAreaTemplates
                            ?.filter((x) => x.templates.length > 0)
                            .map((businessArea) => {
                                return (
                                    <div key={businessArea.id}>
                                        <Typography sx={styles.mostPopularGridContainer}>{businessArea.name}</Typography>
                                        <Grid container spacing={1}>
                                            {businessArea.templates.map((template, index) => {
                                                var disabled = dataProtectionFeatures.processingActivityTemplates === ProcessingActivityTemplates.MostPopular;
                                                return (
                                                    <Grid sx={styles.templateSelectorContainer} key={template?.id ?? index} item xs={12} md={4} lg={4}>
                                                        {getTemplateCheckBox(template, disabled)}
                                                        {getTemplateToolTip(template?.description)}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </div>
                                );
                            })}
                    </Box>
                )}
            </Box>
        );
    };

    const getMostPopularContent = () => {
        let allPopularTemplates = data?.templates.filter((x) => x.mostPopular);

        return (
            <Box sx={styles.gridContainer}>
                <Box sx={styles.templateHeaderContainer}>
                    <Typography sx={styles.gridContainerHeader}>
                        {translateString("chooseProcessingTemplatesMostPopular", { businessArea: businessAreaData?.find((x) => x.isSelected)?.name })}
                    </Typography>
                    {!dataProtectionFeatures.maxNumberOfProcessingActivities &&
                        allPopularTemplates &&
                        allPopularTemplates.length !== allPopularTemplates.filter((x) => selectedTemplates.indexOf(x.id) >= 0).length && (
                            <Typography onClick={() => addAllTemplatesClick(allPopularTemplates)} sx={styles.chooseAll}>
                                {" "}
                                {translateString("chooseAll")}{" "}
                            </Typography>
                        )}
                </Box>

                {mostPopularTemplates
                    ?.filter((x) => x.templates.length > 0)
                    .map((businessArea) => {
                        return (
                            <div key={businessArea.id}>
                                <Typography sx={styles.mostPopularGridContainer}>{businessArea.name}</Typography>
                                <Grid container spacing={1}>
                                    {businessArea.templates.map((template, index) => {
                                        var templateIndex = selectedTemplates.indexOf(template!.id);
                                        var isChecked = templateIndex >= 0;
                                        let disable = processingActivityLimitReached && !isChecked;
                                        return (
                                            <Grid sx={styles.templateSelectorContainer} key={template?.id ?? index} item xs={12} md={4} lg={4}>
                                                {getTemplateCheckBox(template, disable)}
                                                {getTemplateToolTip(template?.description)}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        );
                    })}

                {processingActivityLimitReached && (
                    <Alert severity="error" sx={(theme) => ({ "& .MuiSvgIcon-root": { fill: theme.palette.warning.main }, marginTop: 3 })}>
                        <AlertTitle>{translateString("warning")}</AlertTitle>
                        <Trans i18nKey="processingActivityLimitReached" values={{ count: dataProtectionFeatures.maxNumberOfProcessingActivities }}></Trans>
                    </Alert>
                )}
            </Box>
        );
    };

    const getBusinessAreaContent = () => {
        return (
            <Box sx={styles.container}>
                <Box sx={styles.gridContainer}>
                    <Typography sx={styles.gridContainerHeader}>{translateString("filterProcessingTemplatesByBusinessArea")}</Typography>
                    <Grid container spacing={2}>
                        {businessAreaData
                            ?.filter((x) => x.hasData)
                            .map((businessArea, index) => {
                                let hasTemplates = businessArea.templateCount && businessArea.templateCount > 0;
                                return (
                                    <Grid key={businessArea.id} item xs={12} md={4} lg={4}>
                                        <DotLegalInformationBox
                                            header={`${businessArea.name} ${hasTemplates ? `(${businessArea.templateCount})` : ""}`}
                                            isSelected={businessArea.isSelected ? "selected" : hasTemplates ? "secondarySelected" : "unselected"}
                                            size="small"
                                            isLoading={isLoading}
                                            onChange={() => setSelectedBusinessAreaId(businessArea.id)}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Box>

                {businessAreaTemplates !== undefined && businessAreaTemplates.length > 0 && (
                    <Box sx={styles.gridContainer}>
                        <Box sx={styles.templateHeaderContainer}>
                            <Typography sx={styles.gridContainerHeader}>
                                {translateString("chooseProcessingTemplates", { name: businessAreaData?.find((x) => x.isSelected)?.name })}
                            </Typography>
                            {!dataProtectionFeatures.maxNumberOfProcessingActivities &&
                                businessAreaTemplates.length > 0 &&
                                businessAreaTemplates.length !== businessAreaTemplates.filter((x) => selectedTemplates.indexOf(x.id) >= 0).length && (
                                    <Typography onClick={() => addAllTemplatesClick(businessAreaTemplates!)} sx={styles.chooseAll}>
                                        {" "}
                                        {translateString("chooseAll")}{" "}
                                    </Typography>
                                )}
                        </Box>
                        <Grid container spacing={2}>
                            {businessAreaTemplates?.map((template) => {
                                var disabled = dataProtectionFeatures.processingActivityTemplates === ProcessingActivityTemplates.MostPopular;
                                return (
                                    <Grid sx={styles.templateSelectorContainer} key={template.id} item xs={12} md={4} lg={4}>
                                        <React.Fragment>
                                            {getTemplateCheckBox(template, disabled)}
                                            {getTemplateToolTip(template.description)}
                                        </React.Fragment>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <DotLegalTemplateDialog
            isLoading={isLoading}
            inProgress={isSaving}
            tabContent={getContent()}
            buttonOkText={`${translateString("load")} ${selectedTemplates.length > 0 ? `(${selectedTemplates.length})` : ""}`}
            templateCount={selectedTemplates.length}
            header={translateString("standardProcessingActivities")}
            open
            onDialogClose={props.closeDialog}
            onOkClick={() => postTemplates()}
        />
    );
}

export default ProcessingActivityTemplatesDialog;
