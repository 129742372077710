export interface ApplicationFeatures {
    platformFeatures: PlatformFeatures;
    dataProtectionFeatures: DataProtectionFeatures;
    informationSecurityFeatures: InformationSecurityFeatures;
    vendorManagementFeatures: VendorManagementFeatures;
    addonsFeatures: AddonFeatures;
    hasPremium: boolean;
    hasVendorManagementAccess: boolean;
    hasTaskManagementAccess: boolean;
    planType: PlanType; //TODO NID - Skal på sigt fjernes når alle kunder er på de nye planer,
    oldIsmsPlanType?: PlanType; //TODO NID - Skal på sigt fjernes når alle kunder er på de nye planer,
}

export interface PlatformFeatures {
    maxNumberOfGroupCompanies?: number;
    dashboard: boolean;
    maxNumberOfPolicies?: number;
    commentModule: boolean;
    addTasksToOutlook: boolean;
    annualWheelOverview: boolean;
    annualWheelReport: boolean;
    annualWheelCopyActivity: boolean;
    taskHistory: boolean;
    assignTask: boolean;
    signOff: boolean;
    annualWheelComplianceArea: boolean;
    riskVersions: boolean;
    tags: boolean;
    relatedActivitiesAndTasks: boolean;
    auditLog: boolean;
}

export interface DataProtectionFeatures {
    hasDataProtection: boolean;
    maxNumberOfProcessingActivities?: number;
    processingActivityTemplates: ProcessingActivityTemplates;
    processingActivityValidationFlow: boolean;
    dataProcessingAgreements: boolean;
    processingActivitiesDocuments: boolean;
    standardGDPRActivities: StandardGDPRActivities;
    maxNumberOfProcessingActivityRiskAssessments?: number;
    maxNumberOfGDPRRiskScenarios?: number;
    gdprRiskAssessmentConsequenceCatalogue: boolean;
    gdprRiskReports: boolean;
    maxNumberOfIncidentLogEntries?: number;
    processingActivityStatus: boolean;
    registrationOfDataController: boolean;
    sharedResponsibility: boolean;
    linkPoliciesAndProcessingActivities: boolean;
    annualWheelProcessingActivitiesValidation: boolean;
    recordDataProcessor: boolean;
    recordsExport: boolean;
    standardListExport: boolean;
    maxNumberOfContactsOnUnit?: number;
}

export interface InformationSecurityFeatures {
    maxNumberOfSystems?: number;
    systemTemplates: SystemTemplates;
    systemDocuments: boolean;
    niS2ActivitiesCatalogue: StandardNIS2Activities;
    isoActivitiesCatalogue: boolean;
    maxNumberOfAssetRiskAssessments?: number;
    maxNumberOfNIS2RiskScenarios?: number;
    nis2RiskAssessmentConsequenceCatalogue: boolean;
    niS2RiskReports: boolean;
    systemRiskClassification: boolean;
    standardListExport: boolean;
    systemStatus: boolean;
    storageType: boolean;
    entityType: boolean;
    dataType: boolean;
    operationCriticalSystem: boolean;
    certifications: boolean;
    entityTypeFilter: boolean;
    associatedAssets: boolean;
    internalControlProcedures: boolean;
    personalData: boolean;
    automaticRegistrationOfDataProcessorTransfers: boolean;
    ai: boolean;
}

export interface VendorManagementFeatures {
    hasVendorManagement: boolean;
    maxNumberOfVendorRiskClassificationsPerYear?: number;
    maxNumberOfExternalOrInternalAuditsPerYear?: number;
    vendorScreeningAndOnboarding: boolean;
    auditGroups: boolean;
    vendorDocuments: boolean;
    maxNumberOfContactsOnUnit?: number;
    standardListsExport: boolean;
    certifications: boolean;
    processingActivityAssociateInternalAssessments: boolean;
    systemAssociateInternalAssessments: boolean;
    publicAssessments: boolean;
    systemClassifications: boolean;
}

export interface AddonFeatures {
    sso: boolean;
    customLists: boolean;
    organizationalManagement: boolean;
    research: boolean;
    declarations: boolean;
    userManagementPremium: boolean;
}

export enum StandardGDPRActivities {
    NoAccess = 0,
    MostPopular = 1,
    All = 2,
}

export enum StandardNIS2Activities {
    NoAccess = 0,
    MostPopular = 1,
    All = 2,
}

export enum ProcessingActivityTemplates {
    MostPopular,
    All,
}

export enum SystemTemplates {
    MostPopular,
    All,
}

export enum PlanType {
    GdprStarter = 0,
    GdprEssential = 1,
    GdprStandard = 2,
    GdprPro = 3,
    GdprResearch = 4,
    IsmsStarter = 100,
    IsmsStandard = 101,
    IsmsPro = 102,
}
