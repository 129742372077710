import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { get, post } from "../../common/api/apiShared";
import { useDotLegalSnackbar } from "@dotlegal/dotlegal-ui-components";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { useTranslation } from "../../localization/useTranslation";
import { SystemTemplateTypesEnum } from "../systemEdit/SystemEdit.types";
import { ISystemTemplasDialogProps } from "./SystemTemplatesDialog";
import { SystemTemplateViewModel } from "./SystemTemplatesDialog.types";
import { GetNumberOfSystems } from "../../auth/planProvider/PlanProvider.hooks";

export function useSystemTempaltesDialogHook(props: ISystemTemplasDialogProps) {
    const snackbar = useDotLegalSnackbar();
    const { translateString } = useTranslation();
    const [selectedTemplateType, setSelectedTemplateType] = useState("");
    const [numberOfSystems, setNumberOfSystems] = useState<number | undefined>(undefined);
    const [selectedTemplates, setSelectedTemplates] = useState<Array<string>>([]);
    const url = "/SystemTemplate";
    const { isLoading, data } = useQuery(url, () => get<Array<SystemTemplateViewModel>>(url));
    const numberOfExistingSystems = GetNumberOfSystems();

    let systemTypesData;
    let mostPopularTemplates;
    let systemTypeTemplates;

    useEffect(() => {
        setNumberOfSystems(numberOfExistingSystems);
    }, [numberOfExistingSystems]);

    if (data) {
        systemTypesData = Object.entries(SystemTemplateTypesEnum)
            .filter((q) => isNaN(Number(q[0])))
            .map((q) => {
                let templateCount = data?.filter((x) => x.templateTypes.indexOf(Number(q[1])) >= 0);
                return {
                    name: translateString(q[0].toLowerCase()),
                    id: q[1].toString(),
                    description: "",
                    isSelected: q[1].toString() === selectedTemplateType,
                    templateCount: calculateSystemTypeCount(q[1].toString()),
                    hasData: templateCount !== undefined && templateCount.length > 0,
                };
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));

        mostPopularTemplates = filterMostPopularActivities();
        systemTypeTemplates = filterSystemTypeTemplates();
    }

    const handleTemplateChange = (selected: boolean, templateId: string, templateIndex: number) => {
        if (selected) addTemplate(templateId);
        else removeTemplate(templateIndex);
    };

    const saveMutation = useMutation(save);
    const postTemplates = () => {
        saveMutation.mutateAsync(selectedTemplates!, {
            onSuccess: (response: any) => {
                props.onCloseDialog();
                props.showCompaniesExistingDialog(response.value().failedClonedLegalEntities);
                snackbar.show(translateString("systemTemplatesAddedSuccess"), "success");
            },
        });
    };

    return {
        isLoading,
        isSaving: saveMutation.isLoading,
        systemTypesData,
        systemTypeTemplates,
        mostPopularTemplates,
        setSelectedTemplateType,
        selectedTemplates,
        handleTemplateChange,
        addAllTemplatesClick,
        postTemplates,
        numberOfSystems,
    };

    function save(templateIds: Array<string>) {
        return post<string>("/SystemTemplate", templateIds);
    }

    function filterMostPopularActivities() {
        var tempSystems = [...data!];
        let pupolarTemplates = tempSystems.filter((x) => x.mostPopular === true);
        return pupolarTemplates;
    }

    function calculateSystemTypeCount(industryArea: string) {
        return data?.filter((x) => x.templateTypes.indexOf(Number(industryArea)) >= 0 && selectedTemplates.indexOf(x.id) >= 0).length;
    }

    function filterSystemTypeTemplates() {
        var tempTemplates = [...data!!];

        if (isNullOrWhitespace(selectedTemplateType) === false) {
            tempTemplates = tempTemplates.filter((x) => x.templateTypes.length > 0 && x.templateTypes.indexOf(Number(selectedTemplateType)) >= 0);
            return tempTemplates;
        }
        return [];
    }

    function removeTemplate(templateIndex: number) {
        var tempSelectedTemplates = [...selectedTemplates];
        tempSelectedTemplates.splice(templateIndex, 1);
        setSelectedTemplates(tempSelectedTemplates);

        let tempNumberOfSystems = numberOfSystems !== undefined ? numberOfSystems - 1 : 0;
        setNumberOfSystems(tempNumberOfSystems);
    }

    function addTemplate(templateId: string) {
        var tempSelectedTemplates = [...selectedTemplates];
        tempSelectedTemplates.push(templateId);
        setSelectedTemplates(tempSelectedTemplates);

        let tempNumberOfSystems = numberOfSystems !== undefined ? numberOfSystems + 1 : 0;
        setNumberOfSystems(tempNumberOfSystems);
    }

    function addAllTemplatesClick(templateIds: Array<string>) {
        if (templateIds) {
            var tempSelectedTemplates = [...selectedTemplates];
            templateIds.forEach((template) => {
                if (template && !selectedTemplates.includes(template)) {
                    tempSelectedTemplates.push(template);
                }
            });
            setSelectedTemplates(tempSelectedTemplates);
        }
    }
}
