import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { DotLegalCheckbox, DotLegalHeader, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import CloseIcon from "@mui/icons-material/Close";
import { AnnualWheelActivityTemplateSaveModel, AnnualWheelTemplateValidationModel } from "./AnnualWheelActivityTemplate.types";
import { useAnnualWheelDialogStyles } from "../annualWheelDialog/AnnualWheelDialog.styles";
import { useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { isTemplateCustomer } from "../../common/templateCustomerHelper";

export interface IAnnualWheelActivityTemplate {
    templateModel: AnnualWheelActivityTemplateSaveModel;
    setTemplateModel: (model: AnnualWheelActivityTemplateSaveModel) => void;
    validateName: (name: string) => void;
    validationModel: AnnualWheelTemplateValidationModel;
    templateInUse: boolean;
}

function AnnualWheelActivityTemplate(props: IAnnualWheelActivityTemplate) {
    const { translateString } = useTranslation();
    const isUseOnSmallScreen = useIsOnSmallScreen();
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);

    return (
        <Box sx={styles.formSpacing}>
            <DotLegalHeader headerStyle="small" marginTop={2} marginBottom={0.5}>
                {translateString("createActivityName")}
            </DotLegalHeader>

            <DotLegalTextField
                noMargin
                debounce={false}
                label=""
                value={props.templateModel.name}
                onChange={(name) => {
                    let tempModel = { ...props.templateModel };
                    tempModel.name = name;

                    props.validateName(tempModel.name);
                    props.setTemplateModel(tempModel);
                }}
                errorText={props.validationModel.name}
            />
            <DotLegalHeader headerStyle="small" marginTop={4} marginBottom={0.5}>
                {translateString("createActivityDescription")}
            </DotLegalHeader>
            <DotLegalTextField
                noMargin
                multiline
                rows={5}
                debounce
                label=""
                value={props.templateModel.description}
                onChange={(description) => {
                    let tempModel = { ...props.templateModel };
                    tempModel.description = description;
                    props.setTemplateModel(tempModel);
                }}
            />

            <DotLegalHeader marginBottom={0.5} marginTop={4} headerStyle="small">
                {translateString("createActivitySubTasks")}
            </DotLegalHeader>
            <Typography
                sx={(theme) => ({
                    color: theme.palette.primary.main,
                    display: "inline-block",
                    fontSize: theme.typography.pxToRem(12),
                    "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                    },
                })}
                onClick={() => {
                    let tempModel = { ...props.templateModel };
                    tempModel.subTasks.push({ name: "", id: "" });
                    props.setTemplateModel(tempModel);
                }}
            >
                + {translateString("addSubTask")}
            </Typography>
            {props.templateModel.subTasks.map((x, index) => {
                return (
                    <Box key={index} sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ width: "100%" }}>
                            <DotLegalTextField
                                noMargin
                                variant="standard"
                                key={index}
                                debounce={false}
                                label=""
                                onChange={(value) => {
                                    let tempModel = { ...props.templateModel };
                                    tempModel.subTasks[index] = { name: value, id: x.id };
                                    props.setTemplateModel(tempModel);
                                }}
                                value={x.name}
                                alwaysShowUnderline
                            />
                        </Box>
                        <IconButton
                            size="small"
                            onClick={() => {
                                let tempModel = { ...props.templateModel };
                                tempModel.subTasks.splice(index, 1);
                                props.setTemplateModel(tempModel);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                );
            })}
            {!props.templateInUse && (
                <>
                    <DotLegalHeader marginBottom={0.5} marginTop={4} headerStyle="small" toolTip={translateString("planAnnualWheelActivityImmediatelyDesc")}>
                        {translateString("planAnnualWheelActivityImmediately")}
                    </DotLegalHeader>

                    <Box sx={(theme) => ({ display: "flex", alignItems: "center", marginLeft: "-11px" })}>
                        <DotLegalCheckbox
                            onChange={(checked) => {
                                let tempModel = { ...props.templateModel };
                                tempModel.planActivity = checked;
                                props.setTemplateModel(tempModel);
                            }}
                            label={translateString("planNow")}
                            checked={props.templateModel.planActivity}
                        ></DotLegalCheckbox>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default AnnualWheelActivityTemplate;
