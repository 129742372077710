import React from "react";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";
import UpgradeOrTrialDialog from "../upgradeOrTrialDialog/UpgradeOrTrialDialog";
import UpgradePlanDialog from "../upgradePlanDialog/UpgradePlanDialog";
import { useNavigate } from "react-router-dom";
import { useUrlProvider } from "../../useUrlProvider";

function DeepLinkUpgradeDialog() {
    const { data } = useTrialInformationHook();
    const navigate = useNavigate();
    const { getProcessingActivitiesOverviewUrl } = useUrlProvider();

    if (data?.isFullyUpgraded) {
        navigate(getProcessingActivitiesOverviewUrl());
    }

    return (
        <React.Fragment>
            {data?.isFullyUpgraded === false &&
                (data?.canTrialNextPlan ? (
                    <UpgradeOrTrialDialog onDialogClose={() => navigate(getProcessingActivitiesOverviewUrl())} showDialog />
                ) : (
                    <UpgradePlanDialog onDialogClose={() => navigate(getProcessingActivitiesOverviewUrl())} showDialog />
                ))}
        </React.Fragment>
    );
}

export default DeepLinkUpgradeDialog;
