import React from "react";
import { Box } from "@mui/material";
import UpgradePlanDialog from "../upgradePlanDialog/UpgradePlanDialog";
import { usePlanDialogButtonWrapperHooks } from "./planDialogButtonWrapper.hooks";
import UpgradeOrTrialDialog from "../upgradeOrTrialDialog/UpgradeOrTrialDialog";
import { useTrialInformationHook } from "../../trial/TrialInformation.hooks";

interface IPlanDialogButtonWrapper {
    enabled: boolean;
    children: any;
}

function PlanDialogButtonWrapper(props: IPlanDialogButtonWrapper) {
    const { showDialog, setShowDialog } = usePlanDialogButtonWrapperHooks();
    const { data } = useTrialInformationHook();

    return (
        <React.Fragment>
            {props.enabled ? (
                <Box
                    sx={{
                        "&:hover": { cursor: "pointer !important" },
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDialog(true);
                    }}
                >
                    {props.children}
                </Box>
            ) : (
                props.children
            )}

            {showDialog &&
                (data?.canTrialNextPlan ? (
                    <UpgradeOrTrialDialog onDialogClose={() => setShowDialog(false)} showDialog={showDialog} />
                ) : (
                    <UpgradePlanDialog onDialogClose={() => setShowDialog(false)} showDialog={showDialog} />
                ))}
        </React.Fragment>
    );
}

export default PlanDialogButtonWrapper;
