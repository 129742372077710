import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import React, { createRef, useLayoutEffect, useState } from "react";
import { useDotLegalInformationBoxStyles } from "./DotLegalImformationBox.styles";
import { isNullOrWhitespace } from "../../stringOperations";
import { blueCheck, informationIcon } from "../../icons";
import { DotLegalInformationTooltip, DotLegalOverflowMenu, DotLegalOverflowMenuItem, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";

export interface DotLegalInformationBoxProps {
    isLoading?: boolean;
    header?: string;
    description?: string;
    onChange?: (isSelected: boolean) => void;
    isSelected: "selected" | "secondarySelected" | "unselected";
    size: "large" | "small";
    isDisabled?: boolean;
    fullWidth?: boolean;
    disabledTooltip?: string;
    onDisabledTooltipClick?: () => void;
    overFlowMenuItems?: Array<DotLegalOverflowMenuItem>;
}

export default function DotLegalInformationBox(props: DotLegalInformationBoxProps) {
    const styles = useDotLegalInformationBoxStyles(props);
    const ref: React.RefObject<HTMLDivElement> = createRef();
    const headerRef: React.RefObject<HTMLDivElement> = createRef();
    const [showTooltip, setShowTooltip] = useState(true);
    const [showHeaderTooltip, setShowHeaderTooltip] = useState(false);

    useLayoutEffect(() => {
        if (ref?.current) {
            if (ref.current!.offsetHeight < ref.current!.scrollHeight) {
                setShowTooltip(true);
            } else {
                setShowTooltip(false);
            }
        }

        if (headerRef?.current) {
            if (headerRef.current!.offsetWidth < headerRef.current!.scrollWidth && props.size === "small") {
                setShowHeaderTooltip(true);
            } else {
                setShowHeaderTooltip(false);
            }
        }
    }, [ref, headerRef, props.size]);

    const handleOnChange = () => {
        if (props.isDisabled) return;

        if (props.onChange) {
            if (props.isSelected === "selected") return props.onChange(false);
            else return props.onChange(true);
        }
    };

    const getBeforeHeaderData = () => {
        if (props.disabledTooltip && props.onDisabledTooltipClick) {
            return (
                <Box sx={[styles.logo, { "&:hover": { cursor: "pointer" } }]} onClick={props.onDisabledTooltipClick}>
                    <DotLegalInformationTooltip text={props.disabledTooltip} />
                </Box>
            );
        }
        if (props.isSelected === "selected") {
            return <Box sx={styles.logo}>{blueCheck}</Box>;
        }

        return <></>;
    };

    return (
        <Card sx={styles.informationBox} variant="outlined" onClick={() => handleOnChange()}>
            <CardContent>
                <Box sx={styles.wrapper}>
                    <Box sx={styles.headerAndCheck}>
                        {getBeforeHeaderData()}
                        <DotLegalTooltip text={props.header} disableHoverlistener={!showHeaderTooltip}>
                            <Typography ref={headerRef} sx={styles.header}>
                                {props.isLoading ? <Skeleton /> : props.header}
                            </Typography>
                        </DotLegalTooltip>
                    </Box>
                    {props.size === "large" ? (
                        <DotLegalTooltip text={props.description ? props.description : ""} disableHoverlistener={!showTooltip}>
                            <>
                                <Typography ref={ref} sx={styles.description}>
                                    {props.isLoading ? <Skeleton /> : props.description}
                                </Typography>
                                {props.overFlowMenuItems && (
                                    <Box sx={{ position: "absolute", right: 0, bottom: 0 }}>
                                        <DotLegalOverflowMenu color={"secondary"} items={props.overFlowMenuItems!} />
                                    </Box>
                                )}
                            </>
                        </DotLegalTooltip>
                    ) : (
                        <>
                            {props.overFlowMenuItems ? (
                                <Box sx={{ mt: -1, mb: -1, mr: -1 }}>
                                    <DotLegalOverflowMenu color={"secondary"} items={props.overFlowMenuItems!} />
                                </Box>
                            ) : (
                                !isNullOrWhitespace(props.description) && (
                                    <DotLegalTooltip text={props.description ? props.description : ""}>
                                        <Box sx={styles.icon}>{informationIcon}</Box>
                                    </DotLegalTooltip>
                                )
                            )}
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}
