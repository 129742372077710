import { DotLegalHeader, DotLegalOverflowMenu, DotLegalOverflowMenuItem, DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { TaskStateEnum } from "../AnnualWheel.types";
import AnnualWheelTaskDeleted from "./AnnualWheelTaskDeleted.svg?react";
import { Box } from "@mui/material";
import { RepetitionIcon } from "../../common/icons";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { getTaskColor } from "../../taskManagement/task/Task.styles";
import ComplianceAreaWrapper from "../../common/components/complianceAreaWrapper/ComplianceAreaWrapper";

export const annualWheelTaskCardHeight = 105;
export const annualWheelTaskCardMargin = 8;

export interface IAnnualWheelTaskProps {
    name: string;
    status: TaskStateEnum;
    deleted: boolean;
    areas: Array<SelectableItem>;
    onEditClick: () => void;
    onDeleteClick: () => void;
    onNavigateToTask: () => void;
    isPdf?: boolean;
    recurrenceInterval?: number;
    customId: string | null;
}

function AnnualWheelTask(props: IAnnualWheelTaskProps) {
    const { translateString } = useTranslation();
    const { permissions } = useUserContext();
    const { platformFeatures } = usePlanContext();

    const getMenuItems = (): DotLegalOverflowMenuItem[] => {
        if (props.deleted && permissions.annualWheelPermission.delete) {
            return [
                {
                    menuItem: translateString("delete"),
                    onClick: () => props.onDeleteClick(),
                },
            ];
        }

        let menuItems: DotLegalOverflowMenuItem[] = [];
        if (permissions.taskPermissions.read) {
            menuItems.push({
                menuItem: translateString("viewInTaskManagement"),
                onClick: () => props.onNavigateToTask(),
            });
        }

        if (permissions.annualWheelPermission.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    props.onEditClick();
                },
            });
        }

        if (permissions.annualWheelPermission.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => props.onDeleteClick(),
            });
        }

        return menuItems;
    };

    const showOverFlowMenu = () => {
        if (props.deleted) {
            return permissions.annualWheelPermission.delete;
        }

        return permissions.annualWheelPermission.edit || permissions.annualWheelPermission.delete || permissions.taskPermissions.read;
    };

    return (
        <Box
            className={`avoid-page-break`}
            sx={(theme) => ({
                marginTop: `${annualWheelTaskCardMargin}px`,
                marginBottom: `${annualWheelTaskCardMargin}px`,
                width: "100%",
                height: props.isPdf ? "100%" : undefined,
                "& .MuiPaper-root": {
                    height: props.isPdf ? "auto" : annualWheelTaskCardHeight,
                },
            })}
        >
            <DotLegalPaper>
                <Box
                    sx={(_) => ({
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        minHeight: "55px",
                    })}
                >
                    <Box
                        sx={(theme) => ({
                            backgroundColor: getTaskColor(props.status, theme),
                            marginRight: theme.spacing(2),
                            borderRadius: "12px",
                            paddingRight: "6px",
                        })}
                    />

                    <Box className="tester" sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                            <Box>
                                <DotLegalHeader fontWeight={500} headerStyle={"small"} marginBottom={0} maxNoLines={props.isPdf ? undefined : 1}>
                                    {props.customId && <span style={{ marginRight: 10, fontWeight: "bold" }}>{props.customId}</span>}
                                    {props.name}
                                </DotLegalHeader>
                            </Box>

                            {platformFeatures.annualWheelComplianceArea && (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    {props.areas.map((x) => (
                                        <ComplianceAreaWrapper key={x.id} complianceArea={x.name} />
                                    ))}
                                </Box>
                            )}

                            {props.recurrenceInterval && (
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", marginRight: "4px" }}>{RepetitionIcon}</Box>
                                    <DotLegalHeader fontWeight={500} headerStyle={"small"} marginBottom={0} center>
                                        {props.recurrenceInterval === 1
                                            ? translateString("everyMonth")
                                            : translateString("recurrenceInterval", { interval: props.recurrenceInterval })}
                                    </DotLegalHeader>
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                justifySelf: "flex-end",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column-reverse", justifyContent: "space-between" }}>
                                {showOverFlowMenu() && (
                                    <Box
                                        sx={(_) => ({
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "36px",
                                            "& .MuiButtonBase-root": {
                                                marginBottom: -1.2,
                                            },
                                        })}
                                    >
                                        <DotLegalOverflowMenu items={getMenuItems()} />
                                    </Box>
                                )}
                                {props.deleted && (
                                    <DotLegalTooltip text={translateString("annualActivityIsDeleted")}>
                                        <Box
                                            sx={(_) => ({
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                width: "100%",
                                            })}
                                        >
                                            <Box
                                                sx={(_) => ({
                                                    display: "flex",
                                                    height: "100%",
                                                    width: "36px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                })}
                                            >
                                                <AnnualWheelTaskDeleted />
                                            </Box>
                                        </Box>
                                    </DotLegalTooltip>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DotLegalPaper>
        </Box>
    );
}

export default AnnualWheelTask;
