import { PlanType } from "../../auth/planProvider/PlanProvider.types";
import { LoginProvider } from "../../user/User.types";

export interface CustomerListModel {
    id: string;
    name: string;
    language: string;
    active: boolean;
    loginProvider: LoginProvider;
    created: Date;
    endDate: Date | null;
    customerType: CustomerType;
    additionalProcessingActivityReports: boolean;
    helpLink: string;
    planType: PlanType;
    reportTypes: Array<number>;
    germanEnabled: boolean;
}

export enum CustomerType {
    Demo = 0,
    Live = 1,
    Trial = 2,
}
