import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../localization/useTranslation";
import { useSettingStyles } from "./Settings.styles";
import { DotLegalButton, DotLegalPageHeader, useDotLegalSnackbar, useIsBetweenSize, useIsOnExtraSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useUrlProvider } from "../useUrlProvider";
import usersIcon from "../user/users.svg?url";
import rolesIcon from "../roles/roles.svg?url";
import masterDataIcon from "../masterData/masterData.svg?url";
import customerIcon from "../customer/customer.svg?url";
import auditsIcon from "../common/svgs/audits.svg?url";
import downloadIcon from "../common/svgs/download.svg?url";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { UserPermissions } from "../auth/userContextProvider/UserContextProvider.types";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { WhiteLockIcon } from "../common/icons";
import PlanDialogButtonWrapper from "../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { useCustomListExport } from "../customLists/customListView/useCustomListExport.ts";

function Settings() {
    const customListExport = useCustomListExport();
    const { translateString } = useTranslation();
    const styles = useSettingStyles();
    const urlProvider = useUrlProvider();
    let isExtraSmallScreen = useIsOnExtraSmallScreen();
    let shouldDisplayButtonsAsColumn = useIsBetweenSize(0, 1750);
    const dotLegalSnackbar = useDotLegalSnackbar();

    const { permissions, customerName } = useUserContext();
    const { addonsFeatures, hasVendorManagementAccess } = usePlanContext();
    const navigate = useNavigate();

    if (!hasSettingsAccess(permissions)) {
        navigate(urlProvider.getForbiddenUrl());
    }

    const getPaperStyles = (backgroundImageUrl: string) => {
        if (isExtraSmallScreen) {
            return { minHeight: 245 + "px", display: "flex", padding: 4 };
        }
        return {
            minHeight: 245 + "px",
            display: "flex",
            padding: 4,
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "98% 100%",
            backgroundSize: "300px",
        };
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={[{ name: translateString("settings") }]} />
            <Grid container spacing={4}>
                {permissions.canManageUsers && (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DotLegalPaper sx={getPaperStyles(usersIcon)}>
                            <Grid item md={8} sx={styles.paperInfoContainer}>
                                <div>
                                    <Typography sx={styles.header}>{translateString("manageUsers").toUpperCase()}</Typography>
                                    <Typography sx={styles.bodyText}>{translateString("manageUsersBodyText")}</Typography>
                                </div>
                                <div>
                                    <DotLegalButton btnLength={200} href={urlProvider.getUsersUrl()} buttonType="primary" size="medium">
                                        {translateString("goToUsers")}
                                    </DotLegalButton>
                                </div>
                            </Grid>
                        </DotLegalPaper>
                    </Grid>
                )}
                {permissions.canManageCustomers && (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DotLegalPaper sx={getPaperStyles(customerIcon)}>
                            <Grid item md={8} sx={styles.paperInfoContainer}>
                                <div>
                                    <Typography sx={styles.header}>{translateString("manageCustomers").toUpperCase()}</Typography>
                                    <Typography sx={styles.bodyText}>{translateString("manageCustomerInfo")}</Typography>
                                </div>
                                <div>
                                    <DotLegalButton btnLength={200} href={urlProvider.getCustomersUrl()} buttonType="primary">
                                        {translateString("goToCustomers")}
                                    </DotLegalButton>
                                </div>
                            </Grid>
                        </DotLegalPaper>
                    </Grid>
                )}
                {permissions.canManageMasterData && (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DotLegalPaper sx={getPaperStyles(masterDataIcon)}>
                            <Grid item md={8} sx={styles.paperInfoContainer}>
                                <div>
                                    <Typography sx={styles.header}>{translateString("manageMasterData").toUpperCase()}</Typography>
                                    <Typography sx={styles.bodyText}>{translateString("manageMasterDataBody")}</Typography>
                                </div>
                                <div>
                                    <DotLegalButton btnLength={200} href={urlProvider.getMasterDataUrl()} buttonType="primary">
                                        {translateString("goToMasterData")}
                                    </DotLegalButton>
                                </div>
                            </Grid>
                        </DotLegalPaper>
                    </Grid>
                )}
                {permissions.canManageRoles && (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DotLegalPaper sx={getPaperStyles(rolesIcon)}>
                            <Grid item md={8} sx={styles.paperInfoContainer}>
                                <div>
                                    <Typography sx={styles.header}>{translateString("manageRoles").toUpperCase()}</Typography>
                                    <Typography sx={styles.bodyText}>{translateString("manageRolesInfo")}</Typography>
                                </div>
                                <div>
                                    <PlanDialogButtonWrapper enabled={!addonsFeatures.userManagementPremium}>
                                        <DotLegalButton
                                            btnLength={200}
                                            href={urlProvider.getUserRolesUrl()}
                                            buttonType="primary"
                                            disabled={!addonsFeatures.userManagementPremium}
                                            toolTip={!addonsFeatures.userManagementPremium ? translateString("upgradePrivacyPlanToday") : undefined}
                                            endIcon={!addonsFeatures.userManagementPremium ? WhiteLockIcon : undefined}
                                        >
                                            {translateString("goToRoles")}
                                        </DotLegalButton>
                                    </PlanDialogButtonWrapper>
                                </div>
                            </Grid>
                        </DotLegalPaper>
                    </Grid>
                )}

                {hasVendorManagementAccess && (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DotLegalPaper sx={[getPaperStyles(auditsIcon), { backgroundSize: "250px" }]}>
                            <Grid item sm={8} md={8} sx={[styles.paperInfoContainer, { gap: 2 }]}>
                                <Box>
                                    <Typography sx={styles.header}>{translateString("auditsAndVenderManagement").toUpperCase()}</Typography>
                                    <Typography sx={styles.bodyText}>{translateString("manageAuditAndVendorsDescription")}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: shouldDisplayButtonsAsColumn ? "column" : "row",
                                        gap: 1,
                                        "& a": {
                                            height: "100%",
                                            textAlign: "center",
                                        },
                                    }}
                                >
                                    <DotLegalButton btnLength={230} href={urlProvider.getAuditsUrl()} buttonType="primary">
                                        {translateString("goToAudits")}
                                    </DotLegalButton>
                                    <DotLegalButton btnLength={230} href={urlProvider.getVendorClassifications()} buttonType="primary">
                                        {translateString("goToVendorClassifications")}
                                    </DotLegalButton>
                                </Box>
                            </Grid>
                        </DotLegalPaper>
                    </Grid>
                )}

                {addonsFeatures.customLists && permissions.customListPermissions.read && (
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DotLegalPaper sx={getPaperStyles(downloadIcon)}>
                            <Grid item md={8} sx={styles.paperInfoContainer}>
                                <div>
                                    <Typography sx={styles.header}>{translateString("fullDataExportHeader").toUpperCase()}</Typography>
                                    <Typography sx={styles.bodyText}>{translateString("fullDataExportDescription")}</Typography>
                                </div>
                                <div>
                                    <DotLegalButton
                                        btnLength={200}
                                        buttonType={"primary"}
                                        onClick={() => {
                                            customListExport.exportAllDataInBackground();
                                            dotLegalSnackbar.show(translateString("fullDataExportInProgress"), "success", true);
                                        }}
                                    >
                                        {translateString("export")}
                                    </DotLegalButton>
                                </div>
                            </Grid>
                        </DotLegalPaper>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default Settings;

export const hasSettingsAccess = (permissions: UserPermissions) => {
    return (
        permissions.canManageCustomers ||
        permissions.canManageGroupEntities ||
        permissions.canManageUsers ||
        permissions.canManageRoles ||
        permissions.canManageMasterData
    );
};
