import React from "react";
import {
    completedTaskCountColor,
    headerBorderRadius,
    overDueTaskCountColor,
    plannedTaskCountColor,
    totalTaskCounterColor,
    useAnnualWheelStyles,
} from "./AnnualWheel.styles";
import { useAnnualWheelDataMapping } from "./AnnualWheel.hooks";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { AnnualWheelMonthEnum } from "./AnnualWheel.types";
import { DotLegalChip, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import AnnualWheelTask from "./annualWheelTask/AnnualWheelTask";
import { useTranslation } from "../localization/useTranslation";
import { Box, Grid, Skeleton } from "@mui/material";
import overdueTasksWave from "./overdueTasksWave.svg?url";
import plannedTasksWave from "./plannedTasksWave.svg?url";
import completedTasksWave from "./completedTasksWave.svg?url";
import totalTasksWave from "./totalTasksWave.svg?url";

export interface IAnnualWheelPDFProps {}

function AnnualWheelPDF(props: IAnnualWheelPDFProps) {
    const styles = useAnnualWheelStyles();
    const { translateString } = useTranslation();
    const { isLoading, data, pdfYear, pdfCustomer } = useAnnualWheelDataMapping();

    const getColumnHeader = (month: string, monthEnum: AnnualWheelMonthEnum) => {
        return (
            <Box
                sx={{
                    borderRadius: headerBorderRadius,
                    ...styles.calenderColumnHeader,
                }}
            >
                <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                    {translateString(month.toLowerCase())}
                </DotLegalHeader>
            </Box>
        );
    };

    const getTasks = (month: AnnualWheelMonthEnum) => {
        if (!isLoading) {
            return data?.annualWheelActivities
                .filter((x) => x.month === month)
                .map((task) => {
                    return (
                        <div key={task.id} style={{ minHeight: "153px", height: "1px", width: "25%", display: "inline-block", padding: 8 }}>
                            <AnnualWheelTask
                                name={task.name}
                                status={task.taskStatus}
                                deleted={task.deleted}
                                onEditClick={() => {}}
                                onDeleteClick={() => {}}
                                onNavigateToTask={() => {}}
                                isPdf
                                areas={task.areas}
                                customId={task.customId}
                            />
                        </div>
                    );
                });
        }
    };

    return (
        <>
            <DotLegalHeader headerStyle="extraLarge">{translateString("annualWheelPDFHeader", { year: pdfYear, company: pdfCustomer })}</DotLegalHeader>

            <Grid container spacing={2} sx={styles.taskCounterContainer}>
                <Grid item xs={3}>
                    <DotLegalPaper sx={{ backgroundImage: `url("${overdueTasksWave}")`, ...styles.taskCounterPaper }}>
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("overdueActivities")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip alternativeColor={overDueTaskCountColor()} value={data!.overdueTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={3}>
                    <DotLegalPaper sx={{ backgroundImage: `url("${plannedTasksWave}")`, ...styles.taskCounterPaper }}>
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("plannedActivities")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip alternativeColor={plannedTaskCountColor()} value={data!.plannedTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={3}>
                    <DotLegalPaper sx={{ backgroundImage: `url("${completedTasksWave}")`, ...styles.taskCounterPaper }}>
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("completedActivities")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip alternativeColor={completedTaskCountColor()} value={data!.completedTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={3}>
                    <DotLegalPaper sx={{ backgroundImage: `url("${totalTasksWave}")`, ...styles.taskCounterPaper }}>
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("totalTasksForYear")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip isSelected alternativeColor={totalTaskCounterColor()} value={data!.totalTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip isSelected alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
            </Grid>

            {Object.entries(AnnualWheelMonthEnum)
                .filter((q) => isNaN(Number(q[0])))
                .map((month, _) => {
                    const monthEnum = month[1] as AnnualWheelMonthEnum;
                    return (
                        <DotLegalPaper sx={{ marginTop: 16 }} className={" avoid-page-break"} key={monthEnum}>
                            {getColumnHeader(month[0], monthEnum)}
                            <div style={{ display: "table", width: "100%" }}>{getTasks(monthEnum)}</div>
                        </DotLegalPaper>
                    );
                })}
        </>
    );
}

export default AnnualWheelPDF;
