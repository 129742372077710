import React, { MouseEventHandler } from "react";
import { useDotLegalPaperStyles } from "./DotLegalPaper.styles";
import { Paper, SxProps, Theme } from "@mui/material";

export type backgroundWaves = "blueWave" | "purpleWave" | "greenWave" | "purpleWave1" | "smallPurpleWave";
export type backgroundColors = "lightGreen" | "lightGrey" | "lightBlue" | "darkLightBlue" | "lightPurple" | "orange" | "limeGreen" | "darkGreen" | "pink";

export interface IDotLegalPaperProps {
    children: any;
    /**
     * @deprecated Please don't use this prop if possible. In the long term it should be removed.
     */
    className?: string;
    background?: backgroundWaves;
    backgroundCover?: boolean;
    backgroundColor?: backgroundColors;
    onClick?: () => void;
    clickableRows?: boolean;
    fullHeight?: boolean;
    removePadding?: boolean;
    sx?: SxProps<Theme>;
    onMouseEnter?: MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: MouseEventHandler<HTMLDivElement>;
}

function DotLegalPaper(props: IDotLegalPaperProps) {
    const styles = useDotLegalPaperStyles(props);
    return (
        <Paper
            elevation={0}
            className={props.className}
            sx={[styles.root(), styles.paperBackground, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
            onClick={props.clickableRows ? props.onClick : undefined}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            {props.children}
        </Paper>
    );
}

export default DotLegalPaper;
