import { Box } from "@mui/material";
import React from "react";
import { DotLegalDialog, DotLegalSearchField, DotLegalSelect, DotLegalTooltip, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { useAnnualWheelDialogDataMapping } from "./AnnualWheelDialog.hooks";
import { useAnnualWheelDialogStyles } from "./AnnualWheelDialog.styles";
import { isNullOrWhitespace } from "../../common/stringOperations";
import { arrow, leftWhiteArrowButton } from "../../common/icons";
import { AnnualWheelMonthEnum, DialogStep } from "../AnnualWheel.types";

import DotLegalTabs from "../../common/components/dotLegalTabs/DotLegalTabs";
import AnnualWheelActivityTemplate from "../AnnualWheelActivityTemplate/AnnualWheelActivityTemplate";
import DeleteAnnualWheelActivityTemplateDialog from "../deleteAnnualWheelActivityTemplateDialog/DeleteAnnualWheelActivityTemplateDialog";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import AnnualWheelResponsibleForm from "./annualWheelResponsibleForm/AnnualWheelResponsibleForm";
import AnnualWheelDurationForm from "./annualWheelDurationForm/AnnualWheelDurationForm";
import { TabContent } from "../../common/components/dotLegalTabs/DotLegalTabs.types";
import Nis2Templates from "./templates/nis2Templates/Nis2Templates";
import GdprTemplates from "./templates/gdprTemplates/GdprTemplates";
import CustomTemplates from "./templates/customTemplates/CustomTemplates";
import AnnaulWheelBaseInformation from "./annualWheelBaseInformationForm/AnnualWheelBaseInformationForm";
import { AnnualWheelActivityType, AnnualWheelFilterOption } from "./AnnualWheelDialog.types";
import PurpleLockSvg from "../../common/svgs/purpleLock.svg?react";
import ISOTemplates from "./templates/isoTemplates/IsoTemplates";
import ISO27002Templates from "./templates/isoTemplates/iso27002Templates/Iso27002Templates";
import { getPlanTab } from "../../plan/planTab/PlanTab";
import { PlanType } from "../../auth/planProvider/PlanProvider.types";

export interface IAnnualWheelDialog {
    setShowDialog: (show: boolean) => void;
    onSave: () => void;
    selectedYear?: number;
    selectedMonth?: AnnualWheelMonthEnum;
    activityId?: string;
    activityName: string | undefined;
    onDialogClose: () => void;
    templateId: string | undefined;
}

function AnnualWheelDialog(props: IAnnualWheelDialog) {
    const { translateString } = useTranslation();
    const {
        isLoading,
        inProgress,
        selectedActivity,
        setSelectedActivity,
        dialogStep,
        setDialogStep,
        searchInput,
        setSearchInput,
        gdprActivities,
        selectedTab,
        onChangeTab,
        customActiviites,
        refetch,
        hasCustomActivities,
        showDeleteTemplateDialog,
        setShowDeleteTemplateDialog,
        annualWheelResponsibleFormModel,
        setAnnualWheelResponsibleFormModel,
        isFinalStep,
        onOkClick,
        okButtonText,
        previousButtonText,
        dialogHeaderText,
        onPreviousButtonClick,
        setTemplateModel,
        templateModel,
        validateName,
        templateValidation,
        annualWheelDurationFormModel,
        setAnnualWheelDurationFormModel,
        showFormError,
        isCustomActivity,
        copyTemplate,
        nis2Templates,
        isoTemplates,
        onSelectActivity,
        annualWheelBaseInformationFormModel,
        setAnnualWheelBaseInformationFormModel,
        selectedFilterOption,
        setSelectedFilterOption,
        onCopyClick,
    } = useAnnualWheelDialogDataMapping(props);

    const isUseOnSmallScreen = useIsOnSmallScreen();
    const styles = useAnnualWheelDialogStyles(isUseOnSmallScreen);
    const { informationSecurityFeatures, platformFeatures, oldIsmsPlanType } = usePlanContext();

    const getStepForm = () => {
        switch (dialogStep) {
            case DialogStep.ActivityDurationData:
                return getDurationForm();
            case DialogStep.CustomActivityEditFrom:
                return getEditActiveTemplateForm();
            case DialogStep.ActivityResponsibleData:
                return getResponsibleForm();
            case DialogStep.CreateTemplate:
                return getCreateActivityForm();
            case DialogStep.ActivityBaseInformation:
                return getBaseInformationForm();
            default:
                return getDialogTabs();
        }
    };

    const getBaseInformationForm = () => {
        return (
            <AnnaulWheelBaseInformation
                saveModel={annualWheelBaseInformationFormModel}
                isProcessingActivityValidationActivity={selectedActivity === AnnualWheelActivityType.ProcessingActivityValidation.toString()}
                onChange={(model) => setAnnualWheelBaseInformationFormModel(model)}
                activityId={props.activityId}
            />
        );
    };

    const getResponsibleForm = () => {
        return (
            <AnnualWheelResponsibleForm
                onChange={(model) => setAnnualWheelResponsibleFormModel(model)}
                saveModel={annualWheelResponsibleFormModel}
                showError={showFormError}
            />
        );
    };

    const getDurationForm = () => {
        return (
            <AnnualWheelDurationForm
                saveModel={annualWheelDurationFormModel}
                onChange={(model) => setAnnualWheelDurationFormModel(model)}
                showError={showFormError}
                isCustomActivity={isCustomActivity()}
                isEdit={!isNullOrWhitespace(props.activityId)}
            />
        );
    };

    const getEditActiveTemplateForm = () => {
        return (
            <AnnualWheelActivityTemplate
                setTemplateModel={setTemplateModel}
                templateModel={templateModel}
                validateName={validateName}
                validationModel={templateValidation}
                templateInUse
            />
        );
    };

    const getCreateActivityForm = () => {
        return (
            <AnnualWheelActivityTemplate
                setTemplateModel={setTemplateModel}
                templateModel={templateModel}
                validateName={validateName}
                validationModel={templateValidation}
                templateInUse={false}
            />
        );
    };

    function getSearchField() {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {dialogStep === DialogStep.ActivitySelection && (
                    <DotLegalSearchField labelSearch={translateString("search")} useFullWidth={false} value={searchInput} onChange={setSearchInput} />
                )}
            </div>
        );
    }

    function getDialogTabs() {
        return (
            <DotLegalTabs
                tabStyle={"secondary"}
                selectedTab={selectedTab}
                setSelectedTab={(x) => onChangeTab(x)}
                content={getTabsContent()}
                fullWidth
                ignoreTextTransformation
            ></DotLegalTabs>
        );
    }

    function getGDPRContent() {
        return (
            <GdprTemplates
                templates={gdprActivities}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
                onCopyClick={onCopyClick}
            />
        );
    }

    function getISO27001Content() {
        return (
            <ISOTemplates
                templates={isoTemplates.iso27001Templates}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
                onCopyClick={onCopyClick}
            />
        );
    }

    function getISO27002Content() {
        return (
            <ISO27002Templates
                iso27002Templates={isoTemplates.iso27002Templates}
                iso27002_2013Templates={isoTemplates.iso27002_2013Templates}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
                onCopyClick={onCopyClick}
            />
        );
    }

    function getNIS2Content() {
        return (
            <Nis2Templates
                templateContainer={nis2Templates}
                selectedActivity={selectedActivity}
                additionalFilterField={getFilterField()}
                searchField={<DotLegalSearchField labelSearch={translateString("search")} useFullWidth={false} value={searchInput} onChange={setSearchInput} />}
                onSelectActivity={onSelectActivity}
                onCopyClick={onCopyClick}
            />
        );
    }

    function getCustomContent() {
        return (
            <CustomTemplates
                templates={customActiviites}
                onSelectActivity={onSelectActivity}
                searchField={getSearchField()}
                onCopyClick={(activity) => copyTemplate({ id: activity.id, name: activity.name })}
                onDeleteClick={(activtyId) => {
                    setSelectedActivity(activtyId);
                    setShowDeleteTemplateDialog(true);
                }}
                onEditClick={(activity) => {
                    setSelectedActivity("");
                    setDialogStep(DialogStep.CreateTemplate);
                    setTemplateModel({
                        name: activity.name,
                        description: activity.description,
                        subTasks: activity.subTasks,
                        planActivity: true,
                        id: activity.id,
                    });
                }}
                hasCustomTemplates={hasCustomActivities}
                selectedActivity={selectedActivity}
                onCreateClick={() => {
                    setSelectedActivity("");
                    setDialogStep(DialogStep.CreateTemplate);
                    setTemplateModel({ id: "", description: "", name: "", planActivity: true, subTasks: [] });
                }}
                additionalFilterField={getFilterField()}
            />
        );
    }

    function getFilterField() {
        return (
            <DotLegalSelect
                selectedItem={selectedFilterOption}
                options={[
                    { id: AnnualWheelFilterOption.All.toString(), name: translateString("all") },
                    { id: AnnualWheelFilterOption.Planned.toString(), name: translateString("planned") },
                    { id: AnnualWheelFilterOption.NotPlanned.toString(), name: translateString("notScheduled") },
                ]}
                onChange={(item) => setSelectedFilterOption(item!)}
                placeholder={""}
                label={""}
                noOptionsLabel={""}
                disableClearable
                noMargin
                width={"200px"}
            />
        );
    }

    function getTabsContent() {
        var tabIndex = 0;
        var content = Array<TabContent>();

        content.push({
            content: getGDPRContent(),
            index: tabIndex,
            label: translateString("gdrpActvitities"),
        });

        //TODO NID - OLD ISMS
        content.push(
            getPlanTab(translateString("nis2Activtities"), getNIS2Content(), ++tabIndex, oldIsmsPlanType !== null, translateString("upgradeInfoSecPlan"))
        );

        content.push(
            getPlanTab("ISO 27001", getISO27001Content(), ++tabIndex, informationSecurityFeatures.isoActivitiesCatalogue, translateString("upgradeInfoSecPlan"))
        );

        content.push(
            getPlanTab("ISO 27002", getISO27002Content(), ++tabIndex, informationSecurityFeatures.isoActivitiesCatalogue, translateString("upgradeInfoSecPlan"))
        );

        content.push({
            content: getCustomContent(),
            index: ++tabIndex,
            label: translateString("myActivities"),
        });

        return content;
    }

    return (
        <React.Fragment>
            <DotLegalDialog
                inProgress={inProgress}
                isLoading={isLoading}
                size={dialogStep === DialogStep.ActivitySelection ? "xl" : "md"}
                fullHeight={dialogStep! === DialogStep.ActivitySelection}
                buttonRight
                buttonOkText={okButtonText}
                header={dialogHeaderText}
                onOkClick={() => onOkClick()}
                onDialogClose={() => props.onDialogClose()}
                open
                okButtonDisabled={
                    (!selectedActivity && dialogStep !== DialogStep.CreateTemplate) ||
                    (dialogStep === DialogStep.ActivityBaseInformation &&
                        props.activityId !== undefined &&
                        annualWheelBaseInformationFormModel.tags === undefined &&
                        platformFeatures.tags)
                }
                buttonOkIcon={!isFinalStep() ? arrow : undefined}
                previousButtonText={previousButtonText}
                previousButtonClick={() => onPreviousButtonClick()}
                previousButtonIcon={dialogStep !== DialogStep.CreateTemplate ? leftWhiteArrowButton : undefined}
            >
                <Box sx={dialogStep === DialogStep.ActivitySelection ? {} : styles.contentWrapper}>{getStepForm()}</Box>
            </DotLegalDialog>

            {showDeleteTemplateDialog && (
                <DeleteAnnualWheelActivityTemplateDialog
                    onCloseDialog={() => setShowDeleteTemplateDialog(false)}
                    onSaveCorrect={() => refetch()}
                    id={selectedActivity}
                    name={customActiviites.find((x) => x.id === selectedActivity)!.name}
                ></DeleteAnnualWheelActivityTemplateDialog>
            )}
        </React.Fragment>
    );
}

export default AnnualWheelDialog;
