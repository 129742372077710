import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import { SelectableColoredItem, SelectableItem } from "../processingActivity/ProcessingActivity.types";
import { AnnualWheelActivityType, AnnualWheelPriority } from "./annualWheelDialog/AnnualWheelDialog.types";

export interface AnnualWheelViewModel {
    overdueTaskCount: number;
    plannedTaskCount: number;
    completedTaskCount: number;
    totalTaskCount: number;
    previousYearHasActivities: boolean;
    businessAreaOptions: Array<DotLegalSelectOption>;
    userOptions: Array<DotLegalSelectOption>;
    areaOptions: Array<DotLegalSelectOption>;
    annualWheelActivities: Array<AnnualWheelActivityViewModel>;
    groupCompanyOptions: Array<DotLegalSelectOption>;
    relatedActivities: Array<string>;
    tagFilterOptions: Array<SelectableColoredItem>;
}

export interface AnnualWheelActivityViewModel {
    id: string;
    type: AnnualWheelActivityType;
    month: AnnualWheelMonthEnum;
    taskStatus: TaskStateEnum;
    name: string;
    deleted: boolean;
    templateId: string;
    isCustomTemplateActivity: boolean;
    description: string;
    recurringIntervalInMonths?: number;
    priority: AnnualWheelPriority;
    businessAreas: Array<SelectableColoredItem>;
    responsibles: Array<SelectableItem>;
    areas: Array<SelectableItem>;
    documentationRequired: boolean;
    customId: string | null;
    groupCompanies: Array<string>;
    relatedActivities: Array<string>;
    tags: Array<SelectableColoredItem>;
}

export interface AnnualWheelActivityTableModel extends AnnualWheelActivityViewModel {
    tagsString: string;
    businessAreaString: string;
}

export enum AnnualWheelMonthEnum {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export enum TaskStateEnum {
    Ready = 1,
    InProgress = 2,
    Completed = 3,
    Overdue = 4,
}

export enum TaskRawStateEnum {
    Ready = 1,
    InProgress = 2,
    Completed = 3,
    ReadyForApproval = 5,
}

export enum TaskLaneStateEnum {
    Ready = 1,
    InProgress = 2,
    Completed = 3,
}

export enum DialogStep {
    ActivitySelection = 0,
    CustomActivityEditFrom = 1,
    ActivityDurationData = 2,
    ActivityResponsibleData = 3,
    CreateTemplate = 4,
    ActivityBaseInformation = 5,
}

export enum TabStep {
    GDPRTemplates = 0,
    NIS2 = 1,
    MyActivities = 2,
}

export enum NIS2Type {
    Implementation = 0,
    OngoingOperation = 1,
}

export enum ApprovalOption {
    ApprovalNotRequired = 1,
    ApprovalNotRequiredResponsibleGetNotified = 2,
    ApprovalRequiredByResponsible = 3,
}

export interface DeleteAnnualWheelActivityTemplateResponse {
    success: boolean;
}
