import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useTrialRibbonStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        ribbon: {
            height: "50px",
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            top: 0 + "px",
            zIndex: 1000,
        },
    });
};
