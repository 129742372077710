import {
    DotLegalDatePicker,
    DotLegalDialog,
    DotLegalHeader,
    DotLegalSelect,
    DotLegalTable,
    DotLegalTextField,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import DotLegalSelectApi from "../../common/components/dotLegalSelectApi/DotLegalSelectApi";
import { QuestionnaireSelectableItem } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { apiUrls } from "../../common/api/apiUrls";
import useSendInternalAssessmentHooks from "./SendInternalAssessmentDialog.hooks";
import { Box, TableCell, TableRow } from "@mui/material";
import GroupEntityStep from "../../legalEntity/legalEntityAuditTab/groupEntityStep/GroupEntityStep";
import { InternalAssessmentViewModel } from "./SendInternalAssessmentDialog.types";
import { arrow, leftWhiteArrowButton } from "../../common/icons";
import {
    LegalEntityContactRow,
    LegalEntityContactType,
    LegalEntityContactViewModel,
} from "../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface IInternalAssessmentDialog {
    onDialogClose: () => void;
    onInternalAssessmentSend: () => void;
}

function SendInternalAssessmentDialog(props: IInternalAssessmentDialog) {
    const { translateString, getDateTimePickerLanguage, translateDate } = useTranslation();

    const {
        model,
        validation,
        internalAssessmentUpdater,
        step,
        selectableGroupEntitiesData,
        selectableGroupEntitiesLoading,
        onNextOrSendClick,
        onPreviousButtonClick,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
        isSending,
    } = useSendInternalAssessmentHooks(props);

    validation.check(model);

    function getDialogContent() {
        if (step === "first") {
            return firstStep;
        } else if (step === "selectGroupEntity") {
            return (
                <GroupEntityStep
                    showGdpoIfAvailable
                    onGroupEntityChanged={internalAssessmentUpdater.onGroupEntityChanged}
                    groupEntityId={model.groupEntityId}
                    errormsg={validation.getErrorsForDisplay("groupEntityId")}
                    selectableGroupEntitiesData={selectableGroupEntitiesData}
                    header={translateString("internalAssessmentGroupEntityHeader")}
                    infoTranslationKey={"internalAssessmentGroupEntity"}
                    checkboxTranslationKey={"internalAssessmentEntireGroup"}
                    checkboxTooltip={translateString("internalAssessmentEntireGroupInfo")}
                />
            );
        }

        return stepSend;
    }

    let headers: Array<ITableHeader<InternalAssessmentViewModel>> = [
        { property: "name", text: translateString("assessmentName"), align: "left", showOnMobile: true },
        { property: "questionnaireName", text: translateString("template"), align: "left", showOnMobile: true },
        { property: "assigneeName", text: translateString("assignee"), align: "left", showOnMobile: true },
        { property: "assigneeEmail", text: translateString("assigneeEmail"), align: "left", showOnMobile: true },
        { property: "responsibleId", text: translateString("responsible"), align: "left", showOnMobile: true },
        { property: "responsibleId", text: translateString("responsibleEmail"), align: "left", showOnMobile: true },
        { property: "deadline", text: translateString("deadline"), align: "left", showOnMobile: true },
        { property: "groupEntityName", text: translateString("sentFrom"), align: "left", showOnMobile: true },
    ];

    const stepSend = (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <DotLegalHeader fontWeight={400} headerStyle={"small"}>
                    {translateString("sendAssessmentReview")}
                </DotLegalHeader>
            </Box>
            <DotLegalTable
                headers={headers}
                defaultOrderBy={"name"}
                defaultOrder={"asc"}
                isLoading={false}
                clickableRows={false}
                hidePagination
                data={[model]}
                renderRow={(row, i) => {
                    const responsible = selectableResponsiblesData?.find((x) => x.id === row.responsibleId);
                    return (
                        <TableRow key={row.name + i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.questionnaireName}</TableCell>
                            <TableCell>{row.assigneeName}</TableCell>
                            <TableCell>{row.assigneeEmail}</TableCell>
                            <TableCell>{responsible?.name ?? ""}</TableCell>
                            <TableCell>{responsible?.email ?? ""}</TableCell>
                            <TableCell>{translateDate(row.deadline!)}</TableCell>
                            <TableCell>{row.groupEntityName}</TableCell>
                        </TableRow>
                    );
                }}
                paginationLabelOf={""}
            />
        </Box>
    );

    const firstStep = (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ width: 450 }}>
                <DotLegalTextField
                    errorText={validation.getErrorsForDisplay("name")}
                    label={translateString("assessmentName")}
                    value={model.name}
                    debounce={false}
                    onChange={internalAssessmentUpdater.onAssessmentNameChange}
                    toolTipText={translateString("assessmentNameInfo")}
                />

                <DotLegalTextField
                    label={translateString("description")}
                    onChange={internalAssessmentUpdater.onDescriptionChange}
                    multiline
                    rows={3}
                    value={model.description}
                    debounce={false}
                    toolTipText={translateString("assessmentDescriptionInfo")}
                />

                <DotLegalSelectApi<QuestionnaireSelectableItem>
                    url={apiUrls().questionnaires.internalAssessment}
                    onChange={internalAssessmentUpdater.onQuestionnaireChange}
                    label={translateString("template")}
                    noOptionsLabel={translateString("noOptions")}
                    selectedItem={model.questionnaireId}
                    toolTipText={translateString("selectTemplatetInfo")}
                    errorText={validation.getErrorsForDisplay("questionnaireId")}
                />

                <DotLegalSelectApi<LegalEntityContactRow>
                    url={apiUrls().legalEntities.contactsOnAvaliableGroupEntities}
                    selectedItem={model.assigneeId}
                    onChange={internalAssessmentUpdater.onAssigneeChanged}
                    label={translateString("assignee")}
                    toolTipText={translateString("assessmentAssigneeInfo")}
                    errorText={validation.getErrorsForDisplay("assigneeId")}
                    noOptionsLabel={translateString("noOptions")}
                    onOptionsRender={(item) =>
                        item.contactTypes.length > 0
                            ? `${item.name} (${item.contactTypes
                                  .map((ct) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[ct].toString())))
                                  .join(", ")})`
                            : item.name
                    }
                />

                <DotLegalSelect
                    selectedItem={model.responsibleId}
                    options={selectableResponsiblesData}
                    isLoading={selectableresponsiblesLoading}
                    onChange={internalAssessmentUpdater.onResponsibleChanged}
                    label={translateString("responsible")}
                    toolTipText={translateString("assessmentResponsbileInfo")}
                    noOptionsLabel={translateString("noOptions")}
                    errorText={validation.getErrorsForDisplay("responsibleId")}
                ></DotLegalSelect>

                <DotLegalDatePicker
                    label={translateString("deadline")}
                    value={model.deadline ?? null}
                    onChange={internalAssessmentUpdater.onDateChanged}
                    language={getDateTimePickerLanguage()}
                    toolTipText={translateString("assessmentDeadlineInfo")}
                    error={!!validation.getErrorsForDisplay("deadline")}
                    helperMessage={validation.getErrorsForDisplay("deadline")}
                />
            </Box>
        </Box>
    );

    return (
        <DotLegalDialog
            buttonOkText={step === "send" ? translateString("send") : translateString("next")}
            size="lg"
            header={translateString("sendAssessment")}
            open
            buttonOkIcon={arrow}
            previousButtonIcon={leftWhiteArrowButton}
            onDialogClose={props.onDialogClose}
            onOkClick={onNextOrSendClick}
            okButtonDisabled={selectableGroupEntitiesLoading}
            previousButtonText={translateString("previous")}
            previousButtonClick={step !== "first" ? onPreviousButtonClick : undefined}
            buttonRight
            inProgress={isSending}
        >
            {getDialogContent()}
        </DotLegalDialog>
    );
}

export default SendInternalAssessmentDialog;
