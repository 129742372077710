import { DotLegalButton, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import useLegalEntityClassificationTabHooks from "./LegalEntityClassificationTab.hooks";
import LegalEntityClassificationDialog from "./legalEntityClassificationDialog/LegalEntityClassificationDialog";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { getLegalEntityPermissions } from "../LegalEntity.hooks";
import ClassificationTable from "./classificationTable/ClassificationTable.tsx";
import { WhiteLockIcon } from "../../common/icons.tsx";

export interface ILegalEntityClassificationTab {
    legalEntityId: string;
    isGroupEntity: boolean;
}

function LegalEntityClassificationTab(props: ILegalEntityClassificationTab) {
    const { translateString } = useTranslation();
    const { isLoading, data, onClassificationCreated, setShowClassificationDialog, showClassificationDialog, refetchData, countIsLoading, isLimitReached } =
        useLegalEntityClassificationTabHooks(props);

    const { permissions } = useUserContext();

    const legalEntityPermissions = getLegalEntityPermissions(permissions, props.isGroupEntity);

    return (
        <DotLegalPaper>
            <React.Fragment>
                <ClassificationTable
                    data={data}
                    isLoading={isLoading}
                    refetch={() => refetchData()}
                    paqgeType="legalEntity"
                    emptyStateText={translateString("legalEntityClassificationEmptyState")}
                />

                {legalEntityPermissions.create && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                        <DotLegalButton
                            onClick={() => setShowClassificationDialog(true)}
                            buttonType={"primary"}
                            disabled={isLimitReached}
                            endIcon={isLimitReached ? WhiteLockIcon : undefined}
                            isLoading={countIsLoading}
                            toolTip={isLimitReached ? translateString("classificationLimitReached") : undefined}
                        >
                            {translateString("addClassification")}
                        </DotLegalButton>
                    </Box>
                )}

                {showClassificationDialog && (
                    <LegalEntityClassificationDialog
                        onCreated={onClassificationCreated}
                        legalEntityId={props.legalEntityId}
                        onDialogClose={() => setShowClassificationDialog(false)}
                    />
                )}
            </React.Fragment>
        </DotLegalPaper>
    );
}

export default LegalEntityClassificationTab;
