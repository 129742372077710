import React from "react";
import StepSegment from "../../../../processingActivity/stepSegment/StepSegment";
import { DotLegalMultiSelect, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import useLegalEntityAssociationStepHooks from "./LegalEntityAssociationStep.hooks";
import { useTranslation } from "../../../../localization/useTranslation";

export interface ILegalEntityAssociationStep {
    responsible?: string;
    businessAreaIds: Array<string>;
    onResponsibleChange: (responsible: string | null) => void;
    onBusinessAreaChange: (businessAreas: Array<string>) => void;
}

function LegalEntityAssociationStep(props: ILegalEntityAssociationStep) {
    const { translateString } = useTranslation();
    const { responsibles, responsiblesIsLoading, businessAreaIsLoading, businessAreas } = useLegalEntityAssociationStepHooks();

    return (
        <React.Fragment>
            <StepSegment size="small">
                <DotLegalSelect
                    label={translateString("responsible")}
                    selectedItem={props.responsible}
                    onChange={props.onResponsibleChange}
                    options={responsibles(props.responsible)}
                    placeholder={translateString("responsible")}
                    noOptionsLabel={translateString("noOptions")}
                    disableClearable
                    isLoading={responsiblesIsLoading}
                />
                <DotLegalMultiSelect
                    options={businessAreas}
                    chosenOptions={props.businessAreaIds}
                    onChange={props.onBusinessAreaChange}
                    label={translateString("businessAreas")}
                    noOptionsLabel={translateString("noOptions")}
                    isLoading={businessAreaIsLoading}
                ></DotLegalMultiSelect>
            </StepSegment>
        </React.Fragment>
    );
}

export default LegalEntityAssociationStep;
