import React from "react";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { DotLegalCheckbox, DotLegalDialog, DotLegalMultiSelect, DotLegalRadioButton, DotLegalTextField } from "@dotlegal/dotlegal-ui-components";
import { isTemplateCustomer } from "../../common/templateCustomerHelper";
import { useTranslation } from "../../localization/useTranslation";
import { useAddProcessingActivityDialogDataMapping } from "./AddProcessingActivityDialog.hooks";
import { Box, RadioGroup, styled, Typography } from "@mui/material";
import { ProcessingActivityStatus } from "../processingActivities/ProcessingActivities.types";
import { ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";

export interface IAddProcessingActivityDialog {
    showDialog: boolean;
    onHideDialog: () => void;
}

function AddProcessingActivityDialog(props: IAddProcessingActivityDialog) {
    const { createProcessingActivity, inProgress, validation, saveModel, setSaveModel, industryAreas } = useAddProcessingActivityDialogDataMapping();
    const { translateString, translateStatus } = useTranslation();
    const { permissions } = useUserContext();
    const { dataProtectionFeatures } = usePlanContext();

    const RootBox = styled(Box)(({ theme }) => ({
        display: "flex",
        mb: 2,
        mt: 0,
    }));

    const BodyTypography = styled(Typography)(({ theme }) => ({
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.primary.dark,
    }));

    validation.check(saveModel);

    return (
        <DotLegalDialog
            inProgress={inProgress}
            size="sm"
            buttonOkText={translateString("create")}
            header={translateString("createProcessingActivity")}
            onOkClick={async () => {
                await createProcessingActivity();
            }}
            onDialogClose={props.onHideDialog}
            open={props.showDialog}
            disableBackdropOnCloseIfValueChange={saveModel}
        >
            <DotLegalTextField
                debounce={false}
                size="small"
                label={translateString("processingActivity")}
                value={saveModel.name}
                onChange={(x) => {
                    let tempModel = { ...saveModel };
                    tempModel.name = x;
                    setSaveModel(tempModel);
                }}
                errorText={validation?.getErrorsForDisplay("name")}
            />
            {permissions.processingActivityStatusManagement === ProcessingActivityStatusManagement.Regular && (
                <React.Fragment>
                    <RootBox>
                        <BodyTypography>{translateString("createAs")}</BodyTypography>
                    </RootBox>
                    <RadioGroup
                        row
                        value={saveModel.processingActivityStatus === ProcessingActivityStatus.Active ? 0 : 1}
                        onChange={(e) => {
                            const tempModel = { ...saveModel };
                            tempModel.processingActivityStatus = Number((e.target as HTMLInputElement).value);
                            setSaveModel(tempModel);
                        }}
                    >
                        <DotLegalRadioButton value={0} labelPlacement="end" label={translateStatus(ProcessingActivityStatus.Active.toString())} />
                        <DotLegalRadioButton
                            value={1}
                            labelPlacement="end"
                            label={translateStatus(ProcessingActivityStatus.Draft.toString())}
                            disabled={!dataProtectionFeatures.processingActivityStatus}
                            tooltip={!dataProtectionFeatures.processingActivityStatus ? translateString("upgradeDataProtectionPlan") : undefined}
                        />
                    </RadioGroup>
                </React.Fragment>
            )}
            {permissions.canManageCustomers && isTemplateCustomer() && (
                <React.Fragment>
                    <DotLegalMultiSelect
                        options={industryAreas}
                        chosenOptions={saveModel.industryAreas.map((x) => {
                            return x.toString();
                        })}
                        placeholder={"Brancher"}
                        label={"Brancer"}
                        onChange={(areas) => {
                            let tempModel = { ...saveModel };
                            tempModel.industryAreas = areas;
                            setSaveModel(tempModel);
                        }}
                        noOptionsLabel={translateString("noOptions")}
                    />

                    <DotLegalCheckbox
                        label={"Mest populær?"}
                        checked={saveModel.mostPopular}
                        onChange={(mostPopular) => {
                            let tempViewModel = { ...saveModel! };
                            tempViewModel.mostPopular = mostPopular;
                            setSaveModel(tempViewModel);
                        }}
                    />
                </React.Fragment>
            )}
        </DotLegalDialog>
    );
}

export default AddProcessingActivityDialog;
