import React, { lazy } from "react";
import { getSettings } from "../common/settingsProvider";
import { getExternalToken, getJwtToken } from "../common/api/apiShared";
const QuestionnaireService = lazy(() => import("questionnaireService/App") as any);

export interface QuestionnaireProps {}

function MicroFrontend(props: QuestionnaireProps) {
    return (
        <main style={{ height: "100%" }}>
            <QuestionnaireService jwtToken={getJwtToken()} parentHost={getSettings().mainSiteBaseUrl} externalToken={getExternalToken()} />
        </main>
    );
}

export default MicroFrontend;
