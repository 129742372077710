import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import PlanDialog from "../planDialog/PlanDialog";
import { DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import PlanDialogUpgradeButton from "../planDialogComponents/PlanDialogUpgradeButton";
import { useUrlProvider } from "../../useUrlProvider.ts";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";

export interface IUpgradePlanDialog {
    showDialog: boolean;
    onDialogClose: () => void;
}

export function getPriceListUrl(language: string) {
    if (language === "da" || language === "da-DK") {
        return "https://www.dotlegal.com/da/priser-privacy#Alle-Privacy-features";
    }

    return "https://www.dotlegal.com/en/pricing-privacy#All-Privacy-features";
}

function UpgradePlanDialog(props: IUpgradePlanDialog) {
    const { translateString } = useTranslation();
    const { language } = useUserContext();
    const { getDotLegalContactForm } = useUrlProvider();

    function getContent() {
        return (
            <React.Fragment>
                <DotLegalHeader marginBottom={3} fontWeight={500} center headerStyle="small">
                    {translateString("upgradePrivacyPlanContent")}
                </DotLegalHeader>
                <DotLegalHeader marginBottom={3} fontWeight={500} center headerStyle="small">
                    <Trans
                        i18nKey={"clickToSeeFullListOfFeatures"}
                        components={{
                            mail: (
                                <Box
                                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.main })}
                                    component={"a"}
                                    href={getPriceListUrl(language)}
                                    target="_blank"
                                ></Box>
                            ),
                        }}
                    />
                </DotLegalHeader>

                <DotLegalHeader marginBottom={7} fontWeight={500} center headerStyle="small">
                    <Box sx={{ "& a": { textDecoration: "underline !important" } }}>
                        <Trans
                            i18nKey="contactDotLegalToUpgradePlan"
                            values={{ url: getDotLegalContactForm() }}
                            components={{
                                contactForm: (
                                    <DotLegalLink linkColor={"primary"} bold to={getDotLegalContactForm()}>
                                        {getDotLegalContactForm()}
                                    </DotLegalLink>
                                ),
                            }}
                        />
                    </Box>
                </DotLegalHeader>
            </React.Fragment>
        );
    }

    return (
        <PlanDialog
            showDialog={props.showDialog}
            onDialogClose={() => props.onDialogClose()}
            content={getContent()}
            header={translateString("upgradePrivacyPlanHeader")}
            //buttons={<PlanDialogUpgradeButton />}
        ></PlanDialog>
    );
}

export default UpgradePlanDialog;
