import {
    DotLegalChip,
    DotLegalEmptyState,
    DotLegalInformationTooltip,
    DotLegalOverflowMenu,
    DotLegalTable,
    DotLegalTooltip,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { AnnualWheelActivityTableModel, AnnualWheelActivityViewModel, AnnualWheelMonthEnum, TaskStateEnum } from "../AnnualWheel.types";
import { useTranslation } from "../../localization/useTranslation";
import { Badge, Box, TableCell, TableRow } from "@mui/material";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { AnnualWheelPriority } from "../annualWheelDialog/AnnualWheelDialog.types";
import AnnualWheelTaskDeleted from "../annualWheelTask/AnnualWheelTaskDeleted.svg?react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAnnualWheelListOverviewStyles } from "./AnnualWheelListOverview.styles";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import EmptySvg from "./EmptyList.svg?react";
import NoDataSvg from "../../processingActivity/processingActivityOverview/processingActivitySharingsTab/noSharings.svg?react";
import { getTaskColor } from "../../taskManagement/task/Task.styles";

export interface IAnnualWheelListOverview {
    activties: Array<AnnualWheelActivityViewModel> | undefined;
    setShowAnnualWheelDialog: (show: boolean) => void;
    isLoading: boolean;
    setSelectedActivity: (activity: AnnualWheelActivityViewModel | undefined) => void;
    setShowDeleteActivityDialog: (activity: { showDialog: boolean; activityName: string }) => void;
    getTaskManagementUrlForTask: (month: AnnualWheelMonthEnum, id: string) => string;
    hasActivities: boolean;
}

function AnnualWheelListOverview(props: IAnnualWheelListOverview) {
    const { translateString } = useTranslation();
    const styles = useAnnualWheelListOverviewStyles();
    const navigate = useNavigate();
    const { permissions } = useUserContext();
    const { addonsFeatures, platformFeatures } = usePlanContext();

    const showOverFlowMenu = (isDeleted: boolean) => {
        if (isDeleted) {
            return permissions.annualWheelPermission.delete;
        }

        return permissions.annualWheelPermission.edit || permissions.annualWheelPermission.delete || permissions.taskPermissions.read;
    };

    const getMenuItems = (activity: AnnualWheelActivityViewModel) => {
        if (activity.deleted && permissions.annualWheelPermission.delete) {
            return [
                {
                    menuItem: translateString("delete"),
                    onClick: () => {
                        props.setShowDeleteActivityDialog({ showDialog: true, activityName: activity.name });
                        props.setSelectedActivity(activity);
                    },
                },
            ];
        }

        let menuItems = [];

        if (permissions.taskPermissions.read) {
            menuItems.push({
                menuItem: translateString("viewInTaskManagement"),
                onClick: () => {
                    navigate(props.getTaskManagementUrlForTask(activity.month, activity.id));
                },
            });
        }

        if (permissions.annualWheelPermission.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    props.setShowAnnualWheelDialog(true);
                    props.setSelectedActivity(activity);
                },
            });
        }

        if (permissions.annualWheelPermission.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    props.setShowDeleteActivityDialog({ showDialog: true, activityName: activity.name });
                    props.setSelectedActivity(activity);
                },
            });
        }

        return menuItems;
    };

    function getHeaders() {
        let headers = Array<ITableHeader<AnnualWheelActivityTableModel>>();
        headers.push({ property: "name", text: translateString("activity"), align: "left", showOnMobile: true });
        headers.push({ property: "customId", text: "ID", align: "left", showOnMobile: true });
        headers.push({ property: "month", text: translateString("month"), align: "left", showOnMobile: true });
        headers.push({ property: "recurringIntervalInMonths", text: translateString("recurrence"), align: "left", showOnMobile: true });
        headers.push({ property: "priority", text: translateString("priority"), align: "left", showOnMobile: true });
        headers.push({ property: "taskStatus", text: translateString("status"), align: "left", showOnMobile: true });

        if (platformFeatures.relatedActivitiesAndTasks) {
            headers.push({ property: "relatedActivities", text: translateString("relatedActivities"), align: "left", showOnMobile: false });
        }

        if (platformFeatures.annualWheelComplianceArea) {
            headers.push({ property: "areas", text: translateString("area"), align: "left", showOnMobile: true });
        }

        headers.push({ property: "businessAreas", text: translateString("businessAreas"), align: "left", showOnMobile: true });

        if (platformFeatures.tags) {
            headers.push({ property: "tagsString", text: translateString("tags"), align: "left", showOnMobile: true });
        }

        headers.push({ property: "responsibles", text: translateString("responsible"), align: "left", showOnMobile: true });
        headers.push({ property: "documentationRequired", text: translateString("documentationRequired"), align: "left", showOnMobile: true });

        return headers;
    }
    const hasActivities = props.hasActivities && props.activties && props.activties.length > 0;
    return (
        <React.Fragment>
            {props.isLoading || hasActivities ? (
                <DotLegalTable
                    headers={getHeaders()}
                    rowsPerPage={30}
                    defaultOrderBy={"month"}
                    clickableRows={false}
                    defaultOrder={"asc"}
                    isLoading={props.isLoading}
                    data={convertActivitiesToTableModel(props.activties ?? [])}
                    renderRow={(row, i) => (
                        <TableRow key={row.id + row.month.toString()}>
                            <TableCell>
                                {
                                    <Box sx={styles.nameContainer}>
                                        <Box component={"span"}>{row.name}</Box>
                                        <DotLegalInformationTooltip text={row.description} />
                                        {row.deleted && (
                                            <DotLegalTooltip text={translateString("annualActivityIsDeleted")}>
                                                <Box sx={styles.img}>
                                                    <AnnualWheelTaskDeleted />
                                                </Box>
                                            </DotLegalTooltip>
                                        )}
                                    </Box>
                                }
                            </TableCell>
                            <TableCell>{row.customId}</TableCell>
                            <TableCell>{translateString(AnnualWheelMonthEnum[row.month].toLocaleLowerCase())}</TableCell>
                            <TableCell>
                                {row.recurringIntervalInMonths
                                    ? row.recurringIntervalInMonths === 1
                                        ? translateString("everyMonth")
                                        : translateString("repertEveryXMonth", { repetition: row.recurringIntervalInMonths })
                                    : translateString("oneTimeActivity")}
                            </TableCell>
                            <TableCell>{translateString(setFirstLetterToLowerCase(AnnualWheelPriority[row.priority].toString()))}</TableCell>

                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Badge
                                        variant="dot"
                                        sx={(theme) => ({
                                            marginRight: 1,
                                            "& .MuiBadge-badge": {
                                                backgroundColor: getTaskColor(row.taskStatus, theme),
                                            },
                                        })}
                                    />
                                    <Box>{translateString(setFirstLetterToLowerCase(TaskStateEnum[row.taskStatus].toString()))}</Box>
                                </Box>
                            </TableCell>
                            {platformFeatures.relatedActivitiesAndTasks && <TableCell>{row.relatedActivities.join(", ")}</TableCell>}
                            {platformFeatures.annualWheelComplianceArea && <TableCell>{row.areas.map((x) => x.name).join(", ")}</TableCell>}
                            <TableCell>
                                {row.businessAreas.map((x, index) => {
                                    return (
                                        <Box sx={styles.chip} key={x.name + index}>
                                            <DotLegalChip
                                                value={x.name}
                                                color={x.color}
                                                size="small"
                                                isSelected
                                                collapse={row.businessAreas.length > 2 && index > 0}
                                            />
                                        </Box>
                                    );
                                })}
                            </TableCell>
                            {platformFeatures.tags && (
                                <TableCell>
                                    {row.tags.map((x, index) => {
                                        return (
                                            <Box sx={styles.chip} key={x.name + index}>
                                                <DotLegalChip
                                                    value={x.name}
                                                    color={x.color}
                                                    size="small"
                                                    isSelected
                                                    collapse={row.businessAreas.length > 2 && index > 0}
                                                />
                                            </Box>
                                        );
                                    })}
                                </TableCell>
                            )}
                            <TableCell>{row.responsibles && row.responsibles.map((x) => x.name).join(", ")}</TableCell>
                            <TableCell>{row.documentationRequired ? translateString("yes") : translateString("no")}</TableCell>
                            <TableCell align={"right"}>{showOverFlowMenu(row.deleted) && <DotLegalOverflowMenu items={getMenuItems(row)} />}</TableCell>
                        </TableRow>
                    )}
                    paginationLabelOf={translateString("labelOf")}
                />
            ) : (
                <DotLegalEmptyState
                    icon={props.hasActivities ? <NoDataSvg /> : <EmptySvg />}
                    text={
                        <React.Fragment>
                            <span>{props.hasActivities ? translateString("noData") : translateString("noAnnualWheelActivities")}</span>
                        </React.Fragment>
                    }
                />
            )}
        </React.Fragment>
    );

    function convertActivitiesToTableModel(rows: Array<AnnualWheelActivityViewModel>): Array<AnnualWheelActivityTableModel> {
        return rows.map((row) => {
            return {
                ...row,
                tagsString: row.tags.join(", "),
                businessAreaString: row.businessAreas.join(", "),
            };
        });
    }
}

export default AnnualWheelListOverview;
