import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types";
import { QuestionnaireAuditStatus } from "../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus.types";
import { SelectableItem } from "../../processingActivity/ProcessingActivity.types";
import { BaseViewModel } from "../../system/systemOverview/SystemOverview.types";
import { UserSelectableItem } from "../../user/User.types";
import { RiskLevel } from "../legalEntityClassificationTab/LegalEntityClassificationTab.types";

export interface LegalEntityAuditApiModel extends QuestionnaireAuditStatus {
    id: string;
    questionnaireName: string;
    questionnaireVersion: number;
    questionnaireId: string;
    sent: Date;
    deadline: Date;
    note: string | undefined;
    contact: LegalEntityAuditContactViewModel;
    assets: Array<LegalEntityAuditAssetViewModel>;
    isClosed: boolean;
    sender: string;
    canBeDeleted: boolean;
    questionnaireResponseId: string;
    report: string;
    responsible: UserSelectableItem;
    riskLevel?: RiskLevel;
    score?: number;
    scoreEnabled: boolean;
    riskName: string | undefined;
    uniqueIdentifier: number;
    reminderSent?: Date;
    remindersPaused: boolean;
    responseExternalId: string;
    auditGroup?: BaseViewModel;
    selectableAuditGroups: Array<SelectableItem>;
    hasRecommendations: boolean;
    evaluationStatus?: EvaluationStatus;
    evaluation: string | undefined;
}

export interface LegalEntityAuditRowModel extends LegalEntityAuditApiModel {
    systemsString: string;
    contactName: string;
    responsibleName: string;
    auditGroupName?: string;
    statusName: string;
    riskLevelName?: string;
    scoreName?: string;
    evaluationName?: string;
}

export interface QuestionnaireSelectableItem {
    name: string;
    id: string;
}

export interface LegalEntityAuditContactViewModel {
    id: string;
    name: string;
}

export interface LegalEntityAuditAssetViewModel {
    id: string;
    name: string;
}

export enum LegalEntityAuditStatus {
    Sent,
    Submitted,
    DoesNotNeedToRespond,
    DoesNotWantToRespond,
    InProgress,
    AuditOpinion,
    Opened,
    Other,
}
