import { useState } from "react";
import { CustomerPlanViewModel } from "./Trial.types";

export function useTrialHook(data: CustomerPlanViewModel | undefined) {
    const expiresSoonStorageKey = "privacy-trial-expires-soon-dialog-last-shown";
    const expiredStorageKey = "privacy-trial-expired-dialog-last-shown";
    const [shouldShowTrialExpiresSoonDialog, setShouldShowTrialExpiresSoonDialog] = useState(true);
    const [shouldShowTrialExpiredDialog, setShouldShowTrialExpiredDialog] = useState(true);

    let showSoonDialog =
        ((localStorage.getItem(expiresSoonStorageKey) === null || getHourSinceLastShown(expiresSoonStorageKey) > 24) &&
            data?.isTrial &&
            data?.trialDaysRemaining <= 5 &&
            data?.trialDaysRemaining > 0 &&
            shouldShowTrialExpiresSoonDialog) ??
        false;

    let showExpiredDialog =
        ((localStorage.getItem(expiredStorageKey) === null || getHourSinceLastShown(expiredStorageKey) > 24) &&
            data?.showTrialExpiredDialog &&
            shouldShowTrialExpiredDialog) ??
        false;

    if (showExpiredDialog && !shouldShowTrialExpiredDialog) {
        const date = new Date().getTime().toString();
        localStorage.setItem(expiredStorageKey, date);
    }

    if (showSoonDialog && !shouldShowTrialExpiresSoonDialog) {
        const date = new Date().getTime().toString();
        localStorage.setItem(expiresSoonStorageKey, date);
    }

    function getHourSinceLastShown(storageKey: string) {
        var diff = new Date().getTime() - new Date(Number(localStorage.getItem(storageKey))!).getTime();
        return diff / 1000 / 60 / 60; // Convert milliseconds to hours
    }

    function closeExpiresSoonDialog() {
        var date = new Date().getTime().toString();
        localStorage.setItem(expiresSoonStorageKey, date);
        setShouldShowTrialExpiresSoonDialog(false);
    }

    function closeExpiredDialog() {
        var date = new Date().getTime().toString();
        localStorage.setItem(expiredStorageKey, date);
        setShouldShowTrialExpiredDialog(false);
    }

    function dontAskAgainExpiresSoonDialog() {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        localStorage.setItem(expiresSoonStorageKey, date.getTime().toString());
        setShouldShowTrialExpiresSoonDialog(false);
    }

    function dontAskAgainExpiredDialog() {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        localStorage.setItem(expiredStorageKey, date.getTime().toString());
        setShouldShowTrialExpiredDialog(false);
    }

    return {
        showSoonDialog,
        showExpiredDialog,
        closeExpiresSoonDialog,
        closeExpiredDialog,
        dontAskAgainExpiresSoonDialog,
        dontAskAgainExpiredDialog,
    };
}
