import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useUpgradeOrTrialDialogStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        whiteArrow: {
            "& svg": {
                fill: theme.palette.common.white,
            },
        },
        leftArrow: {
            transform: "rotate(180deg)",
        },
    });
};
