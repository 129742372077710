import { Box } from "@mui/material";
import React from "react";
import DotLegalRecordHeader from "../common/components/dotLegalRecordHeader/DotLegalRecordHeader";
import { DotLegalPageHeader, DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import DataControllerRecord from "../DataControllerRecord/DataControllerRecord";
import DataProcessorRecord from "../DataProcessorRecord/DataProcessorRecord";
import { useTranslation } from "../localization/useTranslation";
import { useRecordDataMapping } from "./Record.hooks";
import { RecordType } from "./Record.types";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import PurpleLockSvg from "../common/svgs/purpleLock.svg?react";
import { DotLegalSelectOption } from "../common/components/dotLegalMultiSelect/DotLegalMultiSelect.types";
import DotLegalAllOrMySwitchBox from "../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";

function Record() {
    const { translateString } = useTranslation();
    const { dataProtectionFeatures } = usePlanContext();
    const { permissions, customerName } = useUserContext();

    const getBreadCrumbs = () => {
        return [
            {
                name: translateString("record"),
            },
        ];
    };

    const {
        onGroupEntityChange,
        selectedGroupEntity,
        processingActivityIds,
        setProcessingActivityIds,
        processingAreaIds,
        setProcessingAreaIds,
        onlyUserSpecific,
        setOnlyUserSpecific,
        onExportClick,
        isExporting,
        searchString,
        setSearchString,
        recordSelectOptions,
        selectedRecord,
        setSelectedRecord,
        dataControllerRows,
        dataControllerRowsIsLoading,
        dataProcessorRows,
        dataProcessorRowsIsLoading,
        isExportDisabled,
        hasData,
    } = useRecordDataMapping();

    function renderRecordOptions(option: DotLegalSelectOption) {
        if (option.id === RecordType.DataProcessor && !dataProtectionFeatures.recordDataProcessor) {
            return (
                <Box sx={{ display: "flex" }}>
                    <span>{option.name}</span>
                    <Box sx={{ marginLeft: 1, display: "flex", alignContent: "center" }}>
                        <PurpleLockSvg />
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box>
                    <span>{option.name}</span>
                </Box>
            );
        }
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs()}
                rightComponent={
                    <DotLegalSelect
                        disableClearable
                        options={recordSelectOptions}
                        onChange={(item: string | null) => setSelectedRecord(item!)}
                        selectedItem={selectedRecord}
                        placeholder={""}
                        label={""}
                        noOptionsLabel={translateString("noOptions")}
                        renderOptions={renderRecordOptions}
                        noMargin
                        width="300px"
                        backgroundColor="white"
                    />
                }
                leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />}
            />

            <DotLegalRecordHeader
                disableExport={isExportDisabled}
                isExporting={isExporting}
                onExportClick={onExportClick}
                processingActivityIds={processingActivityIds}
                processingAreaIds={processingAreaIds}
                selectedGroupEntity={selectedGroupEntity}
                setProcessingActivityIds={setProcessingActivityIds}
                setProcessingAreaIds={setProcessingAreaIds}
                setSelectedGroupEntity={onGroupEntityChange}
                onlyUserSpecific={onlyUserSpecific}
            />

            {selectedRecord === RecordType.DataProcessor ? (
                <DataProcessorRecord
                    isLoading={dataProcessorRowsIsLoading}
                    rows={dataProcessorRows}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    hasData={hasData}
                />
            ) : (
                <DataControllerRecord
                    rows={dataControllerRows}
                    isLoading={dataControllerRowsIsLoading}
                    isExtended={selectedRecord === RecordType.ExtentedDataController}
                    hasData={hasData}
                />
            )}
        </React.Fragment>
    );
}

export default Record;
